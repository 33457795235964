<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><i class='bx bx-chevron-right'></i><a routerLink="/">Home</a></li>
                <li><i  class='bx bx-chevron-right'></i><a routerLink="/">About</a></li>
                <li><i class='bx bx-chevron-right'></i>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Bachelor of Arts (BA)</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Introduction</h3>
                        </div>
                        <p>
                            The Bachelor of Arts (BA) degree is accredited after
                            a three-year undergraduate study in the arts and
                            sciences. By allowing students to pick from a wide
                            variety of specialties, the BA curriculum can help
                            them acquire a broad understanding of the humanities
                            and social sciences. A wide variety of positions are
                            open to those with a BA, such as executive
                            assistant, HR manager, copywriter, supervisor,
                            product manager, and more.
                        </p>
                        <h4>Objectives</h4>
                        <p>
                            The Bachelor of Arts program is designed for
                            students interested in pursuing careers in the arts
                            and social sciences. Bachelor of Arts programs
                            typically include a wide range of elective options
                            in addition to the required coursework. The BA
                            curriculum and courses provide in-depth education
                            and preparation in all relevant areas.
                        </p>
                        <p>
                            BA degree is one of the most well-established and
                            widely pursued academic degrees on a global scale.
                            This degree is a flexible option because it covers a
                            wide range of topics and may be tailored to the
                            specific needs of any workplace. The BA program is
                            designed to improve students' abilities in writing,
                            reading, and thinking critically.
                        </p>
                        <p>
                            Individuals seeking employment have a wide variety
                            of options depending on the specifics of the BA
                            program they enroll in, including but not limited to
                            the fields of English, Politics, Psychology,
                            Finance, and Mass Communication.
                        </p>
                        <p>
                            BA program is open to anybody interested in a career
                            in the social sciences and the humanities. As time
                            has progressed, the field of business analysis has
                            diversified into other subfields, significantly
                            increasing the spectrum of the BA's possible
                            employment options.
                        </p>
                        <h5>Elective Courses in BA:</h5>
                        <ol>
                            <li>Hindi</li>
                            <li>English</li>
                            <li>Political Science</li>
                            <li>Economics</li>
                            <li>Sociology</li>
                            <li>Mathematics</li>
                        </ol>
                        <p>Programme Duration</p>
                        <p>
                            Bachelor of Arts (B.A.) is an undergraduate degree
                            program and B. A. programs are typically three years
                            course.
                        </p>
                        <p>
                            Minimum Duration 3 Years<br />Maximum Duration 6
                            Year
                        </p>
                        <h6>Eligibility & Fee</h6>
                        <p>
                            Online/distance B.A. eligibility criteria are too
                            very flexible. To pursue an online and distance B.A.
                            one has to be 10+2 pass out from any recognised
                            university. There are no entrance examinations for
                            admission in online and distance B.A. courses.
                        </p>
                        <table class="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Programme fee</th>
                                    <th>Examination fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Rs. 6500 Per Year</td>
                                    <td>Rs. 2000 Per Year</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6 style="font-weight: bold">Payment Mode</h6>
                        <table
                            style="width: 100%"
                            cellpadding="0"
                            cellspacing="0"
                            border="1"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Account Holder Name:</b> Swami
                                        Vivekanand Subharti University Distance
                                        Education
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height: 24px">
                                        <b>Account Number:</b> 628501043170
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>IFSC Code:</b> ICIC0006285</td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>Branch:</b> 65 Shiv Puri Hapur Road
                                        Meerut
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h6><strong>Career Prospects</strong></h6>
                        <p>
                            After college, a graduate student has various
                            options for employment, both in the government and
                            private sectors.
                        </p>
                        <h6 style="font-weight: bold">Government Sector-</h6>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Civil Services
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Teacher
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Armed Forces
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Banking
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Railway
                            </li>
                        </ul>
                        <h5 style="font-weight: bold">
                            Private Sector-
                            <hr />
                        </h5>
                        <div class="container p-4" style="text-align: justify">
                            <div class="row">
                                <div class="col-lg-6">
                                    <h6 style="font-weight: bold">
                                        BPO (Business Process Outsourcing)
                                    </h6>
                                    <p>
                                        There are many designations for
                                        Graduates are:
                                    </p>
                                    <ul>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >ed-tech sales
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Inbound Process.
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Voice Process
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Data Entry Operators
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Sales Management
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >International Sales
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <h6 style="font-weight: bold">
                                        KPO (Knowledge Process Outsourcing)
                                    </h6>
                                    <p>
                                        There are many designations for
                                        Graduates are:
                                    </p>
                                    <ul>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Content Writers
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Executive data Entry Operators
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <h6 style="font-weight: bold">
                                        Media Houses
                                    </h6>
                                    <p>
                                        There are many designations for
                                        Graduates are:
                                    </p>
                                    <ul>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Journalist
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Editor
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Sub-Editor
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Reporter
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Anchor
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <h6 style="font-weight: bold">
                                        Best study options after B.A
                                    </h6>
                                    <br />
                                    <ul>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >M.A.
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >MBA
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >MCA
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >M. Sc IT
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >B. Ed.
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >B. Lib.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</div>
