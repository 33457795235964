<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>ADMISSION PROCEDURE</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>ADMISSION PROCEDURE</h3>
                        <p>Application can be sent to CDOE (Formerly DDE) directly or through its city office at Swami Vivekanand Subharti University, Subhartipuram, NH-58, Delhi-Haridwar Road, Meerut, Uttar Pradesh 250005. The applicant's eligibility will be checked and accordingly he/she shall be granted
                            admission and an acknowledgement of the receipt of the fee and the application form shall be
                            issued.</p>
                        <p>A copy of the essential documents, to be attached with Admission Form, required for admission
                            in various programmes under CDOE (Formerly DDE):</p>
                        <h4>[A] For Graduation Programmes:</h4>
                        <p>1. High School/ Higher Secondary passed Marksheet duly self attested by candidate</p>
                        <p>2. High School/ Higher Secondary Certificate duly self attested by candidate</p>
                        <p>3. 10+2 passed Marksheet duly self attested by candidate</p>
                        <p>4. 10+2 Certificate duly self attested by candidate</p>
                        <p>5. Aadhar Card duly self attested by candidate</p>
                        <p>6. Recent Passport size photograph- 4 Nos.</p>
                        <p style="color: black;">Note:-</p>
                        <p>(1) 10+2 passed with subjects Physics, Chemistry & Mathematics is mandatory for admission in
                            B.Sc. (PCM)</p>
                        <p>(2) 10+2 passed with subjects Zoology, Biology & Chemistry is mandatory for admission in
                            B.Sc. (ZBC)
                        </p>
                        <h5>[B] For Post Graduation Programmes:</h5>
                        <p>1. High School/ Higher Secondary passed Marksheet duly self attested by candidate</p>
                        <p>2. High School/ Higher Secondary Certificate duly self attested by candidate</p>
                        <p>3. 10+2 passed Marksheet duly self attested by candidate</p>
                        <p>4. 10+2 Certificate duly self attested by candidate</p>
                        <p>5. Graduation passed Marksheet duly self attested by candidate</p>
                        <p>6. Graduation Degree duly self attested by candidate</p>
                        <p>7. Aadhar Card duly self attested by candidate</p>
                        <p>8. Recent Passport size photograph- 4 Nos.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
