import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-assignment-fm6th23',
    templateUrl: './assignment-fm6th23.component.html',
    styleUrls: ['./assignment-fm6th23.component.scss'],
})
export class AssignmentFm6th23Component implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
