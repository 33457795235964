<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact Us</li>
            </ul>
            <h2>Contact Us</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="postal_address">
                    <h4 class="contact-title" style="margin-top: 30px">
                        Address:
                    </h4>

                    <p>
                        Subhartipuram, NH-58, Delhi-Haridwar, Meerut Bypass Rd,
                        Meerut, Uttar Pradesh 250005
                    </p>
                    <h4 class="contact-title" style="margin-top: 30px">
                        For Enquiry/Grievance:
                    </h4>

                    <p>(+91) 0121 6675091</p>

                    <h4 class="contact-title" style="margin-top: 30px">
                        For Admission:
                    </h4>

                    <p>(+91) 0121-6675092</p>
                    <h4 class="contact-title" style="margin-top: 30px">
                        Student Support:
                    </h4>

                    <p
                        style="
                            font-size: 20pt;
                            font-weight: bolder;
                            color: #29166b;
                        "
                    >
                        18008890609
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<iframe
    width="100%"
    height="400"
    frameborder="0"
    scrolling="no"
    marginheight="0"
    marginwidth="0"
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1745.5050316225033!2d77.636688!3d28.957426!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xddc6f062b674f2f!2sDirectorate%20Of%20Distance%20Education%2C%20Swami%20Vivekanand%20Subharti%20University!5e0!3m2!1sen!2sin!4v1627441455602!5m2!1sen!2sin"
    loading="lazy"
></iframe>
