import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class MetaService {
    constructor(@Inject(DOCUMENT) private dom: any) {}

    updateCanonicalUrl(url: string) {
        const els = this.dom.querySelectorAll("link[rel='canonical']");
        for (let i = 0, l = els.length; i < l; i++) {
            const el = els[i];
            el.remove();
            const head = this.dom.getElementsByTagName('head')[0];
            var element: HTMLLinkElement =
                this.dom.querySelector(`link[rel='canonical']`) || null;
            if (element == null) {
                element = this.dom.createElement('link') as HTMLLinkElement;
                head.appendChild(element);
            }
            element.setAttribute('rel', 'canonical');
            element.setAttribute('href', url);
        }
    }
}
