import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-administrative-staff',
  templateUrl: './administrative-staff.component.html',
  styleUrls: ['./administrative-staff.component.scss']
})
export class AdministrativeStaffComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
