<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Master of Arts in Home Science</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!--<div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Introduction</h3>
                        </div>
                        <p>
                            Producing, preparing, preserving, managing quality
                            and harvesting plants (including breeding, storing,
                            and coping with shortages of vegetables and fruits)
                            are all topics covered in depth by students of MA
                            Home Science. It's a two-year graduate program that
                            covers topics like "Perennial Species," "Annual
                            Species," and more. A master's degree holder in Home
                            Science may find work as a production supervisor,
                            food inspector, landscaper, florist, or
                            administrator of a food processing plant.
                        </p>
                        <h4>Objective</h4>
                        <p>
                            When implied correctly, home science has the power
                            to alter people's lives forever. It makes our way of
                            life more sustainable, and that realization alone
                            can motivate anyone to take this course. Researching
                            the market and thinking critically are two abilities
                            that can save your life that are emphasized in this
                            graduate program. The Master of Arts in Home Science
                            program promotes knowledge of healthy, organic food
                            and living practices.
                        </p>
                        <p>
                            Students who take Home Science classes gain a deeper
                            appreciation for the aesthetic appeal, nutritional
                            worth, and health advantages of a variety of plants.
                            By studying their target demographics in-depth,
                            individuals can create items that are more
                            convenient for their target audience.
                        </p>
                        <p>
                            Since it draws on students' prior knowledge in
                            social science, biology, and physics, the MA in Home
                            Science program has demonstrated the importance of
                            an interdisciplinary curriculum. This course's
                            knowledge, which draws from both the arts and
                            sciences, is used to cultivate and grow plants in a
                            way that maximizes their synergy. One can learn the
                            skills necessary to create better and more long-term
                            lifestyle choices in this course. Aspects of
                            society, economy, ecology, and utility will all be
                            covered in detail.
                        </p>
                        <h5>Programme Duration</h5>
                        <p>
                            MA in Home Science is a post-degree program and this
                            programs are typically two years course.
                        </p>
                        <p>
                            Minimum Duration 2 Years<br />Maximum Duration 4
                            Year
                        </p>
                        <h6>Eligibility & Fee</h6>
                        <p>
                            Online/distance MA in Home Science eligibility
                            criteria are too very flexible. To pursue an online
                            and distance MA in Home Science one has to be
                            completed graduation from any recognised university.
                            There are no entrance examinations for admission in
                            this course.
                        </p>
                        <table class="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Programme fee</th>
                                    <th>Examination fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Rs. 12000 Per Year</td>
                                    <td>Rs. 2000 Per Year</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6 style="font-weight: bold">Payment Mode</h6>
                        <p>
                            Student can submit his fee in cash to cash counter
                            in department or submit online
                            <b
                                >For online payment, Account Details are as
                                follow :</b
                            >
                        </p>
                        <table
                            style="width: 100%"
                            cellpadding="0"
                            cellspacing="0"
                            border="1"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Account Holder Name:</b> Swami
                                        Vivekanand Subharti University Distance
                                        Education
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height: 24px">
                                        <b>Account Number:</b> 628501043170
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>IFSC Code:</b> ICIC0006285</td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>Branch:</b> 65 Shiv Puri Hapur Road
                                        Meerut
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h6><strong>Career Prospects</strong></h6>
                        <p>
                            After post-graduation student has various options
                            for employment, both in the government and private
                            sectors.
                        </p>
                        <p>
                            There are many designations for MA in Home Science
                            Students:
                        </p>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Service and Tourism Industry
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Healthcare Industry
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Teaching and Research
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Production Industry
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Nutrition and Dietetics
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Resource Management
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Clothing and Textile
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Confectionery and Bakery
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Fashion Designer Boutique
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Hobby Centres
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Child Care Centres
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Grooming Centres
                            </li>
                        </ul>
                        <h6>Best study options after MA in Home Science</h6>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >M.Phil.
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Ph.D.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</div>
