<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Bachelor of Library and Information Science (BLIS)</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Introduction</h3>
                        </div>
                        <p>
                            The Bachelor of Library and Information Science
                            (BLIS) is a 4-year undergraduate program focused on
                            the study of libraries and information resources.
                            The BLIS curriculum is built around teaching its
                            students how to manage library databases, access and
                            store electronic books, and more. Graduates of the
                            Bachelor of Library and Information Science program
                            can find work in a variety of settings, including
                            those involving library and information science.
                        </p>
                        <p>
                            Books are the supreme repository of information, and
                            a job in a library is among the most rewarding
                            possible. The employment market currently offers a
                            wide range of possibilities for bibliophiles seeking
                            employment in libraries, both government and
                            institutional.
                        </p>
                        <h4>Objective</h4>
                        <p>
                            The goal of the Bachelor of Library and Information
                            Science (BLIS) program is to give students with the
                            knowledge and training they need to manage and
                            organize libraries efficiently and offer a wide
                            range of library and information services, supported
                            by technology whenever possible. The objectives of
                            the BLIS program are aligned with those of the
                            business world. Therefore, the job prospects and
                            possibilities for BLIS graduates are excellent.
                        </p>
                        <p>
                            Students in the BLIS program learn the fundamentals
                            of library science, such as data handling, indexing,
                            information architecture, preservation management,
                            and more. When you graduate from a BLIS program,
                            you'll have a wide range of opportunities at your
                            disposal.
                        </p>
                        <p>
                            Academic institutions, research facilities, and
                            record-keeping archives all offer graduate-level
                            employment opportunities. The goal of this course is
                            to equip students with an all-encompassing knowledge
                            of library management and technology so that they
                            may contribute real-world solutions after
                            graduation.
                        </p>
                        <p>
                            The curriculum of librarian courses is designed to
                            shape students into pros at gathering, organizing,
                            and disseminating data. Graduates with a Bachelor of
                            Library and Information Science (BLIS) degree can
                            work in a wide variety of fields, such as
                            archivists, cataloguers, information architects,
                            indexers, junior information analysts, records
                            managers, researchers, abstractors, etc.
                        </p>

                        <h5>Programme Duration</h5>
                        <p>
                            Bachelor of Library and Information Science (BLIS)
                            programme is an undergraduate degree program.
                        </p>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Minimum Duration 1 Years
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Maximum Duration 2 Year
                            </li>
                        </ul>
                        <h6>Eligibility & Fee</h6>
                        <p>
                            Online/distance Bachelor of Library and Information
                            Science eligibility criteria are too very flexible.
                            To pursue an online and distance Bachelor of Library
                            and Information Science. one has to be completed
                            graduation from any recognised university. There are
                            no entrance examinations for admission in online and
                            distance courses.
                        </p>
                        <table class="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Programme fee</th>
                                    <th>Examination fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Rs. 14000 Per Year</td>
                                    <td>Rs. 2000 Per Year</td>
                                </tr>
                            </tbody>
                        </table>
                        <h6>Payment Mode</h6>
                        <div style="padding-top: 20px">
                            Student can submit his fee in cash to cash counter
                            in department or submit online<br />
                            <b
                                >For online payment, Account Details are as
                                follow :</b
                            >
                            <br /><br />
                            <table
                                style="width: 100%"
                                cellpadding="0"
                                cellspacing="0"
                                border="1"
                            >
                                <tbody>
                                    <tr>
                                        <td>
                                            <b>Account Holder Name:</b> Swami
                                            Vivekanand Subharti University
                                            Distance Education
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="height: 24px">
                                            <b>Account Number:</b> 628501043170
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>IFSC Code:</b> ICIC0006285</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <b>Branch:</b> 65 Shiv Puri Hapur
                                            Road Meerut
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br />
                        <h6><strong>Career Prospects</strong></h6>
                        <p>
                            Completed after a Bachelor of Library and
                            Information Science student has various options for
                            employment, both in the government and private
                            sectors.
                        </p>
                        <h6>Government Sector-</h6>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Foreign embassies
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Library Attendant
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Library Assistant
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Application Specialist
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Consultant/Reference Librarian
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Record Manager
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Law Librarian
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Museums and galleries (Where reading rooms and
                                research facilities)
                            </li>
                        </ul>
                        <h6>Private Sector-</h6>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Public libraries
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Universities/colleges/schools and other
                                academic institutions
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Private organizations
                            </li>
                            <br />
                            <h6>Best study options after B.A</h6>
                            <ul>
                                <li>
                                    <i
                                        class="bx bx-chevron-right"
                                        style="color: #29176d"
                                    ></i
                                    >Master’s Course in Library and Information
                                    Science
                                </li>
                                <li>
                                    <i
                                        class="bx bx-chevron-right"
                                        style="color: #29176d"
                                    ></i
                                    >M. Phil. in Library and Information Science
                                </li>
                            </ul>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</div>
