<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Blog</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="text-center">
                            <img
                                src="assets/img/blog/how-to-get-admission-open.png"
                                alt="image"
                                style="height: 400px; width: 800px"
                            />
                        </div>
                        <h3
                            style="
                                text-align: center;
                                margin-top: 25px;
                                font-weight: 600;
                            "
                        >
                            How to get admission in Subharti University?
                        </h3>
                        <p>
                            In 2008, Swami Vivekananda Subharti University was
                            founded in Meerut, Uttar Pradesh. Distance learning
                            programmes offered by the institution have been
                            authorised by the UGC's Distance Education Bureau
                            and the All India Council for Technical Education
                            (AICTE). An "A" was awarded by NAAC to the
                            institution in 2006.
                        </p>
                        <p>
                            All levels of education from undergraduate to
                            postgraduate as well as diploma and certificate
                            programmes are available at Swami Vivekananda
                            Subharti University.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    A minimum of 45% in a combination of
                                    Physics, Mathematics, and one other science
                                    (Biology, Chemistry, or Computer Science) is
                                    required for admission to the Bachelor of
                                    Technology programme.
                                </p>
                            </li>
                            <li>
                                <p>
                                    A three-year Engineering Diploma or a
                                    Bachelor of Science with Mathematics as a
                                    required major are prerequisites for B.Tech.
                                    Lateral Entry.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Both the SNET and the qualifying test
                                    results will be used in making the B.Tech.
                                    and B.Tech. Lateral Entry selections,
                                    respectively.
                                </p>
                            </li>
                            <li>
                                <p>
                                    A candidate must have completed a 10+2
                                    programme with the appropriate subject
                                    combinations and relevant percentage of
                                    marks in order to be eligible for admission
                                    to any of the medical courses within the
                                    bachelor's programme.
                                </p>
                            </li>
                            <li>
                                Admission to a medical bachelor's programme is
                                dependent on a candidate's performance in a
                                qualifying test; for MBBS and BDS, this is a
                                legitimate NEET score.
                            </li>
                        </ul>
                        <h4>Method to apply for getting admission</h4>
                        <p>
                            There are few options for submitting applications
                            and getting admission to the institution. Candidates
                            have the option of selecting the best possible
                            option. The application procedure is outlined in
                            full below.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    The application is available for download on
                                    the university's website. You must submit a
                                    properly filled-out form to the college. A
                                    Demand Draft (DD) for Rs.1,500 payable to
                                    "Swami VivekanandSubharti University" will
                                    be drawn.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Another option is for the applicant to pay
                                    using NEFT/RTGS. Check the official website
                                    for the account number.
                                </p>
                            </li>
                            <li>
                                <p>
                                    The third method is for potential students
                                    to print out an application from the Central
                                    Admission Cell's website and mail it in with
                                    a check or money order.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Another option is to apply directly to the
                                    institution and physically complete the
                                    application and requisite steps there at the
                                    point.
                                </p>
                            </li>
                        </ul>
                        <p>
                            After the application process is complete, the names
                            of accepted students will be posted on the
                            university's website.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_subharti_posts_thumb">
                        <h3 class="widget-title">RECENT BLOG</h3>
                        <article class="item">
                            <a
                                routerLink="/blog/is-subharti-university-good-for-distance-mba"
                                class="thumb"
                            >
                                <span
                                    class="fullimage cover bg1"
                                    role="img"
                                ></span>
                            </a>
                            <div class="info">
                                <!--<span>June 10, 2020</span>-->
                                <h4 class="title usmall">
                                    <a
                                        routerLink="/blog/is-subharti-university-good-for-distance-mba"
                                        >Is Subharti's university degree valid
                                        for government jobs?</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a
                                routerLink="/blog/is-subharti-university-good-for-distance-mba"
                                class="thumb"
                            >
                                <span
                                    class="fullimage cover bg2"
                                    role="img"
                                ></span>
                            </a>
                            <div class="info">
                                <!--<span>June 21, 2020</span>-->
                                <h4 class="title usmall">
                                    <a
                                        routerLink="/blog/is-subharti-university-good-for-distance-mba"
                                        >Is Subharti University Good for
                                        Distance MBA</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a
                                routerLink="/blog/why-choose-subharti-university-for-distance-learning"
                                class="thumb"
                            >
                                <span
                                    class="fullimage cover bg3"
                                    role="img"
                                ></span>
                            </a>
                            <div class="info">
                                <!--<span>June 30, 2020</span>-->
                                <h4 class="title usmall">
                                    <a
                                        routerLink="/blog/why-choose-subharti-university-for-distance-learning"
                                        >Why choose Subharti university for
                                        Distance learning?</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Quick Links</h3>
                        <ul>
                            <!--<li><a routerLink="/diploma"> <span class="post-count"
                                                                style="color: black;">Diploma Courses</span></a></li>
                            <li><a routerLink="/advanced-diploma"> <span class="post-count" style="color: black;">Advanced Diploma</span></a>
                            </li>-->
                            <li>
                                <a routerLink="/degree-courses">
                                    <span
                                        class="post-count"
                                        style="color: black"
                                        >Degree Courses</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a routerLink="/pg-courses">
                                    <span
                                        class="post-count"
                                        style="color: black"
                                        >PG Courses</span
                                    ></a
                                >
                            </li>
                            <!-- <li><a routerLink="/pg-diploma-courses"> <span class="post-count" style="color: black;">PG Diploma Courses</span></a>
                            </li>-->
                            <!-- <li><a routerLink="/new-courses"> <span class="post-count"
                                                                    style="color: black;">New Courses</span></a></li> -->
                        </ul>
                    </div>
                    <!--      <div class="widget widget_tag_cloud">
                              <h3 class="widget-title">Popular Tags</h3>
                              <div class="tagcloud">
                                  <a routerLink="/blog-4">Business <span class="tag-link-count"> (3)</span></a>
                                  <a routerLink="/blog-4">Design <span class="tag-link-count"> (3)</span></a>
                                  <a routerLink="/blog-4">Braike <span class="tag-link-count"> (2)</span></a>
                                  <a routerLink="/blog-4">Fashion <span class="tag-link-count"> (2)</span></a>
                                  <a routerLink="/blog-4">Travel <span class="tag-link-count"> (1)</span></a>
                                  <a routerLink="/blog-4">Smart <span class="tag-link-count"> (1)</span></a>
                                  <a routerLink="/blog-4">Marketing <span class="tag-link-count"> (1)</span></a>
                                  <a routerLink="/blog-4">Tips <span class="tag-link-count"> (2)</span></a>
                              </div>
                          </div>-->
                </aside>
            </div>
        </div>
    </div>
</div>
