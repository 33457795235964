<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>ASSIGNMENT 5TH SEMESTER AUGUST 2022</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-text-container">
                    <h4 style="background: #29176d;color: white;padding: 21px; margin-bottom:15px">ASSIGNMENT 5TH
                        SEMESTER AUGUST 2022</h4>
                    <div class="rightcategory_box">

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/B.COM.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.Com</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/B.A.(ECONOMICS).pdf"
                                       style="font-size:18px; font-weight:400">Assignment for B.A.(Economics)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/B.A.(MATHEMATICS).pdf"
                                       style="font-size:18px; font-weight:400">Assignment for BA-MATH</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/B.A.(ENGLISH).pdf"
                                       style="font-size:18px; font-weight:400">Assignment for BA-ENGLISH</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/B.A.(POLITICAL%20SCIENCE).pdf"
                                       style="font-size:18px; font-weight:400">Assignment for BA-POLITICAL SCIENCE</a>
                                </h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/B.A.(FASHION%20DESIGN).pdf"
                                       style="font-size:18px; font-weight:400">Assignment for BA(FASHION DESIGN)</a>
                                </h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/B.SC.(PCM).pdf"
                                       style="font-size:18px; font-weight:400">Assignment for B.SC(PCM)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/B.SC.(ZBC).pdf"
                                       style="font-size:18px; font-weight:400">Assignment for B.SC(ZBC)</a></h6>

                            </div>
                        </div>


                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/B.A.(HINDI).pdf"
                                       style="font-size:18px; font-weight:400"> Assignment for B.A.(Hindi)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/B.A.(HISTORY).pdf"
                                       style="font-size:18px; font-weight:400"> Assignment for BA-HISTRY</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/B.A.(FINE%20ARTS).pdf"
                                       style="font-size:18px; font-weight:400"> Assignment for BA-FINE ARTS</a></h6>
                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/B.A.(SOCIOLOGY).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Sociology)</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/B.A.(JMC).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(JMC)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/BBA.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for BBA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/5TH SEMESTER/BCA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for BCA</a></h6>

                            </div>
                        </div>
                        <br class="clear">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

