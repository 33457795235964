<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>About CDOE (Formerly DDE)</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Why CDOE (Formerly DDE), SVSU?</h3>
                        </div>
                        <p>Distance Education plays a crucial role in enriching the knowledge base of any society. The
                            need & importance of Distance Education is becoming more relevant in modern age where it
                            becomes diﬃcult to impart education to masses in conventional regular mode. SVSU understands
                            the aforesaid need in India & further it has ﬁrmly understood that innovative professional
                            education has to be spread to the general masses. This is how the Distance Education Program
                            of the Swami Vivekanand Subharti University was conceived in 2009.</p>
                        <p>Distance Education Program of CDOE (Formerly DDE), SVSU is a unique program of its kind. With its experience
                            of 13 years of running distance education across the country, the university has evolved its
                            Distance Education Program to the best in class standards. Following are the key reasons to
                            pursue a course under Distance Education Program of CDOE (Formerly DDE), SVSU:</p>
                        <h4>UGC approved Private University</h4>
                        <p>Swami Vivekanand Subharti University is a State Government University established under
                            section 2(f) of the University Grants Commission Act, 1956 set up under the Swami Vivekanand
                            Subharti Vishwavidyalaya Uttar Pradesh Adhinium, 2008 (U.P. Act No. 29 of 2008), as passed
                            by the Uttar Pradesh Legislature and assented to by the Honorable Governor of Uttar Pradesh.
                            As per UGC leer no. F.9.37/2008 (CPP-1), DATED 06.02.2009, Swami Vivekanand Subharti
                            University, being a State Private University, is competent to award degrees speciﬁed in
                            Section 22 of the UGC Act 1956.</p>
                        <h5>DEB (UGC) Recognition</h5>
                        <p>Distance Education Bureau-University Grants Commission has given recognition to CDOE (Formerly DDE) SVSU for
                            oﬀering Programs through Distance Education Mode vide leer no. DEC/Recog/2009/3174, dated
                            09.09.2009, on the recommendation of the Joint Committee of the University Grants
                            Commission, All India Council for Technical Education and Distance Education Council and
                            extended subsequently vide leer no. DEC/SVSU/ UP14621-14623, dated17.09.2012,
                            UGC/DEB/Recog/Dayalbagh/Vol.II/74717475, dated 05.06.2014, UGC/DEB/SVSU/2013, dated
                            28.09.2015 & F.No. 49-1/2016(DEB-III), dated 01-07-2016, F.No. 174/2018 (DEB-I), dated
                            26.10.2018, F.No. 17-4/2018 (DEB-I), dated 12.02.2021, F.No. 40-10/2021 (ODL) (DEB-II),
                            dated 12.11.2021, F.No. 40-10/2021 (ODL) (DEB-II), dated 27.12.2021 for oﬀering Distance
                            Learning Programs. The Programs conducted by the University through distance Education are
                            identical in contents to the courses oﬀered through the normal mode. Consequently, as per
                            Government of India Gazette Notiﬁcation No. 44, F.No. 18-15/93-TD.V/TS-IV, dated 01.03.1995
                            and MHRD Notiﬁcation no. 6-1/2013/DL, dated 10.06.2016, the degrees awarded by the
                            University through distance Education stand automatically recognized for all purposes,
                            including employment to posts & services.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

