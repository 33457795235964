<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>NEW LAUNCHED PROGRAMMES</h2>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<div class="events-area bg-f9f9f9 pt-70" style="margin-top: -50px;">
    <div class="container">
        <div class="row align-items-center">
            <h4 class="inter-font" style="text-align: center;"><span>NEW LAUNCHED PROGRAMMES</span></h4>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> New Launched Programmes : MA (Education)
                        </span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> Proposed Pragrammes to be launched in next two years : MBA (Retail Management)</span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> MSC (Mathematics)</span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> MBA (Retail Management)</span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> MBA (Banking)</span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> MBA (Rural Development)</span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> MA (Mathematics)</span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> MA (Economics)</span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> BA (Painting,Textile,Interior Design,Home Science)</span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> BFA</span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> MFA</span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> BPA</span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> MPA</span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> BCOM (Hons.)</span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <div class="div">
                                <span>Click</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
