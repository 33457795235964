<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Master of Library and Information Science (MLIS)</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!--<div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Introduction</h3>
                        </div>
                        <p>
                            A Master of Library and Information Science (MLIS)
                            is a one-year professional post-graduate degree
                            program that focuses on cutting-edge library theory
                            and data analysis. Graduates can find work in a
                            variety of fields related to libraries, such as
                            librarians, associate information analysts,
                            information architects, academic library attendants,
                            experts in the application of integrated library
                            systems, and so on.
                        </p>
                        <h4>Objective</h4>
                        <p>
                            An academic librarian's job is to ensure that the
                            library's collection is well-managed and accessible
                            to its many users. They monitor the vast array of
                            books, archives, and other study guides that are in
                            regular circulation. Earning a Master's degree in
                            Library and Information Science in India is a great
                            way to expand your horizons in both the Information
                            Science and Library administration fields.
                        </p>
                        <p>
                            The curriculum of a Master of Library and
                            Information Science program equips students with the
                            academic and practical knowledge necessary to excel
                            in the library and information science fields.
                            Knowledge organization systems can be designed and
                            developed after earning a MLIS degree.
                        </p>
                        <p>
                            Personalized reading lists and recommendations are a
                            breeze to make. Acquiring a Master in Library and
                            Information Science opens up a wealth of additional
                            doors for those interested in the preservation of
                            knowledge and the provision of information to
                            students and professionals on demand.
                        </p>
                        <p>
                            The Master of Library and Information Science (MLIS)
                            degree offers a highly respected and lucrative
                            profession. The typical annual salary for an MLIS
                            graduate is between INR 3 and 4 Lakhs. Considering
                            the breadth of employment prospects in India and
                            beyond that this degree offers, there is an
                            abundance of employment opportunities.
                        </p>
                        <h5>Programme Duration</h5>
                        <p>
                            Master of Library and Information Science is a
                            post-graduate degree program and this programme is
                            one year course.
                        </p>
                        <p>
                            Minimum Duration 1 Years<br />Maximum Duration 2
                            Year
                        </p>
                        <h6>Eligibility & Fee</h6>
                        <p>
                            Online/distance Master of Library and Information
                            Science eligibility criteria are too very flexible.
                            To pursue an online and distance MLIS one has to be
                            completed graduation from any recognised university.
                            There are no entrance examinations for admission in
                            this course.
                        </p>
                        <table class="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Programme fee</th>
                                    <th>Examination fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Rs. 18000 Per Year</td>
                                    <td>Rs. 2000 Per Year</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6 style="font-weight: bold">Payment Mode</h6>
                        <p>
                            Student can submit his fee in cash to cash counter
                            in department or submit online
                            <b
                                >For online payment, Account Details are as
                                follow :</b
                            >
                        </p>
                        <table
                            style="width: 100%"
                            cellpadding="0"
                            cellspacing="0"
                            border="1"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Account Holder Name:</b> Swami
                                        Vivekanand Subharti University Distance
                                        Education
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height: 24px">
                                        <b>Account Number:</b> 628501043170
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>IFSC Code:</b> ICIC0006285</td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>Branch:</b> 65 Shiv Puri Hapur Road
                                        Meerut
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h6><strong>Career Prospects</strong></h6>
                        <p>
                            After post-graduation student has various options
                            for employment, both in the government and private
                            sectors.
                        </p>
                        <!--  <h6 style="font-weight: bold;">Government Sector-</h6>-->
                        <p>There are many designations for MLIS Students:</p>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Public/Government libraries
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Librarian/Chief Librarian
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Universities/colleges/schools and other
                                academic institutions
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >News agencies and organizations
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i>
                                Private organizations and special libraries
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Law library/Special library
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Director/Head of Information Centre
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Consultant/Reference Librarian
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Photo/film/radio/television libraries
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Foreign embassies
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Information Architect Companies and
                                organizations with large information handling
                                requirements
                            </li>
                        </ul>
                        <div class="container p-4" style="text-align: justify">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h6 style="font-weight: bold">
                                        Best study options after Master of
                                        Library and Information Science
                                    </h6>
                                    <p>
                                        Graduates who are enthusiastic about
                                        continuing their studies in the field of
                                        education and have already earned their
                                        Master of Library and Information
                                        Science degree can pursue-
                                    </p>
                                    <ul>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >M.Phil.
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Ph.D.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</div>
