import {Component, OnInit} from '@angular/core';


@Component({
    selector: 'app-distance-learning',
    templateUrl: './distance-learning.component.html',
    styleUrls: ['./distance-learning.component.scss']
})
export class DistanceLearningComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
