<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Assignments for Exam 2022 - 5th Semester</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-text-container">
                    <h4 style="background: #29176d;color: white;padding: 21px; margin-bottom:15px">Assignments for Exam
                        2022 - 5th Semester</h4>
                    <div class="rightcategory_box">

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/B.COM.doc"
                                       style="font-size:18px; font-weight:400"> Assignments for B.Com</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/BA-ECONOMICS.doc"
                                       style="font-size:18px; font-weight:400">Assignment for B.A.(Economics)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/BA-MATH.doc"
                                       style="font-size:18px; font-weight:400">Assignment for BA-MATH</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/BA-ENGLISH (2).doc"
                                       style="font-size:18px; font-weight:400">Assignment for BA-ENGLISH</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/BA-POLITICAL SCIENCE (1).doc"
                                       style="font-size:18px; font-weight:400">Assignment for BA-POLITICAL SCIENCE</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/BA(FASHION%20DESIGN).doc"
                                       style="font-size:18px; font-weight:400">Assignment for BA(FASHION DESIGN)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/B.SC(PCM).doc"
                                       style="font-size:18px; font-weight:400">Assignment for B.SC(PCM)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/B.SC(ZBC).doc"
                                       style="font-size:18px; font-weight:400">Assignment for B.SC(ZBC)</a></h6>

                            </div>
                        </div>


                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/BA-HINDI.doc"
                                       style="font-size:18px; font-weight:400"> Assignment for B.A.(Hindi)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/BA-HISTRY%20(2).doc"
                                       style="font-size:18px; font-weight:400"> Assignment for BA-HISTRY</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/BA-FINE ARTS.doc"
                                       style="font-size:18px; font-weight:400"> Assignment for BA-FINE ARTS</a></h6>
                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/BA-SOCIOLOGY.doc"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Sociology)</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/BA-JMC.doc"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(JMC)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/BBA.doc"
                                       style="font-size:18px; font-weight:400"> Assignments for BBA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT 5TH SEMESTER, JUNE, 22 (2)/BCA.doc"
                                       style="font-size:18px; font-weight:400">Assignments for BCA</a></h6>

                            </div>
                        </div>
                        <br class="clear">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

