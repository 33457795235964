import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from 'src/shared/servies/meta.service';
import { TitleService } from 'src/shared/servies/title.service';
@Component({
    selector: 'app-dde',
    templateUrl: './dde.component.html',
    styleUrls: ['./dde.component.scss'],
})
export class DdeComponent implements OnInit {
    universityUrl: any;
    course: any = [];
    url: any;
    parts: any;
    lastParam: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private titleService: TitleService,
        private titleLibraryService: Title,
        private metaServices: MetaService,
        private metaService: Meta
    ) {
        this.url = window.location.pathname;
        this.parts = this.url.split('/');
        this.lastParam = this.parts[this.parts.length - 1];
    }

    ngOnInit(): void {
        this.getAllTitleJson();
    }
    getAllTitleJson() {
        this.titleService.getAboutTitleJson().subscribe((res) => {
            this.course = res.titles;
            console.log('this.course', this.course);
            if (this.lastParam && this.course.length > 0) {
                this.course.forEach(
                    (courseUrl: {
                        twitter_idrmge: string;
                        og_title: string;
                        og_url: string;
                        url: any;
                        title: string;
                        keywords: any;
                        descriptions: any;
                    }) => {
                        if (courseUrl.url === this.lastParam) {
                            this.titleLibraryService.setTitle(courseUrl.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: '',
                                },
                                { name: 'description', content: '' },
                                { property: 'og:title', content: '' },
                                { property: 'og:url', content: '' },
                                { property: 'og:type', content: '' },
                                { property: 'og:description', content: '' },
                                { property: 'og:image', content: '' },
                                { property: 'og:ib.scge', content: '' },
                                { property: 'og:im.scge', content: '' },
                                { property: 'og:ib.edge', content: '' },
                                { property: 'og:ib.libge', content: '' },
                                { property: 'og:im.libge', content: '' },
                                {
                                    property: 'og:ima journalismge',
                                    content: '',
                                },
                                { property: 'og:idfmge', content: '' },
                                { property: 'og:idmmge', content: '' },
                                { property: 'og:idrmge', content: '' },
                                { name: 'twitter:card', content: '' },
                                { name: 'twitter:title', content: '' },
                                { name: 'twitter:description', content: '' },
                                { name: 'twitter:url', content: '' },
                                { name: 'twitter:image', content: '' },
                                { name: 'twitter:ib.scge', content: '' },
                                { name: 'twitter:im.scge', content: '' },
                                { name: 'twitter:ib.edge', content: '' },
                                { name: 'twitter:ib.libge', content: '' },
                                { name: 'twitter:im.libge', content: '' },
                                {
                                    name: 'twitter:ima journalismge',
                                    content: '',
                                },
                                { name: 'twitter:idfmge', content: '' },
                                { name: 'twitter:idmmge', content: '' },
                                { name: 'twitter:idrmge', content: '' },
                            ]);

                            this.metaService.updateTag({
                                name: 'keywords',
                                content: courseUrl.keywords,
                            });
                            this.metaService.updateTag({
                                name: 'description',
                                content: courseUrl.descriptions,
                            });
                            this.metaService.updateTag({
                                property: 'og:title',
                                content: courseUrl.og_title,
                            });
                            this.metaService.updateTag({
                                property: 'og:url',
                                content: courseUrl.og_url,
                            });
                            // this.metaService.updateTag({
                            //     property: 'og:type',
                            //     content: courseUrl.og_type,
                            // });
                            // this.metaService.updateTag({
                            //     property: 'og:description',
                            //     content: courseUrl.og_description,
                            // });
                            // this.metaService.updateTag({
                            //     property: 'og:image',
                            //     content: courseUrl.og_image,
                            // });
                            // this.metaService.updateTag({
                            //     property: 'og:ib.scge',
                            //     content: courseUrl.og_ib_scge,
                            // });
                            // this.metaService.updateTag({
                            //     property: 'og:im.scge',
                            //     content: courseUrl.og_im_scge,
                            // });
                            // this.metaService.updateTag({
                            //     property: 'og:ib.edge',
                            //     content: courseUrl.og_ib_edge,
                            // });
                            // this.metaService.updateTag({
                            //     property: 'og:ib.libge',
                            //     content: courseUrl.og_ib_libge,
                            // });
                            // this.metaService.updateTag({
                            //     property: 'og:im.libge',
                            //     content: courseUrl.og_im_libge,
                            // });
                            // this.metaService.updateTag({
                            //     property: 'og:ima journalismge',
                            //     content: courseUrl.og_ima_journalismge,
                            // });
                            // this.metaService.updateTag({
                            //     property: 'og:idfmge',
                            //     content: courseUrl.og_idfmge,
                            // });
                            // this.metaService.updateTag({
                            //     property: 'og:idmmge',
                            //     content: courseUrl.og_idmmge,
                            // });
                            // this.metaService.updateTag({
                            //     property: 'og:idrmge',
                            //     content: courseUrl.og_idrmge,
                            // });
                            // this.metaService.updateTag({
                            //     name: 'twitter:card',
                            //     content: courseUrl.twitter_card,
                            // });
                            // this.metaService.updateTag({
                            //     name: 'twitter:title',
                            //     content: courseUrl.twitter_title,
                            // });
                            // this.metaService.updateTag({
                            //     name: 'twitter:description',
                            //     content: courseUrl.twitter_description,
                            // });
                            // this.metaService.updateTag({
                            //     name: 'twitter:image',
                            //     content: courseUrl.twitter_image,
                            // });
                            // this.metaService.updateTag({
                            //     name: 'twitter:ib.scge',
                            //     content: courseUrl.twitter_ib_scge,
                            // });
                            // this.metaService.updateTag({
                            //     name: 'twitter:im.scge',
                            //     content: courseUrl.twitter_im_scge,
                            // });
                            // this.metaService.updateTag({
                            //     name: 'twitter:ib.edge',
                            //     content: courseUrl.twitter_ib_edge,
                            // });
                            // this.metaService.updateTag({
                            //     name: 'twitter:ib.libge',
                            //     content: courseUrl.twitter_ib_libge,
                            // });
                            // this.metaService.updateTag({
                            //     name: 'twitter:im.libge',
                            //     content: courseUrl.twitter_im_libge,
                            // });
                            // this.metaService.updateTag({
                            //     name: 'twitter:ima journalismge',
                            //     content: courseUrl.twitter_ima_journalismge,
                            // });
                            // this.metaService.updateTag({
                            //     name: 'twitter:idfmge',
                            //     content: courseUrl.twitter_idfmge,
                            // });
                            this.metaService.updateTag({
                                name: 'twitter:idmmge',
                                content: courseUrl.twitter_idrmge,
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idrmge',
                                content: courseUrl.twitter_idrmge,
                            });
                        }
                    }
                );
            }
        });
    }
}
