import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-details-on-deb-website',
  templateUrl: './details-on-deb-website.component.html',
  styleUrls: ['./details-on-deb-website.component.scss']
})
export class DetailsOnDebWebsiteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
