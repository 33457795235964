<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>VISION & MISSION</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="features-area pt-100 pb-70">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3">
                </div>
                <div class="col-lg-6 col-sm-12 col-md-12">
                    <div class="d-none d-lg-block" style="margin-top: -150px;"></div>
                    <div class="single-features-box text-center" style="background-color: #3b5998">
                        <h3>शिक्षा सेवा संस्कार एवं राष्ट्रीयता</h3>
                    </div>
                </div>
                <div class="col-lg-3">
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>VISION</h3>
                        <p>To be an acclaimed University that provides contemporary Technical and Professional
                            knowledge, skills as well as research opportunities befitting global scenarios while
                            maintaining Service, Sacrament, and Nationality.</p>
                        <h4>MISSION</h4>
                        <p>To develop programs of the highest standards, and to produce confident, self-reliant,
                            responsible youth having skills, social values, leadership, and entrepreneurship bent of
                            mind in highly competitive technologically advanced, ever-changing needs of society.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
