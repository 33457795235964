import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ma-economics',
  templateUrl: './ma-economics.component.html',
  styleUrls: ['./ma-economics.component.scss']
})
export class MaEconomicsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
