<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2 style="color: white;background-color: transparent;">COURSE SLM'S</h2>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-text-container">
                    <h4 style="background: #29176d;color: white;padding: 21px; margin-bottom:15px">Detail Syllabus</h4>
                    <div class="rightcategory_box">
                    <!--    <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    ADBA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/ADBA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                    <!--    <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    ADCA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/ADCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    B.Com
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/B.COM.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    B.Lib
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/B.lib.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                      <!--  <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BSc (PCM)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/B.SC(PCM).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                     <!--   <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BSc (ZBC)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/BSc(ZBC).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/BA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BBA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/BBA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                       <!-- <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BBA-HM
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/BBA-HM.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                 <!--       <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BCA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/BCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BA (JMC)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/BJMC.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                      <!--  <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    DBA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/DBA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    DCA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/DCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (English)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/MA(English).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (Hindi)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/MA(Hindi).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (History)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/MA(History).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (Pol. Science)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/MA(PoliticalScience).pdf">&lt;&lt;Download&gt;&gt;</a>
                                </h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (Public Admin)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/MA(PublicAdministration).pdf">&lt;&lt;Download&gt;&gt;</a>
                                </h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (Sociology)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/MA(Sociology).pdf">&lt;&lt;Download&gt;&gt;</a>
                                </h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (JMC)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/MA(JMC).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    M. Lib
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/M.lib.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                       <!-- <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MSc (CA)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/MSc(CA).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                   <!--     <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MSc (DFSM)
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/M.Sc(DFSM).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MBA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/MBA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                       <!-- <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MCA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/MCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MCom
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/MCom.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>

                        </div>
                <!--        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    PGDCA
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/PGDCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                       <!-- <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    PGDFSQM
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/PGDFSQM.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                       <!-- <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    PGDHHM
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/PGDHHM.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                      <!--  <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    PGDIPR
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/PGDIPR.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    PGDPPN
                                </h6>
                                <h2 align="center">
                                    <a href="assets/documents/PGDPPN.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
