<div
    class="navbar-area"
    ngStickyNav
    stickyClass="sticky-box-shadow"
    ngStickyNav
>
    <div class="container-fluid">
        <nav
            class="navbar navbar-expand-lg navbar-light bg-light"
            [class.active]="classApplied"
        >
            <a class="navbar-brand" routerLink="/"
                ><img
                    src="assets/img/log_1.jpg"
                    alt="logo"
                    style="height: 59px"
            /></a>

            <button
                class="navbar-toggler"
                type="button"
                (click)="toggleClass()"
            >
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"
                            >About Us <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a
                                    routerLink="/dde"
                                    class="nav-link"
                                    (click)="goToRefresh('/dde')"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >CDOE (Formerly DDE)</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/university"
                                    class="nav-link"
                                    (click)="goToRefresh('/university')"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >University</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/director-messege"
                                    (click)="goToRefresh('/director-messege')"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Director's Messege</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    href="assets/documents/Resolution-for-DDE.pdf"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Resolution For CDOE (Formerly DDE)</a
                                >
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >Recognitions
                                    <i class="bx bx-chevron-right"></i
                                ></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/2f.pdf"
                                            target="_blank"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Section 2(F)</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ImportantDocuments/Territorial Jurisdiction.pdf"
                                            target="_blank"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Teritorial Juricdistion</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/recognition"
                                            (click)="
                                                goToRefresh('/recognition')
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >ODL Mode</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/UGC_Univ_%20Approval.pdf"
                                            target="_blank"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Regular Mode</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/2022-23_No%20Objection%20Certificate%201-11278338841%20(1)%20(1).pdf"
                                            target="_blank"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >AICTE</a
                                        >
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >Act And Statutes
                                    <i class="bx bx-chevron-right"></i
                                ></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ImportantDocuments/HEI-U-0543_CopyRelevent_20230330165505.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >University Act</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ImportantDocuments/Statutory bodies approval upload_OL.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Statutury Body Approval</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/OL Documents/OL-Act.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >ACT to Start OL</a
                                        >
                                    </li>
                                </ul>
                            </li>
                            <!-- <li class="nav-item">
                                 <li class="nav-item">
                                <a
                                    href="assets/documents/UmbrellaAct.pdf"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Act And Statutes</a
                                >
                            </li>
                            </li> -->

                            <li class="nav-item">
                                <a
                                    href="https://www.ugc.ac.in/pdfnews/FINAL%20RECOGNITION%20STATUS%2008-05-2019.pdf"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Details On DEB Website</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/compliance"
                                    (click)="goToRefresh('/compliance')"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >DEB Compliance</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/vision-mission"
                                    (click)="goToRefresh('/vision-mission')"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Vision & Mission
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/world-of-subharti"
                                    (click)="goToRefresh('/world-of-subharti')"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >World Of Subharti
                                </a>
                            </li>
                            <!-- <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >Faculty Details
                                    <i class="bx bx-chevron-right"></i
                                ></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ImportantDocuments/Teaching_Faculty.pdf"
                                            target="_blank"
                                            >1. ODL Faculty</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ImportantDocuments/Ol_Faculties_details.pdf"
                                            target="_blank"
                                            >2. OL Faculty</a
                                        >
                                    </li>
                                </ul>
                            </li> -->
                            <li class="nav-item"></li>
                            <!-- <li class="nav-item">
                                <a
                                    href="faculty-details"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Faculty Details
                                </a>
                            </li> -->
                            <li class="nav-item">
                                <a
                                    href="assets/documents/ImportantDocuments/Administrative_staff.pdf"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Administrative Staff
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="assets/documents/Infrastructure.pdf"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Infrastructure
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"
                            >Programmes <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <!-- <li class="nav-item">
                                <a
                                    routerLink="/all-courses"
                                    (click)="goToRefresh('/all-courses')"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >All Courses
                                </a>
                            </li> -->
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >UG<i class="bx bx-chevron-right"></i
                                ></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            routerLink="/programme/bachelor-of-arts"
                                            (click)="
                                                goToRefresh(
                                                    '/programme/bachelor-of-arts'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Bachelor Of Arts</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/programme/bachelor-of-arts-jmc"
                                            (click)="
                                                goToRefresh(
                                                    '/programme/bachelor-of-arts-jmc'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Bachelor Of Arts-JMC</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            (click)="
                                                goToRefresh(
                                                    '/programme/bachelor-of-library-and-information-sciences'
                                                )
                                            "
                                            routerLink="/programme/bachelor-of-library-and-information-sciences"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Bachelor Of Library And Information
                                            Sciences</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/programme/bechelor-of-business-administration"
                                            (click)="
                                                goToRefresh(
                                                    '/programme/bechelor-of-business-administration'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Bachelor Of Business
                                            Administration</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/programme/bachelor-of-commerce"
                                            (click)="
                                                goToRefresh(
                                                    '/programme/bachelor-of-commerce'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Bachelor Of Commerce</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/programme/bachelor-of-commerce-honors"
                                            (click)="
                                                goToRefresh(
                                                    '/programme/bachelor-of-commerce-honors'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Bachelor Of Commerce(Honors)</a
                                        >
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >PG<i class="bx bx-chevron-right"></i
                                ></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            routerLink="/programme/master-of-business-administration"
                                            (click)="
                                                goToRefresh(
                                                    '/programme/master-of-business-administration'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Master Of Business
                                            Administration</a
                                        >
                                        <!-- <a href="#"
                                            >Master Of Business
                                            Administration</a
                                        > -->
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/programme/ma"
                                            (click)="
                                                goToRefresh('/programme/ma')
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Master Of Arts</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            (click)="
                                                goToRefresh(
                                                    '/programme/master-of-library-and-information-sciences'
                                                )
                                            "
                                            routerLink="/programme/master-of-library-and-information-sciences"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Master Of Library And Information
                                            Sciences</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/programme/ma-home-science"
                                            (click)="
                                                goToRefresh(
                                                    '/programme/ma-home-science'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >MA Home Science</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/programme/ma-journalism-and-mass-communication"
                                            (click)="
                                                goToRefresh(
                                                    '/programme/ma-journalism-and-mass-communication'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Ma Journalism And Mass
                                            Communication</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/programme/master-of-commece"
                                            (click)="
                                                goToRefresh(
                                                    '/programme/master-of-commece'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Master Of Commerce</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/programme/ma-public-administration"
                                            (click)="
                                                goToRefresh(
                                                    '/programme/ma-public-administration'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >MA Public Administration</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/programme/ma-buddhist-studies"
                                            (click)="
                                                goToRefresh(
                                                    '/programme/ma-buddhist-studies'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >MA Buddhist Studies</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/programme/ma-education"
                                            (click)="
                                                goToRefresh(
                                                    '/programme/ma-education'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >MA Education</a
                                        >
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"
                            >Academic <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a
                                    routerLink="/admission-procedure"
                                    (click)="
                                        goToRefresh('/admission-procedure')
                                    "
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Admission Procedure</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/credit-transfer-policy"
                                    (click)="
                                        goToRefresh('/credit-transfer-policy')
                                    "
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Credit Transfer Policy</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/latest-entry-policy"
                                    (click)="
                                        goToRefresh('/latest-entry-policy')
                                    "
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Lateral Entry Policy</a
                                >
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >Fee Structure
                                    <i class="bx bx-chevron-right"></i
                                ></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            routerLink="/for-session-2021-22"
                                            (click)="
                                                goToRefresh(
                                                    '/for-session-2021-22'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >For Session 2021-22</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/for-session-2022-23"
                                            (click)="
                                                goToRefresh(
                                                    '/for-session-2022-23'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >For Session 2022-23</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            routerLink="/for-session-2023-24"
                                            (click)="
                                                goToRefresh(
                                                    '/for-session-2023-24'
                                                )
                                            "
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >For Session 2023-24</a
                                        >
                                    </li>
                                </ul>
                            </li>
                            <!-- <li class="nav-item">
                                <a
                                    href="assets/documents/FeeRefundPolicy.pdf"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Refund Policy</a
                                >
                            </li> -->
                            <li class="nav-item">
                                <a
                                    routerLink="/semester-syllabus"
                                    (click)="goToRefresh('/semester-syllabus')"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Semester Syllabus 2021</a
                                >
                            </li>
                            <!-- <li class="nav-item"><a routerLink="/detailed-syllabus"
                                                    (click)="goToRefresh('/detailed-syllabus')"
                                                    class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Detailed
                                Syllabus</a></li> -->
                            <li class="nav-item">
                                <a
                                    routerLink="/assignments"
                                    (click)="goToRefresh('/assignments')"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Assignments
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/guidelines"
                                    (click)="goToRefresh('/guidelines')"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Guidelines
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="assets/documents/ImportantDocuments/black-list-details.pdf"
                                    >Black Listed
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/academic-calender"
                                    (click)="goToRefresh('/academic-calender')"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Academic Calender
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/admission-details"
                                    (click)="goToRefresh('/admission-details')"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Academic Details
                                </a>
                            </li>
                            <!-- <li class="nav-item">
                                <a
                                    routerLink="/course-slm"
                                    (click)="goToRefresh('/course-slm')"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Course SLM's
                                </a>
                            </li> -->

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >PPR & SLM Details
                                    <i class="bx bx-chevron-right"></i
                                ></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            href="javascript:void(0)"
                                            class="nav-link"
                                            >PPR for OL Programmes
                                            <i class="bx bx-chevron-right"></i
                                        ></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <a
                                                    href="assets/documents/OL Documents/BA-JMC.pdf"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    target="_blank"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >BA (JMC)</a
                                                >
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    href="assets/documents/OL Documents/MA (JMC).pdf"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    target="_blank"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >MA (JMC)</a
                                                >
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    href="assets/documents/MBA.pdf"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    target="_blank"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >MBA</a
                                                >
                                            </li>

                                            <li class="nav-item">
                                                <a
                                                    href="assets/documents/OL Documents/MA-Hindi.pdf"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    target="_blank"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >MA (Hindi)</a
                                                >
                                            </li>

                                            <li class="nav-item">
                                                <a
                                                    href="assets/documents/OL Documents/MA - English.pdf"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    target="_blank"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >MA (English)</a
                                                >
                                            </li>

                                            <li class="nav-item">
                                                <a
                                                    href="assets/documents/OL Documents/MA (Buddhist).pdf"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    target="_blank"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >MA (Buddhist)</a
                                                >
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    href="assets/documents/OL Documents/MA (Political Science).pdf"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    target="_blank"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >MA (Political Science)</a
                                                >
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ImportantDocuments/Complience -9 -Plagrisim Report of MBA elm.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Sample Plagiarism Report</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/SLM.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >SLM Details</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ImportantDocuments/SLM_approval.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >SLM Approval</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ImportantDocuments/Proof_Of_Out Source.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >SLM Outsource</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ImportantDocuments/lettertoexternal.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Sample Outsourced Letter</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ImportantDocuments/PPR_Approval Letter.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >PPR Approval</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ppr/mba.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >MBA PPR(Sample)</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ppr/ELM-Readiness.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >ELM-Readiness</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ppr/ELM Approval Letter.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >ELM Approval Letter</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/ppr/E-LM Outsourced.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >E-LM Outsourced</a
                                        >
                                    </li>
                                </ul>
                            </li>
                            <!-- <li class="nav-item"></li>
                            <li class="nav-item">
                                <a
                                    href="assets/documents/SLM.pdf"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >
                                </a>
                            </li> -->
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >Departmental Activities
                                    <i class="bx bx-chevron-right"></i
                                ></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/Departmental Activities/02.02.2023.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >February</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/Departmental Activities/29.04.2023.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >April</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            href="assets/documents/Departmental Activities/23.05.2023.pdf"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >May</a
                                        >
                                    </li>
                                </ul>
                            </li>
                            <!-- <li class="nav-item">
                                <a
                                    href="assets/documents/Activity.pdf"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Departmental Activities
                                </a>
                            </li> -->
                            <li class="nav-item">
                                <a
                                    href="assets/documents/BestPracticie.pdf"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Best Practices
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="assets/documents/FACULTY_DETAILS.pdf"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Faculty Details
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"
                            >Examinations <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a
                                    routerLink="/examination-schedule"
                                    (click)="
                                        goToRefresh('/examination-schedule')
                                    "
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Examination Schedule</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/evalution-procedure"
                                    (click)="
                                        goToRefresh('/evalution-procedure')
                                    "
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Evalution Procedure</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    href="assets/documents/May2024&July2024_ExamCenters.pdf"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Examination Centers</a
                                >
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"
                            >Student Support <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a
                                    routerLink="/downloads"
                                    (click)="goToRefresh('/downloads')"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Downloads</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    href="assets/documents/GUIDELINES FOR STUDENTS TO APPLY EDUCATION CERTIFICATE (For Uploading).pdf"
                                    >Guidelines for students to applying the
                                    application form
                                    <img src="../../assets/img/0_new2p.gif"
                                /></a>
                            </li>
                            <!-- <li class="nav-item"><a routerLink="/course-co-ordinators" class="nav-link"
                                                    (click)="goToRefresh('/course-co-ordinators')"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Course
                                Co-Ordinators</a></li> -->
                            <li class="nav-item">
                                <a
                                    routerLink="/grievance"
                                    class="nav-link"
                                    (click)="goToRefresh('/grievance')"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Enquiry/Grievance Form</a
                                >
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"
                            >Results <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a
                                    href="http://182.74.167.41/studentlogin.aspx"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Student Login</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    href="https://ums.subhartidde.com/Account/StudentLogin.aspx"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Student Login(C 22/A 22-23)</a
                                >
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <!-- <a
                                                        routerLink="/student-login"
                                                        class="nav-link"
                                                        (click)="
                                                            goToRefresh('/student-login')
                                                        "
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Student Login</a
                                                    >
                         -->
                    </li>

                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"
                            >Student Login <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a
                                    href="http://182.74.167.41/studentlogin.aspx"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Student Login (Old Students)</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    href="https://ums.subhartidde.com/Account/StudentLogin.aspx"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Student Login (A/C Batch)</a
                                >
                            </li>
                            <!-- <li class="nav-item">
                                <a
                                    href="https://ddesubharti.com/Account/StudentLogin.aspx"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >STUDENT LOGIN</a
                                >
                            </li> -->

                            <li class="nav-item">
                                <a
                                    href="https://student.subhartide.com/auth/login"
                                    target="_blank"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >STUDENT LOGIN</a
                                >
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="https://lms.subhartidde.com/" class="nav-link"
                            >LMS
                        </a>
                    </li>
                    <li class="nav-item highlight-combined">
                        <a
                            href="assets/documents/FAKE MARK SHEETS Notice.pdf"
                            class="nav-link"
                            ><img src="../../../../assets/img/scam-alret.png" />
                        </a>
                    </li>
                </ul>

                <ul class="navbar-nav for-responsive">
                    <li class="nav-item highlight-combined">
                        <a
                            href="assets/documents/FAKE MARK SHEETS Notice.pdf"
                            class="nav-link"
                            ><img src="../../../../assets/img/scam-alret.png" />
                        </a>
                    </li>
                    <li class="nav-item megamenu">
                        <a href="javascript:void(0)" class="nav-link"
                            >About Us <i class="bx bx-chevron-down"></i
                        ></a>

                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li class="">
                                                    <a
                                                        routerLink="/dde"
                                                        class="nav-link"
                                                        (click)="
                                                            goToRefresh('/dde')
                                                        "
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >CDOE (Formerly DDE)</a
                                                    >
                                                </li>
                                                <li class="">
                                                    <a
                                                        routerLink="/university"
                                                        class="nav-link"
                                                        (click)="
                                                            goToRefresh(
                                                                '/university'
                                                            )
                                                        "
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >University</a
                                                    >
                                                </li>
                                                <li class="">
                                                    <a
                                                        routerLink="/director-messege"
                                                        class="nav-link"
                                                        (click)="
                                                            goToRefresh(
                                                                '/director-messege'
                                                            )
                                                        "
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Director's Messege</a
                                                    >
                                                </li>
                                                <li class="">
                                                    <a
                                                        href="assets/documents/Resolution-for-DDE.pdf"
                                                        target="_blank"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Resolution For CDOE
                                                        (Formerly DDE)</a
                                                    >
                                                </li>
                                                <li class="nav-item megamenu">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="nav-link"
                                                        >Recognitions
                                                        <i
                                                            class="bx bx-chevron-right"
                                                        ></i
                                                    ></a>
                                                    <ul class="dropdown-menu">
                                                        <li class="nav-item">
                                                            <div
                                                                class="container"
                                                            >
                                                                <div
                                                                    class="row"
                                                                >
                                                                    <div
                                                                        class="col"
                                                                    >
                                                                        <ul
                                                                            class="megamenu-submenu"
                                                                        >
                                                                            <li
                                                                                class=""
                                                                            >
                                                                                <a
                                                                                    href="assets/documents/2f.pdf"
                                                                                    target="_blank"
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Section
                                                                                    2(F)</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class=""
                                                                            >
                                                                                <a
                                                                                    href="assets/documents/ImportantDocuments/Territorial Jurisdiction.pdf"
                                                                                    target="_blank"
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Teritorial
                                                                                    Juricdistion</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class=""
                                                                            >
                                                                                <a
                                                                                    routerLink="/recognition"
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/recognition'
                                                                                        )
                                                                                    "
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >ODL
                                                                                    Model</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class=""
                                                                            >
                                                                                <a
                                                                                    href="assets/documents/UGC_Univ_%20Approval.pdf"
                                                                                    target="_blank"
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Regular
                                                                                    Mode</a
                                                                                >
                                                                            </li>

                                                                            <li
                                                                                class=""
                                                                            >
                                                                                <a
                                                                                    href="assets/documents/2022-23_No%20Objection%20Certificate%201-11278338841%20(1)%20(1).pdf"
                                                                                    target="_blank"
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >AICTE</a
                                                                                >
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="nav-link"
                                                        >Act And Statutes
                                                        <i
                                                            class="bx bx-chevron-right"
                                                        ></i
                                                    ></a>
                                                    <ul class="dropdown-menu">
                                                        <li class="nav-item">
                                                            <a
                                                                href="assets/documents/ImportantDocuments/HEI-U-0543_CopyRelevent_20230330165505.pdf"
                                                                class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact: true
                                                                }"
                                                                >University
                                                                Act</a
                                                            >
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="assets/documents/ImportantDocuments/Statutory bodies approval upload_OL.pdf"
                                                                class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact: true
                                                                }"
                                                                >Statutury Body
                                                                Approval</a
                                                            >
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="assets/documents/OL Documents/OL-Act.pdf"
                                                                class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact: true
                                                                }"
                                                                >ACT to Start
                                                                OL</a
                                                            >
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="">
                                                    <a
                                                        href="https://www.ugc.ac.in/pdfnews/FINAL%20RECOGNITION%20STATUS%2008-05-2019.pdf"
                                                        target="_blank"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Details On DEB
                                                        Website</a
                                                    >
                                                </li>
                                                <li class="">
                                                    <a
                                                        routerLink="/compliance"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        (click)="
                                                            goToRefresh(
                                                                '/compliance'
                                                            )
                                                        "
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >DEB Compliance</a
                                                    >
                                                </li>
                                                <li class="">
                                                    <a
                                                        routerLink="/vision-mission"
                                                        class="nav-link"
                                                        (click)="
                                                            goToRefresh(
                                                                '/vision-mission'
                                                            )
                                                        "
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Vision & Mission
                                                    </a>
                                                </li>
                                                <li class="">
                                                    <a
                                                        routerLink="/world-of-subharti"
                                                        class="nav-link"
                                                        (click)="
                                                            goToRefresh(
                                                                '/world-of-subharti'
                                                            )
                                                        "
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >World Of Subharti
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="nav-link"
                                                        >PPR & SLM Details
                                                        <i
                                                            class="bx bx-chevron-right"
                                                        ></i
                                                    ></a>
                                                    <ul class="dropdown-menu">
                                                        <li class="nav-item">
                                                            <a
                                                                href="javascript:void(0)"
                                                                class="nav-link"
                                                                >PPR for OL
                                                                Programmes
                                                                <i
                                                                    class="bx bx-chevron-right"
                                                                ></i
                                                            ></a>
                                                            <ul
                                                                class="dropdown-menu"
                                                            >
                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <a
                                                                        href="assets/documents/OL Documents/BA-JMC.pdf"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        target="_blank"
                                                                        [routerLinkActiveOptions]="{
                                                                            exact: true
                                                                        }"
                                                                        >BA
                                                                        (JMC)</a
                                                                    >
                                                                </li>
                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <a
                                                                        href="assets/documents/OL Documents/MA (JMC).pdf"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        target="_blank"
                                                                        [routerLinkActiveOptions]="{
                                                                            exact: true
                                                                        }"
                                                                        >MA
                                                                        (JMC)</a
                                                                    >
                                                                </li>
                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <a
                                                                        href="assets/documents/MBA.pdf"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        target="_blank"
                                                                        [routerLinkActiveOptions]="{
                                                                            exact: true
                                                                        }"
                                                                        >MBA</a
                                                                    >
                                                                </li>

                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <a
                                                                        href="assets/documents/OL Documents/MA-Hindi.pdf"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        target="_blank"
                                                                        [routerLinkActiveOptions]="{
                                                                            exact: true
                                                                        }"
                                                                        >MA
                                                                        (Hindi)</a
                                                                    >
                                                                </li>

                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <a
                                                                        href="assets/documents/OL Documents/MA - English.pdf"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        target="_blank"
                                                                        [routerLinkActiveOptions]="{
                                                                            exact: true
                                                                        }"
                                                                        >MA
                                                                        (English)</a
                                                                    >
                                                                </li>

                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <a
                                                                        href="assets/documents/OL Documents/MA (Buddhist).pdf"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        target="_blank"
                                                                        [routerLinkActiveOptions]="{
                                                                            exact: true
                                                                        }"
                                                                        >MA
                                                                        (Buddhist)</a
                                                                    >
                                                                </li>
                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <a
                                                                        href="assets/documents/OL Documents/MA (Political Science).pdf"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        target="_blank"
                                                                        [routerLinkActiveOptions]="{
                                                                            exact: true
                                                                        }"
                                                                        >MA
                                                                        (Political
                                                                        Science)</a
                                                                    >
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="assets/documents/ImportantDocuments/Complience -9 -Plagrisim Report of MBA elm.pdf"
                                                                class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact: true
                                                                }"
                                                                >Sample
                                                                Plagiarism
                                                                Report</a
                                                            >
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="assets/documents/SLM.pdf"
                                                                class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact: true
                                                                }"
                                                                >SLM Details</a
                                                            >
                                                        </li>

                                                        <li class="nav-item">
                                                            <a
                                                                href="assets/documents/ImportantDocuments/SLM_approval.pdf"
                                                                class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact: true
                                                                }"
                                                                >SLM Approval</a
                                                            >
                                                        </li>

                                                        <li class="nav-item">
                                                            <a
                                                                href="assets/documents/ImportantDocuments/Proof_Of_Out Source.pdf"
                                                                class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact: true
                                                                }"
                                                                >SLM
                                                                Outsource</a
                                                            >
                                                        </li>

                                                        <li class="nav-item">
                                                            <a
                                                                href="assets/documents/ImportantDocuments/lettertoexternal.pdf"
                                                                class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact: true
                                                                }"
                                                                >Sample
                                                                Outsourced
                                                                Letter</a
                                                            >
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="assets/documents/ImportantDocuments/PPR_Approval Letter.pdf"
                                                                class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact: true
                                                                }"
                                                                >PPR Approval</a
                                                            >
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="assets/documents/ppr/mba.pdf"
                                                                class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact: true
                                                                }"
                                                                >MBA
                                                                PPR(Sample)</a
                                                            >
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="assets/documents/ppr/ELM-Readiness.pdf"
                                                                class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact: true
                                                                }"
                                                                >ELM-Readiness</a
                                                            >
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="assets/documents/ppr/ELM Approval Letter.pdf"
                                                                class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact: true
                                                                }"
                                                                >ELM Approval
                                                                Letter</a
                                                            >
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="assets/documents/ppr/E-LM Outsourced.pdf"
                                                                class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact: true
                                                                }"
                                                                >E-LM
                                                                Outsourced</a
                                                            >
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="">
                                                    <a
                                                        href="assets/documents/ImportantDocuments/Administrative_staff.pdf"
                                                        target="_blank"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Administrative Staff
                                                    </a>
                                                </li>
                                                <li class="">
                                                    <a
                                                        href="assets/documents/Infrastructure.pdf"
                                                        target="_blank"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Infrastructure
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item megamenu">
                        <a href="javascript:void(0)" class="nav-link"
                            >Programmes <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <!-- <li class="">
                                                    <a
                                                        (click)="
                                                            goToRefresh(
                                                                '/all-courses'
                                                            )
                                                        "
                                                        routerLink="all-courses"
                                                        >All Courses</a
                                                    >
                                                </li> -->
                                                <li class="nav-item megamenu">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="nav-link"
                                                        >UG
                                                        <i
                                                            class="bx bx-chevron-right"
                                                        ></i
                                                    ></a>
                                                    <ul class="dropdown-menu">
                                                        <li class="nav-item">
                                                            <div
                                                                class="container"
                                                            >
                                                                <div
                                                                    class="row"
                                                                >
                                                                    <div
                                                                        class="col"
                                                                    >
                                                                        <ul
                                                                            class="megamenu-submenu"
                                                                        >
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    routerLink="/programme/bachelor-of-arts"
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/bachelor-of-arts'
                                                                                        )
                                                                                    "
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Bachelor
                                                                                    Of
                                                                                    Arts</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    routerLink="/programme/bachelor-of-arts-jmc"
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/bachelor-of-arts-jmc'
                                                                                        )
                                                                                    "
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Bachelor
                                                                                    Of
                                                                                    Arts(JMC)</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    routerLink="/programme/bachelor-of-library-and-information-sciences"
                                                                                    class="nav-link"
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/bachelor-of-library-and-information-sciences'
                                                                                        )
                                                                                    "
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Bachelor
                                                                                    Of
                                                                                    Library
                                                                                    And
                                                                                    Information
                                                                                    Sciences</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/bechelor-of-business-administration'
                                                                                        )
                                                                                    "
                                                                                    routerLink="/programme/bechelor-of-business-administration"
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Bachelor
                                                                                    Of
                                                                                    Business
                                                                                    Administration</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/bachelor-of-commerce'
                                                                                        )
                                                                                    "
                                                                                    routerLink="/programme/bachelor-of-commerce"
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Bachelor
                                                                                    Of
                                                                                    Commerce</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/bachelor-of-commerce-honors'
                                                                                        )
                                                                                    "
                                                                                    routerLink="/programme/bachelor-of-commerce-honors"
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Bachelor
                                                                                    Of
                                                                                    Commerce(Honors)</a
                                                                                >
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="nav-item megamenu">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="nav-link"
                                                        >PG
                                                        <i
                                                            class="bx bx-chevron-right"
                                                        ></i
                                                    ></a>
                                                    <ul class="dropdown-menu">
                                                        <li class="nav-item">
                                                            <div
                                                                class="container"
                                                            >
                                                                <div
                                                                    class="row"
                                                                >
                                                                    <div
                                                                        class="col"
                                                                    >
                                                                        <ul
                                                                            class="megamenu-submenu"
                                                                        >
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    routerLink="/programme/master-of-business-administration"
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/master-of-business-administration'
                                                                                        )
                                                                                    "
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Master
                                                                                    Of
                                                                                    Business
                                                                                    Administration</a
                                                                                >
                                                                                <!-- <a
                                                                                    href="#"
                                                                                    >Master
                                                                                    Of
                                                                                    Business
                                                                                    Administration</a
                                                                                > -->
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/ma'
                                                                                        )
                                                                                    "
                                                                                    routerLink="/programme/ma"
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Master
                                                                                    Of
                                                                                    Arts</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/master-of-library-and-information-sciences'
                                                                                        )
                                                                                    "
                                                                                    routerLink="/programme/master-of-library-and-information-sciences"
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Master
                                                                                    Of
                                                                                    Library
                                                                                    And
                                                                                    Information
                                                                                    Sciences</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/master-of-commece'
                                                                                        )
                                                                                    "
                                                                                    routerLink="/programme/master-of-commece"
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Master
                                                                                    Of
                                                                                    Commerce</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    routerLink="/programme/ma-home-science"
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/ma-home-science'
                                                                                        )
                                                                                    "
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Ma
                                                                                    Home
                                                                                    Science</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    routerLink="/programme/ma-journalism-and-mass-communication"
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/ma-journalism-and-mass-communication'
                                                                                        )
                                                                                    "
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Ma
                                                                                    Journalism
                                                                                    And
                                                                                    Mass
                                                                                    Communication</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    routerLink="/programme/ma-public-administration"
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/ma-public-administration'
                                                                                        )
                                                                                    "
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Ma
                                                                                    Public
                                                                                    Administration</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    routerLink="/programme/ma-buddhist-studies"
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/ma-buddhist-studies'
                                                                                        )
                                                                                    "
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Ma
                                                                                    Buddhist
                                                                                    Studies</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    routerLink="/programme/ma-education"
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/programme/ma-education'
                                                                                        )
                                                                                    "
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >Ma
                                                                                    Education</a
                                                                                >
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item megamenu">
                        <a href="javascript:void(0)" class="nav-link"
                            >Academic <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/admission-procedure"
                                                        class="nav-link"
                                                        (click)="
                                                            goToRefresh(
                                                                '/admission-procedure'
                                                            )
                                                        "
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Admission Procedure</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/credit-transfer-policy"
                                                        class="nav-link"
                                                        (click)="
                                                            goToRefresh(
                                                                '/credit-transfer-policy'
                                                            )
                                                        "
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Credit Transfer
                                                        Policy</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/latest-entry-policy"
                                                        class="nav-link"
                                                        (click)="
                                                            goToRefresh(
                                                                '/latest-entry-policy'
                                                            )
                                                        "
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Latest Entry Policy</a
                                                    >
                                                </li>
                                                <li class="nav-item megamenu">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="nav-link"
                                                        >Fee Structure
                                                        <i
                                                            class="bx bx-chevron-right"
                                                        ></i
                                                    ></a>
                                                    <ul class="dropdown-menu">
                                                        <li class="nav-item">
                                                            <div
                                                                class="container"
                                                            >
                                                                <div
                                                                    class="row"
                                                                >
                                                                    <div
                                                                        class="col"
                                                                    >
                                                                        <ul
                                                                            class="megamenu-submenu"
                                                                        >
                                                                            <li>
                                                                                <a
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/for-session-2021-22'
                                                                                        )
                                                                                    "
                                                                                    routerLink="/for-session-2021-22"
                                                                                    >For-Session-2021-22</a
                                                                                >
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/for-session-2022-23'
                                                                                        )
                                                                                    "
                                                                                    routerLink="/for-session-2022-23"
                                                                                    >For-Session-2022-23</a
                                                                                >
                                                                            </li>
                                                                            <li
                                                                                class="nav-item"
                                                                            >
                                                                                <a
                                                                                    routerLink="/for-session-2023-24"
                                                                                    (click)="
                                                                                        goToRefresh(
                                                                                            '/for-session-2023-24'
                                                                                        )
                                                                                    "
                                                                                    class="nav-link"
                                                                                    routerLinkActive="active"
                                                                                    [routerLinkActiveOptions]="{
                                                                                        exact: true
                                                                                    }"
                                                                                    >For
                                                                                    Session
                                                                                    C
                                                                                    2023</a
                                                                                >
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <!-- <li class="nav-item">
                                                    <a
                                                        href="assets/documents/FeeRefundPolicy.pdf"
                                                        target="_blank"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Refund Policy</a
                                                    >
                                                </li> -->
                                                <li class="nav-item">
                                                    <a
                                                        (click)="
                                                            goToRefresh(
                                                                '/semester-syllabus'
                                                            )
                                                        "
                                                        routerLink="/semester-syllabus"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Semester Syllabus
                                                        2021</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        (click)="
                                                            goToRefresh(
                                                                '/detailed-syllabus'
                                                            )
                                                        "
                                                        routerLink="/detailed-syllabus"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Detailed Syllabus</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        (click)="
                                                            goToRefresh(
                                                                '/assignments'
                                                            )
                                                        "
                                                        routerLink="/"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Assignments
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        (click)="
                                                            goToRefresh(
                                                                '/guidelines'
                                                            )
                                                        "
                                                        routerLink="/guidelines"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Guidelines
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        href="assets/documents/ImportantDocuments/black-list-details.pdf"
                                                        >Black Listed
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        (click)="
                                                            goToRefresh(
                                                                '/academic-calender'
                                                            )
                                                        "
                                                        routerLink="/academic-calender"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Academic Calender
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        (click)="
                                                            goToRefresh(
                                                                '/admission-details'
                                                            )
                                                        "
                                                        routerLink="/admission-details"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Academic Details
                                                    </a>
                                                </li>
                                                <!-- <li class="nav-item">
                                                    <a
                                                        (click)="
                                                            goToRefresh(
                                                                '/course-slm'
                                                            )
                                                        "
                                                        routerLink="/course-slm"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Course SLM's
                                                    </a> 
                                                </li>-->
                                                <li class="nav-item">
                                                    <a
                                                        href="assets/documents/FACULTY_DETAILS.pdf"
                                                        target="_blank"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Faculty Details
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item megamenu">
                        <a href="javascript:void(0)" class="nav-link"
                            >Examinations <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li class="nav-item">
                                                    <a
                                                        (click)="
                                                            goToRefresh(
                                                                '/examination-schedule'
                                                            )
                                                        "
                                                        routerLink="/examination-schedule"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Examination Schedule</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/evalution-procedure"
                                                        (click)="
                                                            goToRefresh(
                                                                '/evalution-procedure'
                                                            )
                                                        "
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Evalution Procedure</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        href="assets/documents/May2024&July2024_ExamCenters.pdf"
                                                        target="_blank"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Examination Centers</a
                                                    >
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item megamenu">
                        <a href="javascript:void(0)" class="nav-link"
                            >Student Support <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li class="nav-item">
                                                    <a
                                                        (click)="
                                                            goToRefresh(
                                                                '/downloads'
                                                            )
                                                        "
                                                        routerLink="/downloads"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Downloads</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        href="assets/documents/GUIDELINES FOR STUDENTS TO APPLY EDUCATION CERTIFICATE (For Uploading).pdf"
                                                        >Guidelines for students
                                                        to applying the
                                                        application form
                                                        <img
                                                            src="../../assets/img/0_new2p.gif"
                                                    /></a>
                                                </li>
                                                <!-- <li class="nav-item"><a
                                                    (click)="goToRefresh('/course-co-ordinators')"
                                                    routerLink="/course-co-ordinators"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">Course
                                                    Co-Ordinators</a></li> -->
                                                <li class="nav-item">
                                                    <a
                                                        (click)="
                                                            goToRefresh(
                                                                '/grievance'
                                                            )
                                                        "
                                                        routerLink="/contact"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Enquiry/Grievance
                                                        Form</a
                                                    >
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item megamenu">
                        <a href="javascript:void(0)" class="nav-link"
                            >Results <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li class="nav-item">
                                                    <a
                                                        href="http://182.74.167.41/studentlogin.aspx"
                                                        target="_blank"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Student Login</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        href="https://ums.subhartidde.com/Account/StudentLogin.aspx"
                                                        target="_blank"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Student Login(C 22/A
                                                        22-23)</a
                                                    >
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item megamenu">
                        <!-- <a
                                                            routerLink="/student-login"
                                                            class="nav-link"
                                                            (click)="
                                                                goToRefresh('/student-login')
                                                            "
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Student Login</a
                                                        >
                             -->
                        <a href="javascript:void(0)" class="nav-link"
                            >Student Login <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <ul class="megamenu-submenu">
                                                <li class="nav-item">
                                                    <a
                                                        href="http://182.74.167.41/studentlogin.aspx"
                                                        target="_blank"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Student Login (Old
                                                        Students)</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        href="https://ums.subhartidde.com/Account/StudentLogin.aspx"
                                                        target="_blank"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Student Login(A/C
                                                        Batch)</a
                                                    >
                                                </li>

                                                <!-- <li class="nav-item">
                                                    <a
                                                        href="https://ddesubharti.com/Account/StudentLogin.aspx"
                                                        target="_blank"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >STUDENT LOGIN</a
                                                    >
                                                </li> -->
                                                <li class="nav-item">
                                                    <a
                                                        href="https://student.subhartide.com/auth/login"
                                                        target="_blank"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >STUDENT LOGIN</a
                                                    >
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a href="https://lms.subhartidde.com/" class="nav-link"
                            >LMS
                        </a>
                    </li>
                </ul>
            </div>
            <!-- <div
                class="others-option d-flex align-items-center d-none d-lg-block"
            >
                <div class="option-item">
                    <a
                        href="https://subharti.org/distance/admission/"
                        target="_blank"
                        class="default-btn px-4"
                        style="
                            background-color: #29166b;
                            padding: 3px;
                            font-weight: 300;
                        "
                        >Apply Now<span></span
                    ></a>
                </div>
            </div> -->
        </nav>
    </div>
</div>
