import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assignment7',
  templateUrl: './assignment7.component.html',
  styleUrls: ['./assignment7.component.scss']
})
export class Assignment7Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
