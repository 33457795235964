import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ba',
    templateUrl: './ba.component.html',
    styleUrls: ['./ba.component.scss'],
})
export class BaComponent implements OnInit {
    constructor() {}
    ngOnInit(): void {}
}
