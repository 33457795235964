<div class="banner-wrapper">
    <div class="kitchen-feedback-slides">
        <owl-carousel-o [options]="kitchenFeedbackSlides">
            <ng-template carouselSlide>
                <div class="single-kitchen-feedback-box">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/banner/main-banner_1.jpeg" alt="user">
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-kitchen-feedback-box">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/banner/main-banner_2.jpeg" alt="user">
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>




