<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Master of Arts in Education</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!--<div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Introduction</h3>
                        </div>
                        <p>
                            The Master of Arts in Education is a two-year or
                            four-semester post-graduate course in education.
                            Students will leave with an understanding of the
                            fundamentals of education theory and a working
                            familiarity with the most up-to-date research
                            techniques in the field of education.
                        </p>
                        <h4>Objective</h4>
                        <p>
                            The primary focus of a MA Education program is on
                            preparing students for careers in education through
                            a deep immersion in pedagogy, teaching strategies,
                            and educational technology. While pursuing their
                            education, students will have the unique chance to
                            teach in a classroom setting, guided by the
                            mentorship of a professional educator. That way, by
                            the time they graduate, they'll have a firm grasp of
                            what it takes to enter the teaching profession.
                        </p>
                        <p>
                            To improve the quality of education, schools require
                            highly trained, expert teachers, and a graduate
                            degree indicates that the applicants holding this
                            degree are valued, competent educators who will have
                            a beneficial impact on youth.
                        </p>
                        <p>
                            Obtaining a MA Education degree is also a valuable
                            networking tool. When you're in graduate school,
                            you'll meet a lot of people who are already at the
                            top of their field; they'll have lots of suggestions
                            to provide and may even be able to help you get some
                            great jobs.
                        </p>
                        <p>
                            The MA Education program was created in response to
                            a growing need for qualified professionals in the
                            areas of K-12 and higher education instruction, as
                            well as educational leadership and management.
                            Graduates who are enthusiastic about continuing
                            their studies in the field of education and have
                            already earned their MA degree can pursue an M.Phil.
                            or Ph.D.
                        </p>
                        <h5>Programme Duration</h5>
                        <p>
                            MA in Education is a post-graduate degree program
                            and this programs are typically two years course.
                        </p>
                        <p>
                            Minimum Duration 2 Years<br />Maximum Duration 4
                            Year
                        </p>
                        <h6>Eligibility & Fee</h6>
                        <p>
                            Online/distance MA in Education eligibility criteria
                            are too very flexible. To pursue an online and
                            distance MA in Education one has to be completed
                            graduation from any recognised university. There are
                            no entrance examinations for admission in this
                            course.
                        </p>
                        <table class="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Programme fee</th>
                                    <th>Examination fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Rs. 14400 Per Year</td>
                                    <td>Rs. 2000 Per Year</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6 style="font-weight: bold">Payment Mode</h6>
                        <p>
                            Student can submit his fee in cash to cash counter
                            in department or submit online
                            <b
                                >For online payment, Account Details are as
                                follow :</b
                            >
                        </p>
                        <table
                            style="width: 100%"
                            cellpadding="0"
                            cellspacing="0"
                            border="1"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Account Holder Name:</b> Swami
                                        Vivekanand Subharti University Distance
                                        Education
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height: 24px">
                                        <b>Account Number:</b> 628501043170
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>IFSC Code:</b> ICIC0006285</td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>Branch:</b> 65 Shiv Puri Hapur Road
                                        Meerut
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h6><strong>Career Prospects</strong></h6>
                        <p>
                            After post-graduation student has various options
                            for employment, both in the government and private
                            sectors.
                        </p>
                        <p>There are many designations for Graduates are:</p>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Secondary School Teacher
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Program Manager
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Teacher Trainer
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Teacher/ Lecturer/ Education Administrator in
                                Research Officer
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Coaching Centres
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Education Consultancies
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Home Tuitions
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Private Tuition
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Publishing House
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Research and Development Agencies
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Teacher Training Institute
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >School
                            </li>
                        </ul>
                        <h6>Best study options after MA in Education</h6>
                        <p>
                            Graduates who are enthusiastic about continuing
                            their studies in the field of education and have
                            already earned their MA degree can pursue-
                        </p>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >M.Phil.
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Ph.D.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</div>
