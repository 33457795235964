import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bcom',
  templateUrl: './bcom.component.html',
  styleUrls: ['./bcom.component.scss']
})
export class BcomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
