<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>ASSIGNMENT 4th SEMESTER AUGUST 22</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-text-container">
                    <h4 style="background: #29176d;color: white;padding: 21px; margin-bottom:15px">ASSIGNMENT 4th
                        SEMESTER AUGUST 2022</h4>
                    <div class="rightcategory_box">
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/ADBA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for ADBA</a>
                                </h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/ADCA.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for ADCA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/B.COM.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.Com</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/B.SC.(PCM).pdf"
                                       style="font-size:18px; font-weight:400">Assignment for B.SC(PCM)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/B.SC(ZBC).pdf"
                                       style="font-size:18px; font-weight:400">Assignment for B.SC(ZBC)</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/B.A.(HINDI).pdf"
                                       style="font-size:18px; font-weight:400"> Assignment for B.A.(Hindi)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/B.A.(HISTORY).pdf"
                                       style="font-size:18px; font-weight:400">Assignment for B.A.(History)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/B.A.(POLITICAL%20SCIENCE).pdf"
                                       style="font-size:18px; font-weight:400">Assignment for B.A.(Political Science)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/B.A.(SOCIOLOGY).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Sociology)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/B.A.(ENGLISH).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(English)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/B.A.(FINE%20ARTS).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Fine Art)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/B.A.(FASHION%20DESIGN).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Fashion Designing)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/B.A.(MATHEMATICS).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Math)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/B.A.(JMC).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(JMC)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/BA-ECONOMICS.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(ECONOMICS)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/BBA.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for BBA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/BCA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for BCA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/M.COM.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.Com</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/M.SC(CA).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.Sc (CA)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/M.SC(DFSM).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.Sc(DFSM)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/M.A.(HINDI).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A(Hindi)</a></h6>


                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/M.A.(ENGLISH).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for M.A(English)</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/M.A.(EDUCATION).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for MA-EDUCATION</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/M.A.(HISTORY).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A(History)</a></h6>

                            </div>
                        </div>


                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/M.A.(PUBLIC%20ADMINISTRATION).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A(Public Admin)</a>
                                </h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/M.A.(POLITICAL%20SCIENCE).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for M.A(Political Science)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/M.A.(SOCIOLOGY).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A(Sociology)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/M.A.(JMC).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A.(JMC)</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/MCA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for MCA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/4TH SEMESTER/MBA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for MBA</a></h6>

                            </div>
                        </div>
                        <br class="clear">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

