<footer class="footer-area">
    <!-- <div id="whatsapp" class="d-lg-none">
        <a href="https://subharti.org/distance/admission/" target="_blank">
            <ul class="buy-button list-inline mb-0">
                <li
                    class="list-inline-item ps-1 mb-0"
                    style="background-color: #fe4a55; color: white"
                >
                    <p style="color: white">Apply Now</p>
                </li>
            </ul>
        </a>
    </div> -->
    <div class="container" style="margin-top: -50px">
        <div class="row">
            <div
                class="col-lg-3 col-md-6 col-sm-6 text-center d-none d-lg-block"
                style="margin-left: -33px"
            >
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"
                        ><img
                            src="assets/img/white_logo_v01.png"
                            alt="logo"
                            style="height: 47px"
                    /></a>
                    <p>
                        The Distance Education Council of Government of India
                        has accorded recognition to the University for offering
                        programmes through distance education mode
                    </p>
                    <br />
                    <ul class="social-link">
                        <li>
                            <a
                                href="https://www.facebook.com/subhartiuni"
                                class="d-block"
                                target="_blank"
                                ><i class="bx bxl-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/subhartiuni.official/"
                                class="d-block"
                                target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://twitter.com/subhartiofficial"
                                class="d-block"
                                target="_blank"
                                ><i class="bx bxl-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.youtube.com/channel/UCg9Upto51X-DRkibtkRKryQ"
                                class="d-block"
                                target="_blank"
                                ><i class="bx bxl-youtube"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 text-center d-lg-none">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"
                        ><img
                            src="assets/img/banner/footer-banner.png"
                            alt="logo"
                            style="height: 47px"
                    /></a>
                    <p>
                        The Distance Education Council of <br />Government of
                        India has accorded <br />recognition to the University
                        for <br />offering programmes through <br />distance
                        education mode
                    </p>
                    <ul class="social-link">
                        <li>
                            <a
                                href="https://www.facebook.com/subhartiuni"
                                class="d-block"
                                target="_blank"
                                ><i class="bx bxl-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://twitter.com/subhartiofficia"
                                class="d-block"
                                target="_blank"
                                ><i class="bx bxl-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.youtube.com/channel/UCg9Upto51X-DRkibtkRKryQ"
                                class="d-block"
                                target="_blank"
                                ><i class="bx bxl-youtube"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/subhartiuni.official/"
                                class="d-block"
                                target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 text-center">
                <div class="single-footer-widget">
                    <h3>ABOUT US</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/dde">CDOE (Formerly DDE)</a></li>
                        <li><a routerLink="/university">University</a></li>
                        <li>
                            <a routerLink="/director-messege"
                                >Director's Messege</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://subhartidde.com/documents/Resolution-for-DDE.pdf"
                                target="_blank"
                                >Resolution for CDOE (Formerly DDE)</a
                            >
                        </li>
                        <li><a routerLink="/compliance">DEB Compliance</a></li>
                        <li>
                            <a routerLink="/vision-mission">Vision & Mission</a>
                        </li>
                        <li>
                            <a routerLink="/world-of-subharti"
                                >World of Subharti</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="col-lg-3 col-md-6 col-sm-6 text-center d-none d-lg-block"
                style="margin-left: -1px"
            >
                <div class="single-footer-widget">
                    <h3>ACADEMIC</h3>
                    <ul class="footer-links-list">
                        <li>
                            <a routerLink="/admission-procedure"
                                >Admission Procedure</a
                            >
                        </li>
                        <li>
                            <a routerLink="/credit-transfer-policy"
                                >Credit Transfer Policy</a
                            >
                        </li>
                        <li>
                            <a routerLink="/latest-entry-policy"
                                >Lateral Entry Policy</a
                            >
                        </li>
                        <li>
                            <a routerLink="/for-session-c23">Fee Structure</a>
                        </li>
                        <li>
                            <a
                                href="assets/documents/feerefundpolicy2023-24.pdf"
                                target="_blank"
                                >Refund Policy</a
                            >
                        </li>
                        <li>
                            <a routerLink="/detailed-syllabus"
                                >Detailed Syllabus</a
                            >
                        </li>
                        <li>
                            <a routerLink="/academic-calender"
                                >Academic Calendar</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 text-center d-lg-none">
                <div class="single-footer-widget">
                    <h3>ACADEMIC</h3>
                    <ul class="footer-links-list">
                        <li>
                            <a routerLink="/admission-procedure"
                                >Admission Procedure</a
                            >
                        </li>
                        <li>
                            <a routerLink="/credit-transfer-policy"
                                >Credit Transfer Policy</a
                            >
                        </li>
                        <li>
                            <a routerLink="/latest-entry-policy"
                                >Lateral Entry Policy</a
                            >
                        </li>
                        <li>
                            <a routerLink="/for-session-c23">Fee Structure</a>
                        </li>
                        <li>
                            <a
                                href="assets/documents/feerefundpolicy2023-24.pdf"
                                target="_blank"
                                >Refund Policy</a
                            >
                        </li>
                        <li>
                            <a routerLink="/detailed-syllabus"
                                >Detailed Syllabus</a
                            >
                        </li>
                        <li>
                            <a routerLink="/academic-calender"
                                >Academic Calendar</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="col-lg-3 col-md-6 col-sm-6 text-center d-none d-lg-block"
                style="margin-left: 33px"
            >
                <div class="single-footer-widget">
                    <h3>PORTALS</h3>
                    <ul class="footer-contact-info">
                        <!-- <li>
                            <a
                                href="https://subhartidde.com/Login.aspx"
                                target="_blank"
                                >Student Login</a
                            >
                        </li> -->
                        <!-- <li>
                            <a
                                href="https://ddesubharti.com/Account/StudentLogin.aspx"
                                target="_blank"
                                >Student Login</a
                            >
                        </li> -->

                        <li>
                            <a
                                href="https://student.subhartide.com/auth/login"
                                target="_blank"
                                >Student Login</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://admission.subhartide.com/auth/login"
                                target="_blank"
                                >Login</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://subharti.org/walfare-mechanism-subharti-university-india.php"
                                target="_blank"
                                >Grievance Redressal Committee</a
                            >
                        </li>
                        <li>
                            <a routerLink="/grievance"
                                >Grievance Redressal System</a
                            >
                        </li>
                        <li><a routerLink="/feedback">Feedback</a></li>
                        <!-- <li>
                            <a
                                href="http://74.167.41/StudentLogin.aspx"
                                target="_blank"
                                >Result</a
                            >
                        </li> -->
                        <li><a routerLink="/contact">Contact Us</a></li>
                        <li>
                            <a
                                href="assets/documents/ImportantDocuments/FAQ.pdf"
                                target="_blank"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >FAQ
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 text-center d-lg-none">
                <div class="single-footer-widget">
                    <h3>PORTALS</h3>
                    <ul class="footer-contact-info">
                        <!-- <li>
                            <a
                                href="https://subhartidde.com/Login.aspx"
                                target="_blank"
                                >Student Login</a
                            >
                        </li> -->
                        <!-- <li>
                            <a
                                href="https://ddesubharti.com/Account/StudentLogin.aspx"
                                target="_blank"
                                >Student Login</a
                            >
                        </li> -->
                        <li>
                            <a
                                href="https://student.subhartide.com/auth/login"
                                target="_blank"
                                >Student Login</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://admission.subhartide.com/auth/login"
                                target="_blank"
                                >Login</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://subharti.org/walfare-mechanism-subharti-university-india.php"
                                target="_blank"
                                >Grievance Redressal Committee</a
                            >
                        </li>
                        <li>
                            <a routerLink="/grievance"
                                >Grievance Redressal System</a
                            >
                        </li>
                        <li><a routerLink="/feedback">Feedback</a></li>
                        <!-- <li>
                            <a
                                href="http://74.167.41/StudentLogin.aspx"
                                target="_blank"
                                >Result</a
                            >
                        </li> -->
                        <li><a routerLink="/contact">Contact Us</a></li>

                        <li>
                            <a
                                href="assets/documents/ImportantDocuments/FAQ.pdf"
                                target="_blank"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >FAQ
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area" style="margin-top: -20px">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>
                        <i class="bx bx-copyright"></i> Powered by
                        <a style="color: #b76618">Subharti University</a>
                    </p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a>Privacy Policy</a></li>
                        <li><a>Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
