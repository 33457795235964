import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-master-of-business-administration',
  templateUrl: './master-of-business-administration.component.html',
  styleUrls: ['./master-of-business-administration.component.scss']
})
export class MasterOfBusinessAdministrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
