<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Master of Arts (MA)</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Introduction</h3>
                        </div>
                        <p>
                            The Master of Arts (MA) is a two-year graduate
                            program that focuses on the study of arts,
                            humanities, and select branches of social science.
                            The MA curriculum is designed to teach students how
                            to think critically, examine arguments, and express
                            their own opinions. Discussion is a big part of most
                            MA degree programs.
                        </p>
                        <p>
                            Those who earn an MA in fields like sociology,
                            literature, and psychology might find satisfying
                            careers both in India and abroad. There is a large
                            pool of private and public sector employment
                            prospects in India and overseas for graduates of
                            this program.
                        </p>
                        <h4>Objective</h4>
                        <p>
                            Those seeking a professional foothold in the arts
                            and social sciences can consider enrolling in the
                            Master of Arts program. Aspirants can start
                            professions in a variety of fields, including
                            English, Finance, Political Science, Psychology, and
                            Mass Communication, to mention a few, depending upon
                            the type of MA program they choose to enroll in.
                        </p>
                        <p>
                            The relative significance of the long-standing
                            appeal of the MA degree program cannot be
                            overstated. Participants in this course will get a
                            foundational understanding of topics related to
                            their existing domains of expertise; this will
                            prepare them to continue their education and pursue
                            careers in their chosen sectors. Students pursuing
                            this degree will be able to learn about the most
                            up-to-date methods and tools in their chosen fields.
                        </p>
                        <p>
                            The MA shows a more conventional approach to
                            applying theory. In this course, we ask students to
                            consider business theories from a variety of
                            disciplinary perspectives, including sociology,
                            anthropology, psychology, and ethics.
                        </p>
                        <h4>Elective Courses in MA:</h4>
                        <ol>
                            <li>Hindi</li>
                            <li>English</li>
                            <li>History</li>
                            <li>Sociology</li>
                            <li>Economics</li>
                            <li>Mathematics</li>
                            <li>Political Science</li>
                        </ol>
                        <h5>Programme Duration</h5>
                        <p>
                            Master of Arts is a post-graduate degree program and
                            this programs are typically two years course.
                        </p>
                        <p>
                            Minimum Duration 2 Years<br />Maximum Duration 4
                            Year
                        </p>
                        <h6>Eligibility & Fee</h6>
                        <p>
                            Online/distance Master of Arts eligibility criteria
                            are too very flexible. To pursue an online and
                            distance MA in Education one has to be completed
                            graduation from any recognised university. There are
                            no entrance examinations for admission in this
                            course.
                        </p>
                        <table class="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Programme fee</th>
                                    <th>Examination fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Rs. 12000 Per Year</td>
                                    <td>Rs. 2000 Per Year</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6 style="font-weight: bold">Payment Mode</h6>
                        <table
                            style="width: 100%"
                            cellpadding="0"
                            cellspacing="0"
                            border="1"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Account Holder Name:</b> Swami
                                        Vivekanand Subharti University Distance
                                        Education
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height: 24px">
                                        <b>Account Number:</b> 628501043170
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>IFSC Code:</b> ICIC0006285</td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>Branch:</b> 65 Shiv Puri Hapur Road
                                        Meerut
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h6>Career Prospects</h6>
                        <p>
                            After post-graduation student has various options
                            for employment, both in the government and private
                            sectors.
                        </p>
                        <h6 style="font-weight: bold">Government Sector-</h6>
                        <p>
                            There are many designations for Master of Arts
                            Students:
                        </p>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >UPSC (IAS,IPS,IES,IFS)
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Staff Selection of Commission
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >CGL, CHSL, Police, Junior Engineer and other
                                technical jobs.
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Railway
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >IBPS: Clerk, Probationary Officer, etc.
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >DSSSB: It offers jobs of Clerk, Technician,
                                Junior Engineer, etc.
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Hospitality Jobs
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Public Sector Units
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Indian Army and Indian Navy
                            </li>
                        </ul>
                        <h5 style="font-weight: bold">
                            Private Sector-
                            <hr />
                        </h5>
                        <div class="container p-4" style="text-align: justify">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h6 style="font-weight: bold">
                                        There are many designations for Master
                                        of Arts Students:
                                    </h6>
                                    <!--<p>There are many designations for Graduates are:</p>-->
                                    <ul>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Social Worker
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Content Writer
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Journalist
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Newspaper Columnist
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Photographer
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Administrative Officer
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Subject Matter Expert
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Sales Associate
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Historian
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Psychologist
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Translator
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Economist
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-12">
                                    <h6 style="font-weight: bold">
                                        Best study options after MA in Education
                                    </h6>
                                    <p>
                                        Graduates who are enthusiastic about
                                        continuing their studies in the field of
                                        education and have already earned their
                                        MA degree can pursue-
                                    </p>
                                    <ul>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >M.Phil.
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i>
                                            Ph.D.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</div>
