<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>ASSIGNMENT 3RD SEMESTER AUG 2022</h2>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-text-container">
                    <h4 style="background: #29176d;color: white;padding: 21px; margin-bottom:15px">ASSIGNMENT 3RD
                        SEMESTER AUGUST 2022</h4>
                    <div class="rightcategory_box">
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/ADBA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for ADBA</a>
                                </h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/ADCA.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for ADCA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/B.A.(ECONOMICS).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(ECONOMICS)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/B.A.(ENGLISH).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(ENGLISH)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/B.A.(FASHION DESIGN).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(FASHION DESIGN)</a>
                                </h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/B.A.(HINDI).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(HINDI)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/B.A.(HISTORY).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(HISTORY)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/B.A.(JMC).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(JMC)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/B.A.(MATHEMATICS).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(MATHEMATICS)</a>
                                </h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/B.A.(SOCIOLOGY).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(SOCIOLOGY)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/B.A.(POLITICAL SCIENCE).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(POLITICAL
                                        SCIENCE)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/B.COM.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.Com</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/B.SC.(PCM).pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for B.SC.(PCM)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/B.SC(ZBC).pdf"
                                       style="font-size:18px; font-weight:400"> Assignment for B.Sc(ZBC)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/BBA.pdf"
                                       style="font-size:18px; font-weight:400"> Assignments for BBA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/BCA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for BCA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/M.A.(EDUCATION).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A.(EDUCATION)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/M.A.(ENGLISH).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A.(ENGLISH)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/M.A.(HINDI).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A.(HINDI)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/M.A.(HISTORY).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A.(HISTORY)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/M.A.(JMC).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A.(JMC)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/M.A.(POLITICAL SCIENCE).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A.(POLITICAL
                                        SCIENCE)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/M.A.(SOCIOLOGY).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.A.(SOCIOLOGY)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/MA-PUBLIC ADMINISTRATION (1).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for MA-PUBLIC
                                        ADMINISTRATION</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/M.COM (5).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.COM</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/M.SC.(DFSM).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.SC.(DFSM)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/M.SC(CA).pdf"
                                       style="font-size:18px; font-weight:400">Assignments for M.Sc (CA)</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="padding:8px; margin-bottom:10px">
                                    <a class="hover-underline-animation"
                                       href="assets/documents/assignment-august-22/3RD-SEMESTER/MCA.pdf"
                                       style="font-size:18px; font-weight:400">Assignments for MCA</a></h6>

                            </div>
                        </div>
                        <br class="clear">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

