import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assignments-a-s2nd23',
  templateUrl: './assignments-a-s2nd23.component.html',
  styleUrls: ['./assignments-a-s2nd23.component.scss']
})
export class AssignmentsAS2nd23Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
