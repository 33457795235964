<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>ASSIGNMENTS 2nd SEMESTER JULY 2024</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-text-container">
                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        ASSIGNMENTS 2nd SEMESTER JULY 2024
                    </h4>
                    <div class="rightcategory_box">
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/BA-ECONOMICS.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.A.(ECONOMICS)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/BA-ENGLISH.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for B.A.(ENGLISH)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/BA-Fashion Design.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for B.A.(FASHION DESIGN)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/BA-FINE ARTS.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for B.A.(FINE ARTS)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/BA-HINDI.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for B.A.(HINDI)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/BA-HISTORY.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.A.(HISTORY)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/BA-JMC.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.A.(JMC)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/BA-MATH.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for
                                        B.A.(MATHEMATICS)</a
                                    >
                                </h6>
                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/BA-POLITICAL SCIENCE.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for B.A.(POLITICAL
                                        SCIENCE)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/BA-SOCIOLOGY.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.A.(SOCIOLOGY)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/B.COM(HONS).pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.COM(HONS)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/B.COM.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.COM</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/B.SC(PCM).pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.SC(PCM)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/B.SC(ZBC).pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.SC(ZBC)</a
                                    >
                                </h6>
                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/BBA.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for BBA</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/BCA.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for BCA</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/BLIS.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for BLIS</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/M.A-MATHEMATICS.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for M.A. (MATHEMATICS)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/MA-BUDDHIST.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for M.A.(BUDDHIST
                                        STUDIES)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/MA-ECONOMICS.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for M.A.(ECONOMICS)</a
                                    >
                                </h6>
                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/MA - EDUCATION.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for M.A.(EDUCATION)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/MA-ENGLISH.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for M.A.(ENGLISH)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/MA-HINDI.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for M.A.(HINDI)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/MA-HISTORY.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for M.A.(HISTORY)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/MA-HOME SCIENCE.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for M.A.(HOME SCIENCE)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/MA-JMC.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for M.A.(JMC)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/MA-POLITICAL SCIENCE.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for M.A(POLITICAL
                                        SCIENCE)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/MA-PUBLIC ADMINISTRATION.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for M.A(PUBLIC
                                        ADMINISTRATION)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/MA-SOCIOLOGY.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for M.A(SOCIOLOGY)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/M.COM.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for M.COM</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/M.SC(CA).pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for M.SC(CA)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/M.SC.(DFSM).pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for M.SC(DFSM)</a
                                    >
                                </h6>
                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/MBA.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for MBA</a
                                    >
                                </h6>
                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/MCA.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for MCA</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS JULY 2024/II/M.LIS.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for MLIS</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <br class="clear" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
