import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assignment-fm2nd23',
  templateUrl: './assignment-fm2nd23.component.html',
  styleUrls: ['./assignment-fm2nd23.component.scss']
})
export class AssignmentFm2nd23Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
