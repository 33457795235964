import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mba-banking',
  templateUrl: './mba-banking.component.html',
  styleUrls: ['./mba-banking.component.scss']
})
export class MbaBankingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
