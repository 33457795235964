<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Courses Right Sidebar</li>
            </ul>
            <h2>Courses Right Sidebar</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="subharti-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 result-count">
                        <p>We found <span class="count">12</span> courses available for you</p>
                    </div>
                    <div class="col-lg-6 col-md-6 ordering">
                        <div class="select-box">
                            <label>Sort By:</label>
                            <select>
                                <option>Default</option>
                                <option>Popularity</option>
                                <option>Latest</option>
                                <option>Price: low to high</option>
                                <option>Price: high to low</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-courses-box">
                            <div class="courses-image">
                                <a routerLink="/single-courses-1" class="d-block image">
                                    <img src="assets/img/courses/img1.jpg" alt="image">
                                </a>
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <div class="price shadow">$39</div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    <span>Alex Morgan</span>
                                </div>
                                <h3><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data
                                    Science Bootcamp</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore.</p>
                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                    <li><i class='flaticon-people'></i> 145 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-courses-box">
                            <div class="courses-image">
                                <a routerLink="/single-courses-1" class="d-block image">
                                    <img src="assets/img/courses/img2.jpg" alt="image">
                                </a>
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <div class="price shadow">$49</div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/single-courses-1">Java Programming Masterclass for Software
                                    Developers</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore.</p>
                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                    <li><i class='flaticon-people'></i> 100 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-courses-box">
                            <div class="courses-image">
                                <a routerLink="/single-courses-1" class="d-block image">
                                    <img src="assets/img/courses/img3.jpg" alt="image">
                                </a>
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <div class="price shadow">$59</div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                    <span>David Warner</span>
                                </div>
                                <h3><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural
                                    Networks</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore.</p>
                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                    <li><i class='flaticon-people'></i> 150 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-courses-box">
                            <div class="courses-image">
                                <a routerLink="/single-courses-1" class="d-block image">
                                    <img src="assets/img/courses/img4.jpg" alt="image">
                                </a>
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <div class="price shadow">$39</div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user6.jpg" class="rounded-circle" alt="image">
                                    <span>Alex Morgan</span>
                                </div>
                                <h3><a routerLink="/single-courses-1">Python for Finance: Investment Fundamentals & Data
                                    Analytics</a></h3>
                                <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore.</p>
                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                    <li><i class='flaticon-people'></i> 145 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-courses-box">
                            <div class="courses-image">
                                <a routerLink="/single-courses-1" class="d-block image">
                                    <img src="assets/img/courses/img5.jpg" alt="image">
                                </a>
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <div class="price shadow">$49</div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user5.jpg" class="rounded-circle" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/single-courses-1">Machine Learning A-Z™: Hands-On Python & R In Data
                                    Science</a></h3>
                                <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore.</p>
                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                    <li><i class='flaticon-people'></i> 100 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-courses-box">
                            <div class="courses-image">
                                <a routerLink="/single-courses-1" class="d-block image">
                                    <img src="assets/img/courses/img6.jpg" alt="image">
                                </a>
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <div class="price shadow">$99</div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user4.jpg" class="rounded-circle" alt="image">
                                    <span>James Anderson</span>
                                </div>
                                <h3><a routerLink="/single-courses-1">R Programming A-Z™: R For Data Science With Real
                                    Exercises!</a></h3>
                                <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore.</p>
                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 44 Lessons</li>
                                    <li><i class='flaticon-people'></i> 440 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="pagination-area text-center">
                            <a routerLink="/courses-7" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/courses-7" class="page-numbers">2</a>
                            <a routerLink="/courses-7" class="page-numbers">3</a>
                            <a routerLink="/courses-7" class="page-numbers">4</a>
                            <a routerLink="/courses-7" class="next page-numbers"><i
                                class='bx bx-chevrons-right'></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_search">
                        <h3 class="widget-title">Search</h3>
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_recent_courses">
                        <h3 class="widget-title">Recent Courses</h3>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <span>$49.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">The Data Science Course 2020:
                                    Complete Data Science Bootcamp</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <span>$59.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Java Programming Masterclass
                                    for Software Developers</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <span>$69.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On
                                    Artificial Neural Networks</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/courses-1">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/courses-1">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/courses-1">Digital <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/courses-1">SEO <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/courses-1">Braike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/courses-1">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/courses-1">Software <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/courses-1">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/courses-1">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/courses-1">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/courses-1">Tips <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/courses-1">Website <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>
