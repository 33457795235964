import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { StickyNavModule } from 'ng2-sticky-nav';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { AccordionModule } from 'ngx-accordion';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LightgalleryModule } from 'lightgallery/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { PremiumAccessComponent } from './components/common/premium-access/premium-access.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { DistanceLearningComponent } from './components/pages/distance-learning/distance-learning.component';
import { HealthCoachingComponent } from './components/pages/health-coaching/health-coaching.component';
import { KindergartenComponent } from './components/pages/kindergarten/kindergarten.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { ProfileAuthenticationComponent } from './components/pages/profile-authentication/profile-authentication.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/pages/terms-of-service/terms-of-service.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { CoursesStyleOneComponent } from './components/pages/courses-style-one/courses-style-one.component';
import { CoursesStyleTwoComponent } from './components/pages/courses-style-two/courses-style-two.component';
import { CoursesStyleThreeComponent } from './components/pages/courses-style-three/courses-style-three.component';
import { CoursesStyleFourComponent } from './components/pages/courses-style-four/courses-style-four.component';
import { CoursesStyleFiveComponent } from './components/pages/courses-style-five/courses-style-five.component';
import { CoursesStyleSixComponent } from './components/pages/courses-style-six/courses-style-six.component';
import { CoursesStyleSevenComponent } from './components/pages/courses-style-seven/courses-style-seven.component';
import { CoursesDetailsStyleOneComponent } from './components/pages/courses-details-style-one/courses-details-style-one.component';
import { CoursesDetailsStyleTwoComponent } from './components/pages/courses-details-style-two/courses-details-style-two.component';
import { MembershipLevelsComponent } from './components/pages/membership-levels/membership-levels.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { EventsComponent } from './components/pages/events/events.component';
import { EventsDetailsComponent } from './components/pages/events-details/events-details.component';

import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';

import { BlogStyleOneComponent } from './components/pages/blog-style-one/blog-style-one.component';
import { BlogStyleTwoComponent } from './components/pages/blog-style-two/blog-style-two.component';
import { BlogStyleThreeComponent } from './components/pages/blog-style-three/blog-style-three.component';
import { BlogStyleFourComponent } from './components/pages/blog-style-four/blog-style-four.component';
import { BlogStyleFiveComponent } from './components/pages/blog-style-five/blog-style-five.component';
import { BlogDetailsStyleOneComponent } from './components/pages/blog-details-style-one/blog-details-style-one.component';
import { BlogDetailsStyleTwoComponent } from './components/pages/blog-details-style-two/blog-details-style-two.component';
import { BlogDetailsStyleThreeComponent } from './components/pages/blog-details-style-three/blog-details-style-three.component';

import { FeaturesStyleOneComponent } from './components/common/features-style-one/features-style-one.component';
import { GetInstantCoursesComponent } from './components/common/get-instant-courses/get-instant-courses.component';
import { CoursesComponent } from './components/common/courses/courses.component';

import { SloganComponent } from './components/common/slogan/slogan.component';

import { InstructorComponent } from './components/common/instructor/instructor.component';
import { TestimonialsComponent } from './components/common/testimonials/testimonials.component';
import { PremiumAccessTwoComponent } from './components/common/premium-access-two/premium-access-two.component';
import { FunfactsStyleOneComponent } from './components/common/funfacts-style-one/funfacts-style-one.component';

import { FeaturesStyleTwoComponent } from './components/common/features-style-two/features-style-two.component';

import { InstructorStyleTwoComponent } from './components/common/instructor-style-two/instructor-style-two.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { DistanceBlogComponent } from './components/pages/distance-learning/distance-blog/distance-blog.component';
import { DistanceCoursesComponent } from './components/pages/distance-learning/distance-courses/distance-courses.component';
import { DistancePopularCoursesComponent } from './components/pages/distance-learning/distance-popular-courses/distance-popular-courses.component';
import { DistanceBannerComponent } from './components/pages/distance-learning/distance-banner/distance-banner.component';
import { DistanceBoxesComponent } from './components/pages/distance-learning/distance-boxes/distance-boxes.component';
import { DistanceAboutComponent } from './components/pages/distance-learning/distance-about/distance-about.component';
import { FunfactsStyleTwoComponent } from './components/common/funfacts-style-two/funfacts-style-two.component';

import { HealthCoachingBannerComponent } from './components/pages/health-coaching/health-coaching-banner/health-coaching-banner.component';
import { KindergartenBannerComponent } from './components/pages/kindergarten/kindergarten-banner/kindergarten-banner.component';
import { FeedbackWithBgComponent } from './components/common/feedback-with-bg/feedback-with-bg.component';
import { HowToApplyComponent } from './components/common/how-to-apply/how-to-apply.component';
import { FreeTrialComponent } from './components/common/free-trial/free-trial.component';
import { OverviewComponent } from './components/common/overview/overview.component';

import { FeedbackStyleTwoComponent } from './components/common/feedback-style-two/feedback-style-two.component';
import { DownloadSyllabusComponent } from './components/common/download-syllabus/download-syllabus.component';
import { FlexiblePricingComponent } from './components/common/flexible-pricing/flexible-pricing.component';

import { SubscribeStyleTwoComponent } from './components/common/subscribe-style-two/subscribe-style-two.component';
import { ExperienceComponent } from './components/common/experience/experience.component';

import { LifestyleComponent } from './components/common/lifestyle/lifestyle.component';
import { ProgramComponent } from './components/common/program/program.component';
import { ServicesComponent } from './components/common/services/services.component';
import { HealthCoursesComponent } from './components/pages/health-coaching/health-courses/health-courses.component';
import { HealthExperienceComponent } from './components/pages/health-coaching/health-experience/health-experience.component';
import { HealthFeedbackComponent } from './components/pages/health-coaching/health-feedback/health-feedback.component';
import { HealthEventsComponent } from './components/pages/health-coaching/health-events/health-events.component';
import { HealthBlogComponent } from './components/pages/health-coaching/health-blog/health-blog.component';
import { SubscribeStyleThreeComponent } from './components/common/subscribe-style-three/subscribe-style-three.component';
import { SubscribeStyleFourComponent } from './components/common/subscribe-style-four/subscribe-style-four.component';
import { KindergartenAboutComponent } from './components/pages/kindergarten/kindergarten-about/kindergarten-about.component';
import { KindergartenServicesComponent } from './components/pages/kindergarten/kindergarten-services/kindergarten-services.component';
import { KindergartenCoursesComponent } from './components/pages/kindergarten/kindergarten-courses/kindergarten-courses.component';
import { KindergartenFeedbackComponent } from './components/pages/kindergarten/kindergarten-feedback/kindergarten-feedback.component';
import { KindergartenEventsComponent } from './components/pages/kindergarten/kindergarten-events/kindergarten-events.component';
import { KindergartenBlogComponent } from './components/pages/kindergarten/kindergarten-blog/kindergarten-blog.component';
import { SelectedAgesComponent } from './components/common/selected-ages/selected-ages.component';
import { OnlinePlatformComponent } from './components/common/online-platform/online-platform.component';
import { FeaturedComponent } from './components/common/featured/featured.component';
import { FunfactsStyleThreeComponent } from './components/common/funfacts-style-three/funfacts-style-three.component';
import { FeedbackStyleThreeComponent } from './components/common/feedback-style-three/feedback-style-three.component';
import { ExpertTrainerComponent } from './components/common/expert-trainer/expert-trainer.component';
import { TeacherRegisterComponent } from './components/common/teacher-register/teacher-register.component';
import { FeaturesStyleFourComponent } from './components/common/features-style-four/features-style-four.component';
import { GetInstantCoursesTwoComponent } from './components/common/get-instant-courses-two/get-instant-courses-two.component';
import { PartnerSliderComponent } from './components/common/partner-slider/partner-slider.component';
import { FunfactsStyleFourComponent } from './components/common/funfacts-style-four/funfacts-style-four.component';
import { KitchenCoachingComponent } from './components/pages/kitchen-coaching/kitchen-coaching.component';
import { FeaturesStyleThreeComponent } from './components/common/features-style-three/features-style-three.component';
import { FunfactsStyleFiveComponent } from './components/common/funfacts-style-five/funfacts-style-five.component';
import { FeedbackStyleFourComponent } from './components/common/feedback-style-four/feedback-style-four.component';
import { CoachingProgramComponent } from './components/common/coaching-program/coaching-program.component';
import { FeaturesFunfactsComponent } from './components/common/features-funfacts/features-funfacts.component';
import { MotivationalFeedbackComponent } from './components/common/motivational-feedback/motivational-feedback.component';
import { SubscribeStyleFiveComponent } from './components/common/subscribe-style-five/subscribe-style-five.component';
import { KitchenCoachingBannerComponent } from './components/pages/kitchen-coaching/kitchen-coaching-banner/kitchen-coaching-banner.component';
import { FeaturesStyleFiveComponent } from './components/common/features-style-five/features-style-five.component';
import { KitchenCoachingAboutComponent } from './components/pages/kitchen-coaching/kitchen-coaching-about/kitchen-coaching-about.component';
import { DownloadBookComponent } from './components/common/download-book/download-book.component';
import { KitchenCoachingCoursesComponent } from './components/pages/kitchen-coaching/kitchen-coaching-courses/kitchen-coaching-courses.component';
import { KitchenFeedbackFunfactsComponent } from './components/common/kitchen-feedback-funfacts/kitchen-feedback-funfacts.component';
import { KitchenCoachingEventsComponent } from './components/pages/kitchen-coaching/kitchen-coaching-events/kitchen-coaching-events.component';
import { MyNewsletterComponent } from './components/common/my-newsletter/my-newsletter.component';
import { GetStartedComponent } from './components/common/get-started/get-started.component';
import { DdeComponent } from './components/pages/dde/dde.component';
import { UniversityComponent } from './components/pages/university/university.component';
import { DirectorMessegeComponent } from './components/pages/director-messege/director-messege.component';
import { ResutionForDdeComponent } from './components/pages/resution-for-dde/resution-for-dde.component';
import { RecognitionsComponent } from './components/pages/recognitions/recognitions.component';
import { ActAndStatutesComponent } from './components/pages/act-and-statutes/act-and-statutes.component';
import { DetailsOnDebWebsiteComponent } from './components/pages/details-on-deb-website/details-on-deb-website.component';
import { DebComplianceComponent } from './components/pages/deb-compliance/deb-compliance.component';
import { VisionMissionComponent } from './components/pages/vision-mission/vision-mission.component';
import { WorldOfSubhartiComponent } from './components/pages/world-of-subharti/world-of-subharti.component';
import { FacultyDetailsComponent } from './components/pages/faculty-details/faculty-details.component';
import { AdministrativeStaffComponent } from './components/pages/administrative-staff/administrative-staff.component';
import { InfrastructureComponent } from './components/pages/infrastructure/infrastructure.component';
import { MaEducationComponent } from './components/pages/ma-education/ma-education.component';
import { MscMathematicsComponent } from './components/pages/msc-mathematics/msc-mathematics.component';
import { MbaRetallManagementComponent } from './components/pages/mba-retall-management/mba-retall-management.component';
import { MbaBankingComponent } from './components/pages/mba-banking/mba-banking.component';
import { MbaRuralDevelopmentComponent } from './components/pages/mba-rural-development/mba-rural-development.component';
import { MaComponent } from './components/pages/ma/ma.component';
import { MaMathematicsComponent } from './components/pages/ma-mathematics/ma-mathematics.component';
import { MaEconomicsComponent } from './components/pages/ma-economics/ma-economics.component';
import { BaComponent } from './components/pages/ba/ba.component';
import { BfaComponent } from './components/pages/bfa/bfa.component';
import { MfaComponent } from './components/pages/mfa/mfa.component';
import { BpaComponent } from './components/pages/bpa/bpa.component';
import { MpaComponent } from './components/pages/mpa/mpa.component';
import { BcomComponent } from './components/pages/bcom/bcom.component';
import { DiplomaComponent } from './components/pages/diploma/diploma.component';
import { AdvancedDiplomaComponent } from './components/pages/advanced-diploma/advanced-diploma.component';
import { DegreeCoursesComponent } from './components/pages/degree-courses/degree-courses.component';
import { PgCoursesComponent } from './components/pages/pg-courses/pg-courses.component';
import { PgDiplomaCoursesComponent } from './components/pages/pg-diploma-courses/pg-diploma-courses.component';
import { AdmissionProcedureComponent } from './components/pages/admission-procedure/admission-procedure.component';
import { CreditTransferPolicyComponent } from './components/pages/credit-transfer-policy/credit-transfer-policy.component';
import { LatestEntryPolicyComponent } from './components/pages/latest-entry-policy/latest-entry-policy.component';
import { NewFeeStructureComponent } from './components/pages/new-fee-structure/new-fee-structure.component';
import { RefundPolicyComponent } from './components/pages/refund-policy/refund-policy.component';
import { SemesterSyllabusComponent } from './components/pages/semester-syllabus/semester-syllabus.component';
import { DetailedSyllabusComponent } from './components/pages/detailed-syllabus/detailed-syllabus.component';
import { AssignmentsComponent } from './components/pages/assignments/assignments.component';
import { LatestNewsComponent } from './components/pages/latest-news/latest-news.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DownloadComponent } from './components/pages/download/download.component';
import { CourseCoOrdinatorsComponent } from './components/pages/course-co-ordinators/course-co-ordinators.component';
import { EnqComponent } from './components/pages/enq/enq.component';
import { EnquiryComponent } from './components/pages/enquiry/enquiry.component';
import { GuidelinesComponent } from './components/pages/guidelines/guidelines.component';
import { HowToGetAdmissionOpenComponent } from './components/pages/how-to-get-admission-open/how-to-get-admission-open.component';
import { IsSubhartiGoodComponent } from './components/pages/is-subharti-good/is-subharti-good.component';
import { WhyChooseSubhartiComponent } from './components/pages/why-choose-subharti/why-choose-subharti.component';
import { ExaminationScheduleComponent } from './components/pages/examination-schedule/examination-schedule.component';
import { EvalutionProcedureComponent } from './components/pages/evalution-procedure/evalution-procedure.component';
import { AllCoursesComponent } from './components/pages/all-courses/all-courses.component';
import { NewCoursesComponent } from './components/pages/new-courses/new-courses.component';
import { NewSyllabusComponent } from './components/pages/new-syllabus/new-syllabus.component';
import { CourseSlmComponent } from './components/pages/course-slm/course-slm.component';
import { AdmissionDetailsComponent } from './components/pages/admission-details/admission-details.component';
import { AcademicCalenderComponent } from './components/pages/academic-calender/academic-calender.component';
import { SessionOneComponent } from './components/pages/session-one/session-one.component';
import { SessionTwoComponent } from './components/pages/session-two/session-two.component';
import { FacultyComponent } from './components/pages/faculty/faculty.component';
import { IsSubhartiUniversityDegreeValidForGovernmentJobComponent } from './components/pages/is-subharti-university-degree-valid-for-government-job/is-subharti-university-degree-valid-for-government-job.component';
import { GrievanceRedressalSystemComponent } from './components/pages/grievance-redressal-system/grievance-redressal-system.component';
import { RecognitionComponent } from './components/pages/recognition/recognition.component';
import { Assignment1Component } from './components/pages/assignment1/assignment1.component';
import { Assignment2Component } from './components/pages/assignment2/assignment2.component';
import { Assignment3Component } from './components/pages/assignment3/assignment3.component';
import { Assignment4Component } from './components/pages/assignment4/assignment4.component';
import { Assignment5Component } from './components/pages/assignment5/assignment5.component';
import { Assignment6Component } from './components/pages/assignment6/assignment6.component';
import { Assignment7Component } from './components/pages/assignment7/assignment7.component';
import { Assignment8Component } from './components/pages/assignment8/assignment8.component';
import { Assignment9Component } from './components/pages/assignment9/assignment9.component';
import { Assignment10Component } from './components/pages/assignment10/assignment10.component';
import { Assignment11Component } from './components/pages/assignment11/assignment11.component';
import { Assignment12Component } from './components/pages/assignment12/assignment12.component';
import { ProgrammeComponent } from './components/pages/programme/programme.component';
import { BachelorOfArtsComponent } from './components/pages/programme/bachelor-of-arts/bachelor-of-arts.component';
import { BachelorOfLibraryAndInformationSciencesComponent } from './components/pages/programme/bachelor-of-library-and-information-sciences/bachelor-of-library-and-information-sciences.component';
import { BachelorOfBusinessAdministrationComponent } from './components/pages/programme/bachelor-of-business-administration/bachelor-of-business-administration.component';
import { BachelorOfCommerceComponent } from './components/pages/programme/bachelor-of-commerce/bachelor-of-commerce.component';
import { BachelorOfCommerceHonorsComponent } from './components/pages/programme/bachelor-of-commerce-honors/bachelor-of-commerce-honors.component';
import { BachelorOfArtsJamcComponent } from './components/pages/programme/bachelor-of-arts-jamc/bachelor-of-arts-jamc.component';
import { MaBuddhistStudiesComponent } from './components/pages/programme/ma-buddhist-studies/ma-buddhist-studies.component';
import { MaPgComponent } from './components/pages/programme/ma-pg/ma-pg.component';
import { MaUgComponent } from './components/pages/programme/ma-ug/ma-ug.component';
import { MasterOfLibraryAndInformationSciencesComponent } from './components/pages/programme/master-of-library-and-information-sciences/master-of-library-and-information-sciences.component';
import { MasterOfBusinessAdministrationComponent } from './components/pages/programme/master-of-business-administration/master-of-business-administration.component';
import { MasterOfCommeceComponent } from './components/pages/programme/master-of-commece/master-of-commece.component';
import { MaHomeScienceComponent } from './components/pages/programme/ma-home-science/ma-home-science.component';
import { MaJournalismAndMassCommunicationComponent } from './components/pages/programme/ma-journalism-and-mass-communication/ma-journalism-and-mass-communication.component';
import { MaPublicAdministrationComponent } from './components/pages/programme/ma-public-administration/ma-public-administration.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { BusinessCoachingAboutComponent } from './components/pages/business-coaching/business-coaching-about/business-coaching-about.component';
import { BusinessCoachingBlogComponent } from './components/pages/business-coaching/business-coaching-blog/business-coaching-blog.component';
import { BusinessCoachingComponent } from './components/pages/business-coaching/business-coaching.component';
import { HttpClientModule } from '@angular/common/http';
import { AssignmentFm2nd23Component } from './components/pages/assignment-fm2nd23/assignment-fm2nd23.component';
import { AssignmentFm1st23Component } from './components/pages/assignment-fm1st23/assignment-fm1st23.component';
import { AssignmentFm3rd23Component } from './components/pages/assignment-fm3rd23/assignment-fm3rd23.component';
import { AssignmentFm4th23Component } from './components/pages/assignment-fm4th23/assignment-fm4th23.component';
import { AssignmentFm5th23Component } from './components/pages/assignment-fm5th23/assignment-fm5th23.component';
import { AssignmentFm6th23Component } from './components/pages/assignment-fm6th23/assignment-fm6th23.component';
import { HttpsGuard } from './https-guard';
import { AssignmentsAS1st23Component } from './components/pages/assignments-a-s1st23/assignments-a-s1st23.component';
import { AssignmentsAS2nd23Component } from './components/pages/assignments-a-s2nd23/assignments-a-s2nd23.component';
import { AssignmentsAS3rd23Component } from './components/pages/assignments-a-s3rd23/assignments-a-s3rd23.component';
import { AssignmentsAS4th23Component } from './components/pages/assignments-a-s4th23/assignments-a-s4th23.component';
import { AssignmentsAS5th23Component } from './components/pages/assignments-a-s5th23/assignments-a-s5th23.component';
import { AssignmentsAS6th23Component } from './components/pages/assignments-a-s6th23/assignments-a-s6th23.component';
import { ForSessionC23Component } from './components/pages/for-session-c23/for-session-c23.component';
import { NoticeComponent } from './components/common/notice/notice.component';
import { StudentLoginComponent } from './components/common/student-login/student-login.component';
// import { HttpsGuard } from './https-guard';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        PartnerComponent,
        PremiumAccessComponent,
        SubscribeComponent,
        DistanceLearningComponent,
        HealthCoachingComponent,
        KindergartenComponent,
        ContactUsComponent,
        GalleryComponent,
        ProfileAuthenticationComponent,
        ErrorComponent,
        ComingSoonComponent,
        PrivacyPolicyComponent,
        TermsOfServiceComponent,
        FaqComponent,
        CoursesStyleOneComponent,
        CoursesStyleTwoComponent,
        CoursesStyleThreeComponent,
        CoursesStyleFourComponent,
        CoursesStyleFiveComponent,
        CoursesStyleSixComponent,
        CoursesStyleSevenComponent,
        CoursesDetailsStyleOneComponent,
        CoursesDetailsStyleTwoComponent,
        MembershipLevelsComponent,
        CategoriesComponent,
        EventsComponent,
        EventsDetailsComponent,
        CartComponent,
        CheckoutComponent,
        BlogStyleOneComponent,
        BlogStyleTwoComponent,
        BlogStyleThreeComponent,
        BlogStyleFourComponent,
        BlogStyleFiveComponent,
        BlogDetailsStyleOneComponent,
        BlogDetailsStyleTwoComponent,
        BlogDetailsStyleThreeComponent,
        FeaturesStyleOneComponent,
        GetInstantCoursesComponent,
        CoursesComponent,
        BlogComponent,
        SloganComponent,
        InstructorComponent,
        TestimonialsComponent,
        PremiumAccessTwoComponent,
        FunfactsStyleOneComponent,
        FeaturesStyleTwoComponent,
        InstructorStyleTwoComponent,
        FeedbackComponent,
        DistanceBlogComponent,
        DistanceCoursesComponent,
        DistancePopularCoursesComponent,
        DistanceBannerComponent,
        DistanceBoxesComponent,
        DistanceAboutComponent,
        FunfactsStyleTwoComponent,
        HealthCoachingBannerComponent,
        KindergartenBannerComponent,
        FeedbackWithBgComponent,
        HowToApplyComponent,
        FreeTrialComponent,
        OverviewComponent,
        FeedbackStyleTwoComponent,
        DownloadSyllabusComponent,
        FlexiblePricingComponent,
        SubscribeStyleTwoComponent,
        ExperienceComponent,
        LifestyleComponent,
        ProgramComponent,
        ServicesComponent,
        HealthCoursesComponent,
        HealthExperienceComponent,
        HealthFeedbackComponent,
        HealthEventsComponent,
        HealthBlogComponent,
        SubscribeStyleThreeComponent,
        SubscribeStyleFourComponent,
        KindergartenAboutComponent,
        KindergartenServicesComponent,
        KindergartenCoursesComponent,
        KindergartenFeedbackComponent,
        KindergartenEventsComponent,
        KindergartenBlogComponent,
        SelectedAgesComponent,
        OnlinePlatformComponent,
        FeaturedComponent,
        FunfactsStyleThreeComponent,
        FeedbackStyleThreeComponent,
        ExpertTrainerComponent,
        TeacherRegisterComponent,
        FeaturesStyleFourComponent,
        GetInstantCoursesTwoComponent,
        PartnerSliderComponent,
        FunfactsStyleFourComponent,
        KitchenCoachingComponent,
        FeaturesStyleThreeComponent,
        FunfactsStyleFiveComponent,
        FeedbackStyleFourComponent,
        CoachingProgramComponent,
        FeaturesFunfactsComponent,
        MotivationalFeedbackComponent,
        SubscribeStyleFiveComponent,
        KitchenCoachingBannerComponent,
        FeaturesStyleFiveComponent,
        KitchenCoachingAboutComponent,
        DownloadBookComponent,
        KitchenCoachingCoursesComponent,
        KitchenFeedbackFunfactsComponent,
        KitchenCoachingEventsComponent,
        MyNewsletterComponent,
        GetStartedComponent,
        DdeComponent,
        UniversityComponent,
        DirectorMessegeComponent,
        ResutionForDdeComponent,
        RecognitionsComponent,
        ActAndStatutesComponent,
        DetailsOnDebWebsiteComponent,
        DebComplianceComponent,
        VisionMissionComponent,
        WorldOfSubhartiComponent,
        FacultyDetailsComponent,
        AdministrativeStaffComponent,
        InfrastructureComponent,
        MaEducationComponent,
        MscMathematicsComponent,
        MbaRetallManagementComponent,
        MbaBankingComponent,
        MbaRuralDevelopmentComponent,
        MaComponent,
        MaMathematicsComponent,
        MaEconomicsComponent,
        BaComponent,
        BfaComponent,
        MfaComponent,
        BpaComponent,
        MpaComponent,
        BcomComponent,
        DiplomaComponent,
        AdvancedDiplomaComponent,
        DegreeCoursesComponent,
        PgCoursesComponent,
        PgDiplomaCoursesComponent,
        AdmissionProcedureComponent,
        CreditTransferPolicyComponent,
        LatestEntryPolicyComponent,
        NewFeeStructureComponent,
        RefundPolicyComponent,
        SemesterSyllabusComponent,
        DetailedSyllabusComponent,
        AssignmentsComponent,
        LatestNewsComponent,
        DownloadComponent,
        CourseCoOrdinatorsComponent,
        EnqComponent,
        EnquiryComponent,
        GuidelinesComponent,
        HowToGetAdmissionOpenComponent,
        IsSubhartiGoodComponent,
        WhyChooseSubhartiComponent,
        ExaminationScheduleComponent,
        EvalutionProcedureComponent,
        AllCoursesComponent,
        NewCoursesComponent,
        NewSyllabusComponent,
        CourseSlmComponent,
        AdmissionDetailsComponent,
        AcademicCalenderComponent,
        SessionOneComponent,
        SessionTwoComponent,
        FacultyComponent,
        IsSubhartiUniversityDegreeValidForGovernmentJobComponent,
        GrievanceRedressalSystemComponent,
        RecognitionComponent,
        Assignment1Component,
        Assignment2Component,
        Assignment3Component,
        Assignment4Component,
        Assignment5Component,
        Assignment6Component,
        Assignment7Component,
        Assignment8Component,
        Assignment9Component,
        Assignment10Component,
        Assignment11Component,
        Assignment12Component,
        ProgrammeComponent,
        BachelorOfArtsComponent,
        BachelorOfLibraryAndInformationSciencesComponent,
        BachelorOfBusinessAdministrationComponent,
        BachelorOfCommerceComponent,
        BachelorOfCommerceHonorsComponent,
        BachelorOfArtsJamcComponent,
        MaBuddhistStudiesComponent,
        MaPgComponent,
        MaUgComponent,
        MasterOfLibraryAndInformationSciencesComponent,
        MasterOfBusinessAdministrationComponent,
        MasterOfCommeceComponent,
        MaHomeScienceComponent,
        MaJournalismAndMassCommunicationComponent,
        MaPublicAdministrationComponent,
        BusinessCoachingAboutComponent,
        BusinessCoachingBlogComponent,
        BusinessCoachingComponent,
        BpaComponent,
        AssignmentFm1st23Component,
        AssignmentFm2nd23Component,
        AssignmentFm3rd23Component,
        AssignmentFm4th23Component,
        AssignmentFm5th23Component,
        AssignmentFm6th23Component,
        AssignmentsAS1st23Component,
        AssignmentsAS2nd23Component,
        AssignmentsAS3rd23Component,
        AssignmentsAS4th23Component,
        AssignmentsAS5th23Component,
        AssignmentsAS6th23Component,
        ForSessionC23Component,
        NoticeComponent,
        StudentLoginComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CarouselModule,
        BrowserAnimationsModule,
        CountUpModule,
        StickyNavModule,
        TabsModule,
        NgxScrollTopModule,
        LightboxModule,
        AccordionModule,
        FormsModule,
        PdfViewerModule,
        LightgalleryModule,
        HttpClientModule,
        ReactiveFormsModule,
    ],
    providers: [HttpsGuard],
    bootstrap: [AppComponent],
})
export class AppModule {}
