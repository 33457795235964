<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                 <li><a routerLink="/">Home</a></li>
                 <li>Contact</li>
             </ul>-->
            <h2>Grievance</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="breadcrumb-banner-area">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="breadcrumb-text">
                                <h1 class="text-center" style="color:green">CONTACT US</h1>
                                <p class="text-center" style="color:green"><a class="text-center"
                                        href="/"><u>Home</u></a>
                                </p>
                                <h3 class="text-center"> For Enquiry/Grievance:</h3>
                                <p class="text-center mb-10">Contact: +91-7456901052 / 53</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">

            </div>
            <div class="col-lg-6 col-md-12 rounded p-5"
                style="border: 1px solid black; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); background-color: #ede6e7;">
                <div class="contact-form">
                    <h2 style="text-align: center;">Grievance Form</h2>
                    <!--<p>Your email address will not be published.  fields are marked *</p>-->
                    <!-- <form>
                         <div class="row">
                             <div class="col-lg-12 col-md-6">
                                 <div class="form-group">
                                     <input  minlength="3" maxlength="50" ngModel name="name" type="text"
                                            class="form-control" id="name" placeholder="Your name">
                                     <div class="alert alert-danger">
                                         <div>Name is .</div>
                                         <div>Name is
                                             minimum character.
                                         </div>
                                         <div>Name is maximum 50 character.</div>
                                     </div>
                                 </div>
                             </div>
                             <div class="col-lg-12 col-md-6">
                                 <div class="form-group">
                                     <input  ngModel name="email" type="text" #email="ngModel"
                                            class="form-control" id="email" placeholder="Your email address">
                                     <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address
                                         is .
                                     </div>
                                 </div>
                             </div>
                             <div class="col-lg-12 col-md-12">
                                 <div class="form-group">
                                     <input  ngModel name="number" type="text" #number="ngModel"
                                            class="form-control" id="number" placeholder="Your phone number">
                                     <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number
                                         is .
                                     </div>
                                 </div>
                             </div>
                             <div class="col-lg-12 col-md-12">
                                 <div class="form-group">
                                     <textarea  ngModel #message="ngModel" name="message" id="message" cols="30"
                                               rows="5" class="form-control"
                                               placeholder="Write your message..."></textarea>
                                     <div class="alert alert-danger">Message is
                                         .
                                     </div>
                                 </div>
                             </div>
                             <div class="col-lg-12 col-md-12">
                                 <button type="submit" class="default-btn">Send
                                     Message<span></span></button>
                             </div>
                         </div>
                     </form>-->
                    <div class="container mt-3">
                        <!--<h2>Stacked form</h2>-->
                        <form [formGroup]="GrievanceForm" #userPost="ngForm" (ngSubmit)="onSubmit(userPost.value)"
                            ngNativeValidate>
                            <div class="mb-3 mt-3">
                                <label>Name: <span class="text-danger">*</span></label>
                                <input ngModel type="text" class="form-control" id="name" placeholder="Enter Name"
                                    name="Studentname" formControlName="Studentname">
                                <span *ngIf="Studentname.invalid && Studentname.touched" class="text-danger">Student
                                    Name is Required</span>
                            </div>

                            <div class="mb-3 mt-3">
                                <label>Father/Husband's Name:<span class="text-danger">*</span></label>
                                <input ngModel type="text" class="form-control" id="fatheName"
                                    placeholder="Father/Husband's Name" name="FatherName" formControlName="FatherName">
                                <span *ngIf="FatherName.invalid && FatherName.touched" class="text-danger">Father Name
                                    is Required</span>
                            </div>
                            <div class="mb-3 mt-3">
                                <label>Date of Birth:</label>
                                <input ngModel type="text" class="form-control" id="DOB" placeholder="Date of birth"
                                    name="DOB" formControlName="DOB">
                            </div>
                            <!-- <div class="mb-3">
                                <label for="QueryBy">You are:<span
                                        class="text-danger">*</span></label>
                                <select ngModel class="form-select form-control" aria-label="Default select example"
                                    name="QueryBy" id="QueryBy" formControlName="QueryBy" #D (change)="divdis(D.value)">
                                    <option value="1">Student</option>
                                    <!-- <option  value="2">Consultancy</option>
                                    <option  value="3">Other</option> --
                                </select>
                                <span *ngIf="QueryBy.invalid" class="text-danger">Please Choose Your Category</span>
                            </div> -->
                            <div class="mb-3 mt-3" [ngStyle]="{'display':displayStyleCon}">
                                <label>Consultancy Code:</label>
                                <input ngModel type="text" class="form-control" id="ConsultancyCode"
                                    placeholder="Consultancy Code" name="ConsultancyCode" formControlName="ConsultancyCode">
                            </div>
                            <div class="mb-3 mt-3" [ngStyle]="{'display':displayStyleEn}">
                                <label>Enrollment No.:</label>
                                <input ngModel type="text" class="form-control" id="EnrollmentNo"
                                    placeholder="Enrollment No." name="EnrollmentNo" formControlName="EnrollmentNo">
                            </div>
                            <div class="mb-3 mt-3">
                                <label for="email">Email:<span class="text-danger">*</span></label>
                                <input ngModel type="email" class="form-control" id="email" placeholder="Enter email"
                                    name="email" formControlName="email">
                                <span *ngIf="email.invalid && email.touched" class="text-danger">Email is Invalid</span>
                            </div>
                            <div class="mb-3">
                                <label>Number:<span class="text-danger">*</span></label>
                                <input ngModel type="text" class="form-control" id="ContactNo" placeholder="Number"
                                    name="ContactNo" formControlName="ContactNo">
                                <span *ngIf="ContactNo.invalid && ContactNo.touched" class="text-danger">Contact No is
                                    Invalid</span>
                            </div>
                            <div class="mb-3">
                                <label for="Department">Issue Related Department:<span
                                        class="text-danger">*</span></label>
                                <select ngModel class="form-select form-control" aria-label="Default select example"
                                    name="Department" id="Department" formControlName="Department">
                                    <option value="1">Degree</option>
                                    <option value="2">Migration/Consolated Marksheet/Transcript/Provisional Degree
                                    </option>
                                    <option value="3">Verification</option>
                                    <option value="4">Marksheet</option>
                                    <option value="5">Result Issue</option>
                                    <option value="111">Other</option>
                                </select>
                                <span *ngIf="Department.invalid" class="text-danger">Please Choose Department</span>
                            </div>
                            <div class="mb-3">
                                <label>Comments:<span class="text-danger">*</span></label>
                                <textarea ngModel class="form-control" rows="5" id="Message" name="Message"
                                    formControlName="Message"></textarea>
                                <span *ngIf="Message.invalid && Message.touched" class="text-danger">Message is Required
                                    (Minimum length is 10)</span>
                            </div>
                            <!-- <div class="mb-3">
                                <input ngModel formControlName="Attachment" type="file" name="Attachment" id="Attachment">
                            </div> -->
                            <button type="submit" [disabled]="GrievanceForm.invalid" class="btn  text-center float-end"
                                style="background-color: #29166b; color: white;">Submit
                            </button>
                            <!-- <div class="col-lg-3 col-md-12">
                                <button type="button" class="btn  text-center float-end" (click)="ConfirmationSubmit()"
                                    style="background-color: #29166b; color: white;">Submit
                                </button>
                            </div> -->
                        </form>
                    </div>
                </div>
            </div>
            
            <div class="modal mt-10 modal-dialog-centered" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="background: #D3D3D3;">
                            <h4 class="modal-title">Acknowledgement:</h4>
                        </div>
                        <div class="modal-body" style="background: #f4fdf4;">
                            <h3 class="text-success">Your grievance has been submitted successfully...</h3>
                            <br>
                            <h5>Check reply and further communication:</h5>
                            <p><b>For Students: </b>Please login to your profile using your Enrollment number as UserId and Your DOB(ddmmyyyy) as your password.</p>
                            <p><b>For Others: </b>Please wait for our E-mail.</p>
                        </div>
                        <div class="modal-footer" style="background: #D3D3D3;">
                            <button type="button" class="btn btn-danger" (click)="closePopup()">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal mt-10 modal-dialog-centered" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleErr}">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="background: #D3D3D3;">
                            <h4 class="modal-title">Acknowledgement:</h4>
                        </div>
                        <div class="modal-body" style="background: #f4fdf4;">
                            <h3 class="text-danger">Something went wrong...</h3>
                        </div>
                        <div class="modal-footer" style="background: #D3D3D3;">
                            <button type="button" class="btn btn-danger" (click)="closePopupError()">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>