import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bpa',
  templateUrl: './bpa.component.html',
  styleUrls: ['./bpa.component.scss']
})
export class BpaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
