<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Bachelor of Commerce (Honors)</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Introduction</h3>
                        </div>
                        <p>
                            The Bachelor of Commerce (Honors) curriculum covers
                            advanced topics in economics, accounting, and
                            business policy over the course of three years.
                            Students enrolled in the Bachelor of Commerce
                            (Honors) program receive an education in the
                            fundamentals of commerce and trade. Professional
                            opportunities for B.Com Honours grads span many
                            industries, including finance, banking, management,
                            and administration.
                        </p>
                        <h4>Objective</h4>
                        <p>
                            A Bachelor of Commerce (Honors) is a 3-year
                            undergraduate degree that teaches students the
                            fundamentals of business and the specific knowledge
                            and abilities they will need to succeed in a variety
                            of fields.
                        </p>
                        <p>
                            The course covers a wide range of topics vital to
                            the business world, including economics,
                            accountancy, statistics, indirect tax, and business
                            arithmetic. There is a great deal of flexibility in
                            the B.Com Honours program in terms of electives,
                            specializations, and career paths.
                        </p>
                        <p>
                            B.Com Honours graduates can find lucrative
                            employment in both the commercial and public
                            sectors, in a wide variety of well-respected Indian
                            and international companies. A B.Com honors graduate
                            may find work in a variety of areas of commerce,
                            including accounting, finance, and product creation.
                            The program is designed to assist students to
                            improve their knowledge and abilities in the
                            research and development sector, which will be
                            useful throughout their careers.
                        </p>
                        <p>
                            B.Com Honours grads learn the ins and outs of a wide
                            range of commerce fields, which positions them well
                            to instruct the next generation. Graduates of B.Com
                            programs in India and abroad have the opportunity to
                            advance their knowledge and expertise to the highest
                            levels of a wide range of business and public sector
                            enterprises.
                        </p>
                        <p>
                            The B.Com Honours curriculum at the undergraduate
                            level offers students a wealth of exposure to
                            advanced ideas in the field of business. It assists
                            those seeking a variety of professional
                            opportunities, from those in academia to those in
                            the real world at any reputable company.
                        </p>
                        <h5>Programme Duration</h5>
                        <p>
                            Bachelor of Commerce (Honours) is an undergraduate
                            degree program and B. Com programs are typically
                            three years course.
                        </p>
                        <p>
                            Minimum Duration 3 Years<br />Maximum Duration 6
                            Year
                        </p>
                        <h6>Eligibility & Fee</h6>
                        <p>
                            Online/distance Bachelor of Commerce (Honours)
                            eligibility criteria are too very flexible. To
                            pursue an online and distance B. Com one has to be
                            10+2 pass out with Commerce from any recognised
                            university. There are no entrance examinations for
                            admission in online and distance B. Com courses.
                        </p>
                        <table class="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Programme fee</th>
                                    <th>Examination fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Rs. 10,000 Per Year</td>
                                    <td>Rs. 2000 Per Year</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6 style="font-weight: bold">Payment Mode</h6>
                        <table
                            style="width: 100%"
                            cellpadding="0"
                            cellspacing="0"
                            border="1"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Account Holder Name:</b> Swami
                                        Vivekanand Subharti University Distance
                                        Education
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height: 24px">
                                        <b>Account Number:</b> 628501043170
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>IFSC Code:</b> ICIC0006285</td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>Branch:</b> 65 Shiv Puri Hapur Road
                                        Meerut
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h6><strong>Career Prospects</strong></h6>
                        <p>
                            After college, a Bachelor of Commerce (Honours)
                            graduate student has various options for employment,
                            both in the government and private sectors.
                        </p>
                        <h6 style="font-weight: bold">Government Sector-</h6>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Banking
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Accounting
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Taxation
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Wealth Management
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Finance
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Insurance
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Commerce
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Education
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Government Service
                            </li>
                        </ul>
                        <h5 style="font-weight: bold">
                            Private Sector-
                            <hr />
                        </h5>
                        <div class="container p-4" style="text-align: justify">
                            <div class="row">
                                <div class="col-lg-6">
                                    <h6 style="font-weight: bold">
                                        There are many designations for Bachelor
                                        <br />of Commerce (Honours) Graduates
                                        are:
                                    </h6>
                                    <!--<p>There are many designations for Graduates are:</p>-->
                                    <ul>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Charted Accountant (CA)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Company Secretary (CS)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Charted Financial Analyst (CFA)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Financial Risk Management (FRM)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Certified management Accountant
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <h6 style="font-weight: bold">
                                        Best study options after Bachelor of
                                        Commerce (Honours)
                                    </h6>
                                    <ul>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Master of Business Administration
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Chartered Accountancy (CA)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Master of Commerce (M. Com)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Chartered Financial Analyst (CFA)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Business Accounting and Taxation
                                            (BAT)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Certified Management Accountant
                                            (CMA)
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</div>
