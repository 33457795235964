import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from 'src/shared/servies/meta.service';
import { TitleService } from 'src/shared/servies/title.service';
@Component({
    selector: 'app-admission-details',
    templateUrl: './admission-details.component.html',
    styleUrls: ['./admission-details.component.scss'],
})
export class AdmissionDetailsComponent implements OnInit {
    universityUrl: any;
    course: any = [];
    url: any;
    parts: any;
    lastParam: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private titleService: TitleService,
        private titleLibraryService: Title,
        private metaServices: MetaService,
        private metaService: Meta
    ) {
        this.url = window.location.pathname;
        this.parts = this.url.split('/');
        this.lastParam = this.parts[this.parts.length - 1];
    }
    ngOnInit(): void {
        this.getAcademicTitle();
    }

    getAcademicTitle() {
        this.titleService.getAcademicTitleJson().subscribe((res) => {
            this.course = res.titles;
            if (this.lastParam && this.course.length > 0) {
                this.course.forEach(
                    (courseUrl: {
                        twitter_idrmge: string;
                        og_title: string;
                        og_url: string;
                        url: any;
                        title: string;
                        keywords: any;
                        descriptions: any;
                    }) => {
                        if (courseUrl.url === this.lastParam) {
                            this.titleLibraryService.setTitle(courseUrl.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: '',
                                },
                                { name: 'description', content: '' },
                                { property: 'og:title', content: '' },
                                { property: 'og:url', content: '' },
                                { property: 'og:type', content: '' },
                                { property: 'og:description', content: '' },
                                { property: 'og:image', content: '' },
                                { property: 'og:ib.scge', content: '' },
                                { property: 'og:im.scge', content: '' },
                                { property: 'og:ib.edge', content: '' },
                                { property: 'og:ib.libge', content: '' },
                                { property: 'og:im.libge', content: '' },
                                {
                                    property: 'og:ima journalismge',
                                    content: '',
                                },
                                { property: 'og:idfmge', content: '' },
                                { property: 'og:idmmge', content: '' },
                                { property: 'og:idrmge', content: '' },
                                { name: 'twitter:card', content: '' },
                                { name: 'twitter:title', content: '' },
                                { name: 'twitter:description', content: '' },
                                { name: 'twitter:url', content: '' },
                                { name: 'twitter:image', content: '' },
                                { name: 'twitter:ib.scge', content: '' },
                                { name: 'twitter:im.scge', content: '' },
                                { name: 'twitter:ib.edge', content: '' },
                                { name: 'twitter:ib.libge', content: '' },
                                { name: 'twitter:im.libge', content: '' },
                                {
                                    name: 'twitter:ima journalismge',
                                    content: '',
                                },
                                { name: 'twitter:idfmge', content: '' },
                                { name: 'twitter:idmmge', content: '' },
                                { name: 'twitter:idrmge', content: '' },
                            ]);

                            this.metaService.updateTag({
                                name: 'keywords',
                                content: courseUrl.keywords,
                            });
                            this.metaService.updateTag({
                                name: 'description',
                                content: courseUrl.descriptions,
                            });
                            this.metaService.updateTag({
                                property: 'og:title',
                                content: courseUrl.og_title,
                            });
                            this.metaService.updateTag({
                                property: 'og:url',
                                content: courseUrl.og_url,
                            });

                            this.metaService.updateTag({
                                name: 'twitter:idmmge',
                                content: courseUrl.twitter_idrmge,
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idrmge',
                                content: courseUrl.twitter_idrmge,
                            });
                        }
                    }
                );
            }
        });
    }
}
