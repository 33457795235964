<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Evalution Procedure</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <!--<div class="blog-details-desc">
                    <div class="article-content">
                        <h3>EXAMINATION SCHEDULE</h3>
                        <p>In exercise of the powers vested under section 7 (b) of the Act, Swami Vivekanand Subharti
                            University has established the Centre for Distance and Online Education (Formerly Directorate of Distance Education) to help those who want to
                            pursue higher studies, but are unable to do so through regular mode of education due to job
                            constraints, family commitments or remoteness of location.</p>
                        <p>The Distance Education Council of Government of India has accorded recognition to the
                            University for offering programmes through distance education mode also vide letter no.
                            DEC/Recog/2009/3174 dated 09.09.2009, on the recommendation of the Joint Committee of the
                            University Grants Commission, All India Council for Technical Education and Distance
                            Education Council. Accordingly, the University has started Distance Education programmes at
                            undergraduate and post-graduate levels with effect from the academic year 2009-10 in various
                            disciplines.</p>
                        <p>As per UGC letter no. F.9.37/2008(CPP-1) dated 06.02.2009, Swami Vivekanand Subharti
                            University, being a State approved private University u/s 2(f) of UGC Act 1956, is competent
                            to award degrees specified in section 22 of the UGC Act 1956. The programmes conducted by
                            the University through distance education are identical in content to the courses offered
                            through the normal mode. Consequently, as per Government of India Gazette Notification No.
                            44, F.No.18-15/93-TD.V/TS-IV dated 01.03.1995, the degrees awarded by the University through
                            distance education stand automatically recognized for all purposes, including employment to
                            posts and services under the Central Government.</p>
                    </div>
                </div>-->
                <div class="about-text-container">
                    <h4 style="background: #29176d;color: white;padding: 21px; margin-bottom:15px">Evaluation
                        Procedure</h4>
                    <div class="rightcategory_box">
                        <p>
                            The University follows the following evaluation system:</p>
                        <p>
                            (1) Continuous evaluation through personal contact programme, assignment work, viva
                            &amp; group discussion and personality development programme.</p>
                        <p>
                            (2) Semester wise examination will be conducted by the University.</p>
                        <p>
                            (3) Evaluation of practical, wherever prescribed by Examiners appointed by SVSU.</p>
                        <p>
                            (4) Evaluation of professional project report, wherever prescribed will be submitted
                            to CDOE (Formerly DDE).</p>
                        <p>
                            (5) Report on internship &amp; industry integrated learning wherever prescribed
                            will be submitted to CDOE (Formerly DDE).</p>
                        <p>
                            <b><a href="http://studentresult.in/">Grading System.</a></b></p>
                        <p>
                            The final grading of the learner shall be determined on the basis of the marks obtained
                            by the learner in examinations and continuous internal assessment, after substituting
                            the marks obtained in reappear papers, if any. The learner’s achievement shall be
                            indicated on a six point scale, as given below:</p>
                        <table border="1" cellpadding="0" cellspacing="0">
                            <tbody>
                            <tr>
                                <td valign="top">
                                    <p>
                                        <b>Marks</b></p>
                                </td>
                                <td valign="top">
                                    <p>
                                        <b>Description</b></p>
                                </td>
                                <td valign="top">
                                    <p>
                                        <b>Division</b></p>
                                </td>
                                <td valign="top">
                                    <p>
                                        <b>Grade</b></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        85% and above</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        Outstanding</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        First</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        A++</p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        75% and above but below 85%</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        Excellent</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        First</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        A+</p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        60% and above but below 75%</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        Very Good</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        First</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        A</p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        50% and above but below 60%</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        Good</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        Second</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        B</p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        40% and above but below 50%</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        Average</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        Third</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        C</p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>
                                        Below 40%</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        Unsatisfactory</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        Fail</p>
                                </td>
                                <td valign="top">
                                    <p>
                                        D</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
