<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Academic Calender</h2>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-text-container">
                    <h4 style="background: #29176d;color: white;">Academic
                        Calender</h4>
                    <div class="accor_heading downToggle" style="overflow-x:auto;">
                        <table class="table  table-striped" style="border: 1px solid black;">
                            <thead>
                            <tr>
                                <td>
                                    <p>
                                        <b>Academic Activity</b></p>
                                </td>
                                <td>
                                    <p>
                                        <b>Academic Batch </b>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        <b>Calender Batch </b>
                                    </p>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <p>
                                        Notification for Program Announcement &amp; <br>Issue of Application Forms
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        March/April</p>
                                </td>
                                <td>
                                    <p>
                                        September/October</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        Admission
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        July-August</p>
                                </td>
                                <td>
                                    <p>
                                        January-February
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        Dispatch of SLMs
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        August
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        February
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        Uploading of Assignments on the Website <br> "www.subhartidde.com"
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        February &amp; Return of Assignment 15th March
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        August &amp; Return of Assignment 15th September
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        Last Date of Submission of Examination<br> Form
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        30th April
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        30th October
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        Examination
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        June
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        December
                                    </p></td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        Announcement / Declaration of Result
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        September
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        February
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        PCP
                                    </p>
                                </td>
                                <td>
                                    September-May
                                </td>
                                <td>
                                    March-November
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
