import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-for-session-c23',
  templateUrl: './for-session-c23.component.html',
  styleUrls: ['./for-session-c23.component.scss']
})
export class ForSessionC23Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
