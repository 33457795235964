<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>For Session 2022-23</h2>
        </div>
        <div class="shape9">
            <img src="assets/img/shape8.svg" alt="image" />
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-text-container">
                    <!--<h4 style="background: #29176d;color: white;padding: 21px; margin-bottom:15px">Fee Structure (Applicable from Academic Session 2022-23 onwards)</h4>-->
                    <div class="about-text-container">
                        <!--<div class="accor_heading downToggle">-->
                        <div
                            class="accor_heading downToggle"
                            style="overflow-x: auto"
                        >
                            <table
                                cellspacing="0"
                                cellpadding="0"
                                class="table table-striped"
                                style="border: 1px solid black"
                            >
                                <colgroup>
                                    <col width="49" />
                                    <col width="54" />
                                    <col width="405" />
                                    <col width="310" />
                                </colgroup>
                                <tbody>
                                    <tr height="40" style="background: #29176d">
                                        <td
                                            height="40"
                                            width="52"
                                            style="color: #ffffff"
                                        >
                                            S.No.
                                        </td>
                                        <td width="82" style="color: #ffffff">
                                            UG/PG
                                        </td>
                                        <td width="405" style="color: #ffffff">
                                            Programme Name
                                        </td>
                                        <td width="310" style="color: #ffffff">
                                            Specialization
                                        </td>
                                        <td width="88" style="color: #ffffff">
                                            Annual Fee in Rupees
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td rowspan="8" height="160">1</td>
                                        <td rowspan="8">UG</td>
                                        <td rowspan="8">BACHELOR OF ARTS</td>
                                        <td>
                                            GENERAL(Combination of any 3
                                            Subjects)
                                        </td>
                                        <td rowspan="8" width="88">6500</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">
                                            1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            HINDI
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">
                                            2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            ENGLISH
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">
                                            3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            POLITICAL SCIENCE
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">
                                            4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            HISTORY
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">
                                            5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            ECONOMICS
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">
                                            6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            SOCIOLOGY
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">
                                            7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            MATHEMATICS
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">2</td>
                                        <td>UG</td>
                                        <td>
                                            BACHELOR OF LIBRARY AND INFORMATION
                                            SCIENCES
                                        </td>
                                        <td>
                                            LIBRARY AND INFORMATION SCIENCES
                                        </td>
                                        <td width="88">14000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">3</td>
                                        <td>UG</td>
                                        <td>
                                            BACHELOR OF BUSINESS ADMINISTRATION
                                        </td>
                                        <td>MANAGEMENT</td>
                                        <td width="88">12000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">4</td>
                                        <td>UG&nbsp;</td>
                                        <td>BACHELOR OF COMMERCE</td>
                                        <td>MANAGEMENT</td>
                                        <td width="88">8000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">5</td>
                                        <td>UG&nbsp;</td>
                                        <td>BACHELOR OF COMMERCE (HONORS)</td>
                                        <td>MANAGEMENT</td>
                                        <td width="88">10000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">6</td>
                                        <td>UG</td>
                                        <td>BACHELOR OF ARTS (JMC)</td>
                                        <td>
                                            JOURNALISM AND MASS COMMUNICATION
                                        </td>
                                        <td width="88">12000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">7</td>
                                        <td>PG</td>
                                        <td>MASTER OF ARTS</td>
                                        <td>BUDDHIST STUDIES</td>
                                        <td width="88">13000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">8</td>
                                        <td>PG</td>
                                        <td>MASTER OF ARTS</td>
                                        <td>EDUCATION</td>
                                        <td width="88">14400</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">9</td>
                                        <td>PG</td>
                                        <td>MASTER OF ARTS</td>
                                        <td>PUBLIC ADMINISTRATION</td>
                                        <td width="88">12000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">10</td>
                                        <td>PG</td>
                                        <td>MASTER OF ARTS</td>
                                        <td>HOME SCIENCE</td>
                                        <td width="88">12000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">11</td>
                                        <td>PG</td>
                                        <td>
                                            MASTER OF LIBRARY AND INFORMATION
                                            SCIENCES
                                        </td>
                                        <td>
                                            LIBRARY AND INFORMATION SCIENCES
                                        </td>
                                        <td width="88">18000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">12</td>
                                        <td>PG</td>
                                        <td>
                                            MASTER OF BUSINESS ADMINISTRATION
                                        </td>
                                        <td>
                                            GENERAL (Combination of any 2
                                            Subjects)
                                        </td>
                                        <td width="88">24000</td>
                                    </tr>

                                    <tr height="20">
                                        <td height="20">13</td>
                                        <td>PG</td>
                                        <td>MASTER OF COMMERCE</td>
                                        <td>MANAGEMENT</td>
                                        <td width="88">12000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">14</td>
                                        <td>PG</td>
                                        <td>MASTER OF ARTS (JMC)</td>
                                        <td>
                                            JOURNALISM AND MASS COMMUNICATION
                                        </td>
                                        <td width="88">14000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">15</td>
                                        <td>PG</td>
                                        <td>MASTER OF ARTS</td>
                                        <td>POLITICAL SCIENCES</td>
                                        <td width="88">12000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">16</td>
                                        <td>PG</td>
                                        <td>MASTER OF ARTS</td>
                                        <td>MATHEMATICS</td>
                                        <td width="88">12000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">17</td>
                                        <td>PG</td>
                                        <td>MASTER OF ARTS</td>
                                        <td>SOCIOLOGY</td>
                                        <td width="88">12000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">18</td>
                                        <td>PG</td>
                                        <td>MASTER OF ARTS</td>
                                        <td>HISTORY</td>
                                        <td width="88">12000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">19</td>
                                        <td>PG</td>
                                        <td>MASTER OF ARTS</td>
                                        <td>HINDI</td>
                                        <td width="88">12000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">20</td>
                                        <td>PG</td>
                                        <td>MASTER OF ARTS</td>
                                        <td>ECONOMICS</td>
                                        <td width="88">12000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20">21</td>
                                        <td>PG</td>
                                        <td>MASTER OF ARTS</td>
                                        <td>ENGLISH</td>
                                        <td width="88">12000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br />
                        <br />
                        <div>
                            <p>
                                <b>Other Fees: </b>
                            </p>
                            <p>
                                <strong
                                    >In addition to the course fee, the learner
                                    will be required to deposit the following
                                    fee at appropriate</strong
                                >
                            </p>
                            <br />
                            <p>
                                Registration fee: Rs. 1500 (Non refundable & Non
                                Transferable)
                            </p>
                            <p></p>
                            Examination Fee : Rs. 2000 per Annum
                            <p></p>
                            <p>Reappear Paper Fee : Rs. 250 per paper</p>
                            <p>Credit Transfer Fee : Rs. 1500</p>
                            <p>Continuation Fee : Rs. 2000</p>
                            <p></p>
                            Re-Totaling Fee : Rs. 200 per paper
                            <p></p>
                            <p>Change of Address Fee : Rs. 500</p>
                            <p>Change of Specialization Fee : Rs. 500</p>
                            <p></p>
                            Certificate/ Diploma/ Provisional Degree Fee : Rs.
                            500
                            <p></p>
                            <p>Consolidated Marksheet Fee : Rs. 2000</p>
                            <p></p>
                            Duplicate Marksheet Fee : Rs. 500
                            <p></p>
                            <p>Final Degree Fee : Rs. 3000</p>
                            <p>Confidential Result Fee : Rs. 1000</p>
                            <p>Final Diploma Fee : Rs. 2000</p>
                            <p></p>
                            Document Verification Fee : Rs. 1500 (Per Document
                            for non Gov. Organizations)
                            <p></p>
                            <p>Transcript Fee : Rs. 1000 for each</p>
                            <p></p>
                            Degree Correction : Rs. 500
                            <p></p>
                            <p></p>
                            Marksheet Correction Fee : Rs. 500
                            <p></p>
                            <p></p>
                            <h3>Note : Fee refund policy as per UGC norms</h3>
                            <p><strong>No any other hidden charges</strong></p>
                        </div>
                        <div style="padding-top: 20px">
                            Student can submit his fee in cash to cash counter
                            in department or submit online<br />
                            <b
                                >For online payment, Account Details are as
                                follow :</b
                            >
                            <br /><br />
                            <table
                                style="width: 100%"
                                cellpadding="0"
                                cellspacing="0"
                                border="1"
                            >
                                <tbody>
                                    <tr>
                                        <td>
                                            <b>Account Holder Name:</b> Swami
                                            Vivekanand Subharti University
                                            Distance Education
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="height: 24px">
                                            <b>Account Number:</b> 628501043170
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>IFSC Code:</b> ICIC0004078</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <b>Branch:</b> 65 Shiv Puri Hapur
                                            Road Meerut
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <table
                                style="width: 100%"
                                cellpadding="0"
                                cellspacing="0"
                                border="1"
                            >
                                <tbody>
                                    <tr>
                                        <td>
                                            <b>Account Holder Name:</b> S
                                            .V.S.U. ( D.D.E)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="height: 24px">
                                            <b>Account Number:</b>
                                            52282191015961
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>IFSC Code:</b> PUNB0522810</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <b>Branch:</b> SUBHARTIPURAM, DELHI
                                            HARIDWAR BYE-PASS, MEERUT
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
