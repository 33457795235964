<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Assignments for Exam 2022 - 1st Semester</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-text-container">
                    <h4 style="background: #29176d;color: white;padding: 21px; margin-bottom:15px">Assignments for
                        Calendar Batch '2022' First Year</h4>
                    <div class="rightcategory_box">
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/ADBA.docx"
                                       style="font-size:18px; font-weight:400">Assignments for ADBA</a>
                                </h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/ADCA.docx"
                                       style="font-size:18px; font-weight:400"> Assignments for ADCA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/B.COM.docx"
                                       style="font-size:18px; font-weight:400"> Assignments for B.Com</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/B.COM(HONS).docx"
                                       style="font-size:18px; font-weight:400"> Assignments for B.Com((HONS)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/B.LIB.docx"
                                       style="font-size:18px; font-weight:400"> Assignments for b.Lib</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/BA-ECONOMICS.docx"
                                       style="font-size:18px; font-weight:400">Assignment for B.A.(Economics)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/B.SC(PCM).docx"
                                       style="font-size:18px; font-weight:400">Assignment for B.SC(PCM)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/B.SC(ZBC).docx.docx"
                                       style="font-size:18px; font-weight:400">Assignment for B.SC(ZBC)</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/BA-HINDI.docx"
                                       style="font-size:18px; font-weight:400"> Assignment for B.A.(Hindi)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/BA-HISTRY.docx"
                                       style="font-size:18px; font-weight:400">Assignment for B.A.(History)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/BA-POLITICAL%20SCIENCE.docx"
                                       style="font-size:18px; font-weight:400">Assignment for B.A.(Political
                                        Science)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/BA-SOCIOLOGY.docx"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Sociology)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/BA-ENGLISH.docx"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(English)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/BA-Fine%20Arts.docx"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Fine Art)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/BA-Fashion%20Designs.docx"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Fashion
                                        Designing)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/BA-MATHEMATICS.docx"
                                       style="font-size:18px; font-weight:400">Assignments for B.A.(Math)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/BA-JMC.docx"
                                       style="font-size:18px; font-weight:400"> Assignments for B.A.(JMC)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/BBA.docx"
                                       style="font-size:18px; font-weight:400"> Assignments for BBA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/BCA.docx"
                                       style="font-size:18px; font-weight:400">Assignments for BCA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/M.COM.docx"
                                       style="font-size:18px; font-weight:400">Assignments for M.Com</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/M.LIB.docx"
                                       style="font-size:18px; font-weight:400">Assignments for M.Lib</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/M.SC.(CA).docx"
                                       style="font-size:18px; font-weight:400">Assignments for M.Sc (CA)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/M.SC.(DFSM).docx"
                                       style="font-size:18px; font-weight:400">Assignments for M.Sc(DFSM)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/MA-HINDI.docx"
                                       style="font-size:18px; font-weight:400">Assignments for M.A(Hindi)</a></h6>


                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/MA-BUDDHIST.docx"
                                       style="font-size:18px; font-weight:400">Assignments for MA-BUDDHIST</a></h6>


                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/MA-ENGLISH.docx"
                                       style="font-size:18px; font-weight:400"> Assignments for M.A(English)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/MA-ECONOMICS.docx"
                                       style="font-size:18px; font-weight:400"> Assignments for MA-ECONOMICS</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/MA-EDUCATION.docx"
                                       style="font-size:18px; font-weight:400"> Assignments for MA-EDUCATION</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/MA-HISTRY.docx"
                                       style="font-size:18px; font-weight:400">Assignments for M.A(History)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/MA-HOME%20SCIENCE.docx"
                                       style="font-size:18px; font-weight:400">Assignments for MA-HOME SCIENCE</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/MA-PUBLIC ADMINISTRATION.docx"
                                       style="font-size:18px; font-weight:400">Assignments for M.A(Public Admin)</a>
                                </h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/MA-POLITICAL%20SCIENCE.docx"
                                       style="font-size:18px; font-weight:400"> Assignments for M.A(Political
                                        Science)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/MA-SOCIOLOGY.docx"
                                       style="font-size:18px; font-weight:400">Assignments for M.A(Sociology)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/MA-JMC.docx"
                                       style="font-size:18px; font-weight:400">Assignments for M.A.(JMC)</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/M.A-MATHEMATICS.docx"
                                       style="font-size:18px; font-weight:400">Assignments for M.A.(mathematics)</a></h6>

                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/MCA.docx"
                                       style="font-size:18px; font-weight:400">Assignments for MCA</a></h6>

                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                    <a href="assets/documents/ASSIGNMENT IST SEMESTER ,JUNE,22/MBA.docx"
                                       style="font-size:18px; font-weight:400">Assignments for MBA</a></h6>

                            </div>
                        </div>
                        <br class="clear">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

