<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>ASSIGNMENTS</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="courses-area ptb-100 bg-f5f7fa">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="about-text-container">
                    <h4
                    style="
                        background: #29176d;
                        color: white;
                        padding: 21px;
                        margin-bottom: 15px;
                    "
                >
                    Assignments July 2024
                </h4>

                <b>
                    <br />

                    <a
                        class="hover-underline-animation"
                        routerLink="/assignments-1st-semester-July2024"
                        >ASSIGNMENT 1st SEMESTER JULY 2024</a
                    ><br /><br />
                    <a
                        class="hover-underline-animation"
                        routerLink="/assignments-2nd-semester-July2024"
                        >ASSIGNMENT 2nd SEMESTER JULY 2024</a
                    ><br /><br />
                    <a
                        class="hover-underline-animation"
                        routerLink="/assignments-3rd-semester-July2024"
                        >ASSIGNMENT 3rd SEMESTER JULY 2024</a
                    ><br /><br />
                    <a
                        class="hover-underline-animation"
                        routerLink="/assignments-4th-semester-July2024"
                        >ASSIGNMENT 4th SEMESTER JULY 2024</a
                    ><br /><br />
                    <a
                        class="hover-underline-animation"
                        routerLink="/assignments-5th-semester-July2024"
                        >ASSIGNMENT 5th SEMESTER JULY 2024</a
                    ><br /><br />
                    <a
                        class="hover-underline-animation"
                        routerLink="/assignments-6th-semester-July2024"
                        >ASSIGNMENT 6th SEMESTER JULY 2024</a
                    ><br /><br />

                    <br />
                    <br />
                </b>
                    <!-- <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Assignments January 2024
                    </h4>

                    <b>
                        <br />

                        <a
                            class="hover-underline-animation"
                            routerLink="/assignments-1st-semester-January2024"
                            >ASSIGNMENT 1st SEMESTER JANUARY 2024</a
                        ><br /><br />
                        <a
                            class="hover-underline-animation"
                            routerLink="/assignments-2nd-semester-January2024"
                            >ASSIGNMENT 2nd SEMESTER JANUARY 2024</a
                        ><br /><br />
                        <a
                            class="hover-underline-animation"
                            routerLink="/assignments-3rd-semester-January2024"
                            >ASSIGNMENT 3rd SEMESTER JANUARY 2024</a
                        ><br /><br />
                        <a
                            class="hover-underline-animation"
                            routerLink="/assignments-4th-semester-January2024"
                            >ASSIGNMENT 4th SEMESTER JANUARY 2024</a
                        ><br /><br />
                        <a
                            class="hover-underline-animation"
                            routerLink="/assignments-5th-semester-January2024"
                            >ASSIGNMENT 5th SEMESTER JANUARY 2024</a
                        ><br /><br />
                        <a
                            class="hover-underline-animation"
                            routerLink="/assignments-6th-semester-January2024"
                            >ASSIGNMENT 6th SEMESTER JANUARY 2024</a
                        ><br /><br />

                        <br />
                        <br />
                    </b> -->
                    <!-- <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Assignments Aug-Sep 2023
                    </h4>

                    <b>
                        <br />

                        <a
                            class="hover-underline-animation"
                            routerLink="/assignments-1st-semester-Aug-Sep-23"
                            >ASSIGNMENT 1st SEMESTER Aug-Sep 2023</a
                        ><br /><br />
                        <a
                            class="hover-underline-animation"
                            routerLink="/assignments-2nd-semester-Aug-Sep-23"
                            >ASSIGNMENT 2nd SEMESTER Aug-Sep 2023</a
                        ><br /><br />
                        <a
                            class="hover-underline-animation"
                            routerLink="/assignments-3rd-semester-Aug-Sep-23"
                            >ASSIGNMENT 3rd SEMESTER Aug-Sep 2023</a
                        ><br /><br />
                        <a
                            class="hover-underline-animation"
                            routerLink="/assignments-4th-semester-Aug-Sep-23"
                            >ASSIGNMENT 4th SEMESTER Aug-Sep 2023</a
                        ><br /><br />
                        <a
                            class="hover-underline-animation"
                            routerLink="/assignments-5th-semester-Aug-Sep-23"
                            >ASSIGNMENT 5th SEMESTER Aug-Sep 2023</a
                        ><br /><br />
                        <a
                            class="hover-underline-animation"
                            routerLink="/assignments-6th-semester-Aug-Sep-23"
                            >ASSIGNMENT 6th SEMESTER Aug-Sep 2023</a
                        ><br /><br />

                        <br />
                        <br />
                    </b> -->
                    <!-- <h4 style="background: #29176d;color: white;padding: 21px; margin-bottom:15px">Assignments August 2022</h4>
                    <div class="rightcategory_box text-align">
                        <b>
                            <br> -->
                    <!--<a routerLink="/assignments-5st-semester-june-22">Assignments for Exam 2022 - 5th Semester</a><br>-->

                    <!--<a routerLink="/assignments-4st-semester-june-22">Assignments for Exam 2022 - 4th Semester</a><br>-->

                    <!--<br>
                            <a class=hover-underline-animation routerLink="/assignments-3st-semester-june-22">Assignments for Exam 2022 - 3rd Semester</a><br>-->

                    <!--<br>
                            <a class=hover-underline-animation routerLink="/assignments-2st-semester-june-22">Assignments for Exam 2022 - 2nd Semester</a><br>-->

                    <!--<a routerLink="/assignments-2st-semester-august-22">ASSIGNMENT 2th SEMESTER  AUGUST 2022</a><br><br>--
                            <a class="hover-underline-animation" routerLink="/assignments-1st-semester-august-22">ASSIGNMENT 1st SEMESTER AUGUST 2022</a><br><br>
                            <a class="hover-underline-animation" routerLink="/assignments-2nd-semester-august-22">ASSIGNMENT 2nd SEMESTER AUGUST 2022</a><br><br>
                            <a class="hover-underline-animation" routerLink="/assignments-3rd-semester-august-22">ASSIGNMENT 3rd SEMESTER AUGUST 2022</a><br><br>
                            <a class="hover-underline-animation" routerLink="/assignments-4th-semester-august-22">ASSIGNMENT 4th SEMESTER AUGUST 2022</a><br><br>
                            <a class="hover-underline-animation" routerLink="/assignments-5th-semester-august-22">ASSIGNMENT 5th SEMESTER AUGUST 2022</a><br><br>
                            <!--<a routerLink="/assignments-1st-semester-june-22">Assignments for Exam 2022 - 1st Semester</a><br>-->
                    <!-- <a class="hover-underline-animation" routerLink="/assignments-1st-semester-june-22">ASSIGNMENT 1st SEMESTER JUNE 22</a><br>
                            <br>
                            <a class="hover-underline-animation" routerLink="/assignments-2st-semester-june-22">ASSIGNMENT 2rd SEMESTER JUNE
                                22</a><br><br>
                            <a class="hover-underline-animation" routerLink="/assignments-3st-semester-june-22">ASSIGNMENT 3rd SEMESTER JUNE
                                22</a><br><br>
                            <a class="hover-underline-animation" routerLink="/assignments-4st-semester-june-22">ASSIGNMENT 4th SEMESTER JUNE
                                22</a><br><br>

                            <a class="hover-underline-animation" routerLink="/assignments-5st-semester-june-22">ASSIGNMENT 5th SEMESTER JUNE 22</a><br><br> -->

                    <!-- <a class=hover-underline-animation routerLink="/assignment9">Assignment of Academic Batch- 2021-2022 (III
                                 YEAR)</a><br>

                             <br>
                             <a class=hover-underline-animation routerLink="/assignment8">Assignment of Academic Batch- 2019 (III YEAR)</a><br>

                             <br>

                             <a class=hover-underline-animation routerLink="/assignment7">Assignment of Academic Batch- 2020 (II YEAR)</a><br>

                             <br>

                             <a class=hover-underline-animation routerLink="/admission-details">Assignment of Academic Batch-2018-19(III YEAR)</a><br>

                             <br>

                             <a class=hover-underline-animation routerLink="/assignment6">Assignment of Academic Batch-2019-20(II YEAR)</a><br>


                             <br>

                             <a class=hover-underline-animation routerLink="/assignment5">Assignment of Calendar Batch-2020(II Semester).</a>

                             <br>

                             <br>


                             <a class=hover-underline-animation routerLink="/assignment4">Assignment of Academic Batch-2021(I Semester).</a>
                             <br>
                             <br>
                             <a class=hover-underline-animation routerLink="/assignment3">Assignment's of Academic Batch-2021-22-I Semester.</a>
                             <br>


                             <br>
                             <a class=hover-underline-animation routerLink="/assignment6">Assignment's of Academic Batch-2021-22-IISemester.</a>
                             <br>
                             <br>

                             <a class=hover-underline-animation routerLink="/assignment5">Assignment's of Academic
                                 Batch-2021-22-IIISemester.</a>
                             <br>
                             <br>
                             <a class=hover-underline-animation routerLink="/assignment4">Assignment's of Academic Batch-2021-22-IVSemester.</a>
                             <br>
                             <br>
                             <a class=hover-underline-animation routerLink="/assignment3">Calendar Batch '2019' Second Year.</a>

                             <br>

                             <br>

                             <a class=hover-underline-animation routerLink="/assignment2">Calendar Batch '2018' Third Year.</a>--

                            <br>
                            <br>
                        </b>
                        <br>
                        <b>Note :</b> Please download and install hindi font "Kruti Dev 011" to view the assignments
                        of Hindi Subjects.To download this font click on following link.
                        <br>
                        <div align="center">
                            <a class="hover-underline-animation" routerLink="/assignment1"><b>Download Hindi Font "Kruti Dev 011"</b></a>
                        </div>
                    </div>
                </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
