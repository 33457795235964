<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>CREDIT TRANSFER POLICY</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>
                            POLICY FOR CREDIT TRANSFER IN DISTANCE COURSES RUN
                            BY CDOE (Formerly DDE)
                        </h3>
                        <p>
                            The Centre for Distance and Online Education (Formerly Directorate of Distance Education) adopted credit
                            transfer policy for UG and PG degree courses run by
                            CDOE (Formerly DDE) as given below –
                        </p>
                        <ol>
                            <li>
                                If a Learner has completed his/her 1st year or
                                2nd year from any other university and wishes to
                                apply for 2nd year or 3rd year respectively,
                                he/she can apply for the same on the prescribed
                                form along with the prescribed fee to the
                                Centre for Distance and Online Education (Formerly Directorate of Distance Education).
                            </li>
                            <li>
                                The Learner will have to deposit all his
                                relevant documents attested by a Gazetted
                                Officer (with a clear seal of the name and
                                designation) along with the application.
                            </li>
                            <li>
                                The Credit Transfer fee will be Rs. 1500/-
                                additional charges of qualifying exam.
                            </li>
                            <li>The additional charges will be as follows –</li>

                            <div
                                class="accor_heading downToggle"
                                style="overflow-x: auto"
                            >
                                <table
                                    cellspacing="0"
                                    cellpadding="0"
                                    class="table table-striped"
                                    style="border: 1px solid black"
                                >
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Standard</th>
                                            <th>Charges</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <p>Traditional UG Course</p>
                                                <p>
                                                    BA,B.Com &amp; B.Com (Hons.)
                                                </p>
                                            </td>
                                            <td>
                                                Rs.500/-per additional paper
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                                <p>Traditional PG Course</p>
                                                <p>MA, M.Com</p>
                                            </td>
                                            <td>
                                                Rs.700/-per additional paper
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>
                                                <p>Professional UG Course</p>
                                                <p>BBA, BA-JMC</p>
                                            </td>
                                            <td>
                                                Rs.800/-per additional paper
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>
                                                <p>Professional PG Course</p>
                                                <p>MBA, MA-JMC</p>
                                            </td>
                                            <td>
                                                Rs.1000/-per additional paper
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p>
                                Note-The above-mentioned fee can change as per
                                the rules of CDOE (Formerly DDE).
                            </p>
                            <li>
                                The eligibility criteria and matching of the
                                credits for that course will be done and
                                according to the credits matched as per the
                                norms of the University, the Learner will be
                                given admission &amp; promoted to the
                                appropriate year.
                            </li>
                            <li>
                                In case the credits are short, the student will
                                be required to appear in the additional papers
                                to match the credits.
                            </li>
                            <li>
                                The student/learner will have to give an
                                affidavit as per the prescribed format that all
                                information given by him/her are correct and all
                                documents deposited by him/her are genuine. In
                                case any information given by him/her is found
                                fake or any document submitted is found fake or
                                not Genuine, he/she will be liable for the
                                appropriate administrative/legal action against
                                him/her.
                            </li>
                            <li>
                                The Directorate reserves the right to get the
                                certificate/mark-sheets/documents verified by
                                the issuing authority.
                            </li>
                            <li>
                                The Policy of credit transfer will be applicable
                                only in the degree courses at PG & UG Level run
                                by CDOE (Formerly DDE).
                            </li>
                            <li>
                                The student/Learner may be allowed to take
                                admission under Credit Transfer Policy depending
                                upon the eligibility from Regular to Distance
                                mode, Private to Distance mode and Distance to
                                Distance mode. However as per rules, the student
                                will not be eligible to take admission under
                                Credit Transfer from Distance to Regular mode.
                                The approval of the previous university under
                                which the student has been continuing course
                                under Regular/Distance/Private mode will be
                                checked before giving the admission.
                            </li>
                        </ol>
                        <h4>
                            General Instructions for Credit Transfer at CDOE (Formerly DDE),
                            SVSU, Meerut
                        </h4>
                        <p style="color: black">Credit Transfer</p>
                        <p>
                            “Credit transfer” means allowing a student of
                            another recognized university to get admitted to
                            CDOE (Formerly DDE), SVSU for completing any equivalent degree
                            programme on the basis of credits obtained by
                            him/her from that University. A student thus
                            admitted need not write CDOE (Formerly DDE) examinations for such
                            courses which are found equivalent to and for which
                            appropriate credits would be deemed to have been
                            acquired for, and for purposes of fulfilling the CDOE (Formerly DDE)
                            requirements for award of a degree.
                        </p>
                        <h5>Eligibility Criteria</h5>
                        <p>
                            1 The credit transfer scheme is applicable only to
                            those candidates who have not completed their degree
                            from any other recognised university but are willing
                            to complete it through CDOE (Formerly DDE) as per rules provided.
                        </p>
                        <p>
                            2 Normally credit transfer will be applicable only
                            from a graduate degree to a graduate degree and post
                            graduate to post graduate degree respectively.
                        </p>
                        <h6>Instruction to be followed</h6>
                        <p>
                            1 Do not forget to enclose your marks-sheet and
                            syllabus of the courses which you have passed and
                            against which you seek credit transfer. Note that
                            the syllabus should be of the same year in which you
                            passed the courses.
                        </p>
                        <p>
                            2 Credit transfer will be permissible for those
                            students who have completed 1st year or 2nd year
                            through regular, distance or private mode from any
                            recognized university.
                        </p>
                        <p>
                            3 Credit transfer can be done only on the basis of
                            individual courses and not on the basis of year to
                            year courses as in conventional institutions.
                        </p>
                        <p>
                            4 A demand Draft of required fee drawn in favour of
                            SVSU Distance Education” payable at Meerut with the
                            attested copies of Mark Sheet(s) and attested copies
                            of syllabi of courses covered by them to be send to
                            the following address –
                        </p>
                        <p>
                            Centre for Distance and Online Education (Formerly Directorate of Distance Education),<br />Swami
                            Vivekanand Subharti University,<br />Subhartipuram
                            NH-58 Delhi- Haridwar Bypass Road,<br />Meerut,
                            250005<br />Mob.– 7456901051, 7456901054 <br />Email
                            ID : ddesvsu@gmail.com<br />Website :
                            www.subhartidde.com
                        </p>
                        <p>
                            Credit transfer/exemption can be considered only for
                            the courses at the same level, e.g. undergraduate,
                            post-graduate etc.<br />The credit transfer for a
                            course can not be allowed, if the corresponding
                            course of CDOE (Formerly DDE), SVSU has more credits than the
                            credits for the corresponding completed course by
                            the student from other<br />universities/institution.
                            In this case, student will be required to give the
                            extra papers to match the credits
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
