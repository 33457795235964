<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>LATERAL ENTRY POLICY</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>
                            POLICY FOR LATERAL ENTRY IN DISTANCE COURSES RUN BY
                            CDOE (Formerly DDE)
                        </h3>
                        <h4>
                            The Centre for Distance and Online Education (Formerly Directorate of Distance Education) has adopted
                            Lateral Entry Policy for UG and PG degree courses
                            run by CDOE (Formerly DDE) as given below –
                        </h4>
                        <!-- <p>1 If a Learner has completed one year Post Graduate Diploma in Business Management and wishes to apply for 2nd year in MBA course
                            respectively through the lateral entry, he/she can apply for the same on the prescribed form
                            along with the prescribed fee to the Centre for Distance and Online Education (Formerly Directorate of Distance Education).
                        </p>
                        <p>2 The Learner will have to deposit all his relevant documents attested by a Gazetted Officer
                            (with a clear seal of the name and designation) along with the application.</p>
                        <p>3 The Lateral Entry fee will be Rs. 1500/-+additional charges of qualifying exam.</p>
                        <p>4 The additional charges will be as follows –</p>-->
                        <p>
                            “Lateral Entry” means an applicant who has passed an
                            unassembled examination consisting of an appraisal
                            of training, education, experience, work history or
                            any other means for evaluating relative
                            qualifications. A student thus admitted need not
                            write CDOE (Formerly DDE) examinations for such courses which are
                            found equivalent to and for which appropriate
                            lateral entry would be deemed to have been acquired
                            for, and for purposes of fulfilling the CDOE (Formerly DDE)
                            requirements for award of a degree.
                        </p>
                        <ol>
                            <li>
                                If a Learner has completed one year Post
                                Graduate Diploma in Business Management and
                                wishes to apply for 2nd year in MBA course
                                respectively through the lateral entry, he/she
                                can apply for the same on the prescribed form
                                along with the prescribed fee to the Directorate
                                of Distance Education.
                            </li>
                            <li>
                                The Learner will have to deposit all his
                                relevant documents attested by a Gazetted
                                Officer (with a clear seal of the name and
                                designation) along with the application.
                            </li>
                            <li>
                                The Lateral Entry fee will be Rs.
                                1500/-additional charges of qualifying exam.
                            </li>
                            <li>The additional charges will be as follows –</li>

                            <div class="container mt-3">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Standard</th>
                                            <th>Charges</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <p>Professional PG Course</p>
                                                <p>MBA</p>
                                            </td>
                                            <td>
                                                Rs.1000/-per additional paper
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p>
                                Note-The above-mentioned fee can change as per
                                the rules of CDOE (Formerly DDE).
                            </p>
                            <li>
                                The eligibility criteria for above-mentioned
                                courses will be done and according to the
                                subject/paper matched as per the norms of the
                                University, the Learner will be given admission
                                &amp; promoted to the appropriate year.
                            </li>
                            <li>
                                If any paper or courses are mismatched and
                                candidate has not studied in PDGBM, he/she will
                                have to appear in that particular course during
                                his 2nd year MBA exam for qualifying the course.
                            </li>
                            <li>
                                The student/learner will have to give an
                                affidavit as per the prescribed format that all
                                information given by him/her are correct and all
                                documents deposited by him/her are genuine. In
                                case any information given by him/her is found
                                fake or any document submitted is found fake,
                                he/she will be liable for the appropriate
                                administrative/legal action against him/her.
                            </li>
                            <li>
                                The Directorate reserves the right to get the
                                certificate/marksheets/documents verified by the
                                issuing authority.
                            </li>
                            <li>
                                If the student/learner is admitted in the 2nd
                                year under the Lateral entry scheme, he/she will
                                not be considered for another lateral entry
                                under any circumstances.
                            </li>
                            <li>
                                The Policy of Lateral Entry will be applicable
                                for MBA courses only.
                            </li>
                            <li>
                                The student/Learner may be allowed to take
                                admission under Lateral Entry Policy depending
                                upon the eligibility from Regular to Distance
                                mode, Private to Distance mode and Distance to
                                Distance mode. However as per rules, the student
                                will not be eligible to take admission under
                                Lateral Entry from Distance to Regular mode. The
                                approval of the previous university under which
                                the student has been continuing course under
                                Regular/Distance/Private mode will be checked
                                before giving the admission.
                            </li>
                        </ol>
                        <h4>
                            General Instructions for Lateral Entry at CDOE (Formerly DDE), SVSU,
                            Meerut
                        </h4>
                        <p style="color: black">Lateral Entry</p>
                        <p>
                            “Lateral Entry” means an applicant who has passed an
                            unassembled examination consisting of an appraisal
                            of training, education, experience, work history or
                            any other means for evaluating relative
                            qualifications.
                        </p>
                        <p>
                            A student thus admitted need not write CDOE (Formerly DDE)
                            examinations for such courses which are found
                            equivalent to and for which appropriate lateral
                            entry would be deemed to have been acquired for, and
                            for purposes of fulfilling the CDOE (Formerly DDE) requirements for
                            award of a degree.
                        </p>
                        <h5>Eligibility Criteria</h5>
                        <p>
                            1 The lateral entry scheme is applicable only to
                            those candidates who have completed one year Post
                            Graduate Diploma in Business Management and wishes
                            to apply for 2nd year in MBA respectively.
                        </p>
                        <p>
                            2 Normally lateral entry will be applicable only
                            from a graduate degree to a graduate degree and post
                            graduate to post graduate degree respectively.
                        </p>
                        <h6>Instruction to be followed</h6>
                        <p>
                            1 Do not forget to enclose your marks-sheet and
                            syllabus of the courses which you have passed and
                            against which you seek lateral entry.
                        </p>
                        <p>
                            2 Lateral Entry will be permissible only in the case
                            of students coming from institutions recognised by
                            the DEB.
                        </p>
                        <p>
                            3 Lateral Entry can be done only on the basis of
                            individual courses and not on the basis of year to
                            year courses as in conventional institutions.
                        </p>
                        <p>
                            4 A demand Draft of required fee drawn in favour of
                            SVSU Distance Education” payable at Meerut with the
                            attested copies of Mark Sheet(s) and attested copies
                            of syllabi of courses covered by them to be send to
                            the following address –
                        </p>
                        <p>
                            Centre for Distance and Online Education (Formerly Directorate of Distance Education),<br />Swami
                            Vivekanand Subharti University,<br />Subhartipuram
                            NH-58 Delhi- Haridwar Bypass Road,<br />Meerut,
                            250005<br />Mob. – 7456901051, 7456901054
                            <br />Email ID : contact@subhartidde.com ,
                            coordinator@subhartidde.com<br />Website :
                            www.subhartidde.com
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
