<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>DIRECTOR'S MESSAGE</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="products-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">
                    <img
                        src="assets/img/director/Prof (Dr.) Mahavir Singh.jpeg"
                        alt="image"
                        style="width: 100%"
                    />
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="products-details-desc">
                    <h3>DIRECTOR'S MESSAGE</h3>
                    <p>
                        Stepping ahead out of all odds is what that makes a
                        diligent person. I feel delighted to see the spirit of
                        the students, who have synergized and focused to the
                        path that will lead them to their desired dream and a
                        college is a place where these students can take a right
                        turn of their precious and promising life. Globalization
                        has vanished, the barriers of culture, nationalities and
                        age groups. For a &#39;boundary- free&#39; world,
                        education is the most powerful weapon to develop a
                        country, but with the rising population and increasing
                        cost of living, it is not possible that everyone can
                        reach out to standard quality education by resolving all
                        impossibilities in the field of education. The concept
                        of open and distance learning came in to existence for
                        the same. The underlying philosophy of this concept is
                        the result of the barriers and restrictions that are
                        placed on learners as in the conventional education
                        system. It offers opportunities for part - time study
                        and learning at a distance, for innovation in the
                        curriculum. To allow access to wider section of adult
                        population, to enable students to compensate for lost
                        opportunities in the past or can say to acquire new
                        skills and qualifications for the future. It also
                        redresses social or educational inequality. It is
                        pleasures to state that Swami Vivekanand Subharti
                        University, Meerut, was established with the dream of
                        providing quality education and instilling &quot;Siksha,
                        Seva, Sanskar&quot;avem Rashtriyata&quot; in the youth.
                    </p>
                    <p>
                        Therefore to realise the dream of spreading education
                        &amp; values of &#39;Subharti&#39; to all, the
                        Centre for Distance and Online Education (Formerly Directorate of Distance Education) was established in
                        2009 with Regular Mode (2008). Learning through Distance
                        Mode proved out to be a ray of hope and opened new
                        directions of opportunities for students to obtain
                        higher education without the constraints of geographies
                        and time. Our mission is self-paced learning and to
                        provide ultimate convenience, ease and flexibility to
                        our students. We seek self-motivated individuals who
                        believe in charting their own methods of learning, who
                        take full advantage of the opportunities, and are
                        willing to contribute their best.
                    </p>
                    <p>
                        As the Director of Centre for Distance and Online Education (Formerly Directorate of Distance Education) of
                        this prestigious University, it is my accountability and
                        responsibility to reach out to maximum students with the
                        aim to increase the country GER (Gross Enrolment
                        Ratio) &amp; spread education till the smallest &amp;
                        darkest of corners. I, along with my team, have pledge
                        to set new standards for education to spread the values
                        of the founders of &#39;Subharti&#39; far and wide and
                        to contribute in building up of a strongest nation.
                    </p>
                    <p>
                        &quot;I welcome each one of you to be a part of the
                        &#39;Subharti University&#39; where Subharti University
                        programmes have been designed with the objective of
                        giving a perfect boost to the career of learners and
                        also to help them in using their full potential for
                        elevation, by bringing together innovation pedagogy,
                        rich content and latest technology, Subharti University
                        programmes are as per the University Grants Commission
                        (UGC), entitled courses are meant for anytime, anywhere
                        learning with Innovative teaching methodologies and
                        quality education are blended together to create unique
                        learning experiences.&quot;
                    </p>
                    <p>
                        The well-researched curriculum, renowned faculty,
                        cutting edge technology is some of the noteworthy
                        features.
                    </p>
                    <h3>Miles to go before I sleep</h3>
                    <p style="float: end; color: black; font-weight: bold">
                        Prof. (Dr.) Mahavir Singh<br />
                        Director<br />
                        Centre for Distance and Online Education (Formerly Directorate of Distance Education) (CDOE (Formerly DDE))
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
