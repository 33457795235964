<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>NEW SYLLABUS SEMESTER PROGRAMME (2020-21)</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-area ptb-100 bg-f5f7fa">
    <div class="container">
        <div class="row">
            <!-- <div class="col-12 text-center">
                 <h3>NEW SYLLABUS SEMESTER PROGRAMME (2020-21)</h3>
             </div>-->
        </div>
        <div class="row">
            <div class="about-text-container">
                <h4 style="background: #29176d;color: white;padding: 21px; margin-bottom:15px">New Syllabus Semester
                    Programme (2020-21)</h4>
                <div class="rightcategory_box">
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/BA.pdf"
                                   style="font-size:18px; font-weight:400">BA </a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/B.COM(H).pdf"
                                   style="font-size:18px; font-weight:400">B.COM
                                    (H)</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/B.COM.pdf"
                                   style="font-size:18px; font-weight:400">B.COM</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/BBA.pdf"
                                   style="font-size:18px; font-weight:400">BBA </a>
                            </h6>

                        </div>
                    </div>

                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/BLIB.pdf"
                                   style="font-size:18px; font-weight:400">BLIB</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/M.COM.pdf"
                                   style="font-size:18px; font-weight:400">M.COM</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MA-EDUCATION.pdf"
                                   style="font-size:18px; font-weight:400">MA-EDUCATION</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MA-ENGLISH.pdf"
                                   style="font-size:18px; font-weight:400">MA-ENGLISH</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MA-HINDI.pdf"
                                   style="font-size:18px; font-weight:400">MA-HINDI</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MA-HISTORY.pdf"
                                   style="font-size:18px; font-weight:400">MA-HISTORY</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MA-JMC.pdf"
                                   style="font-size:18px; font-weight:400">MA-JMC</a>
                            </h6>
                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MA-POLITICAL SCIENCE.pdf"
                                   style="font-size:18px; font-weight:400">MA-POLITICAL SCIENCE</a>
                            </h6>
                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MA-PUBLICA%20ADMINISTRATION.pdf"
                                   style="font-size:18px; font-weight:400">MA-PUBLICA ADMINISTRATION</a>
                            </h6>
                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MA-SOCIOLOGY.pdf"
                                   style="font-size:18px; font-weight:400">MA-SOCIOLOGY</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MA%20in%20Mathematics.pdf"
                                   style="font-size:18px; font-weight:400">MA - MATHEMATICS</a>
                            </h6>
                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MA%20in%20Buddhist.pdf"
                                   style="font-size:18px; font-weight:400">MA - BUDDHIST</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MA%20in%20Home%20Science.pdf"
                                   style="font-size:18px; font-weight:400">MA - HOME SCIENCE</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MBA.pdf"
                                   style="font-size:18px; font-weight:400">MBA</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MLIB.pdf"
                                   style="font-size:18px; font-weight:400">MLIB</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MA%20in%20Economics.pdf"
                                   style="font-size:18px; font-weight:400">M.A - ECONOMICS</a>
                            </h6>
                        </div>
                    </div>
                <!--    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/ADBA.pdf"
                                   style="font-size:18px; font-weight:400">ADBA</a>
                            </h6>
                        </div>
                    </div>-->
                 <!--   <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/ADCA.pdf"
                                   style="font-size:18px; font-weight:400">ADCA</a>
                            </h6>

                        </div>
                    </div>-->
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/BA%20_Fashion%20Design_.pdf"
                                   style="font-size:18px; font-weight:400">BA - FASHION DESIGN</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/BA%20_Fashion%20Design_.pdf"
                                   style="font-size:18px; font-weight:400">BA - FINE ARTS</a>
                            </h6>

                        </div>
                    </div>
                  <!--  <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/BCA.pdf"
                                   style="font-size:18px; font-weight:400">BCA</a>
                            </h6>

                        </div>
                    </div>-->
                  <!--  <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/DBA.pdf"
                                   style="font-size:18px; font-weight:400">DBA</a>
                            </h6>

                        </div>
                    </div>-->
                   <!-- <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/DCA.pdf"
                                   style="font-size:18px; font-weight:400">DCA</a>
                            </h6>

                        </div>
                    </div>-->
                    <!-- <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/M.Sc(CA).pdf"
                                   style="font-size:18px; font-weight:400">M.Sc(CA)</a>
                            </h6>

                        </div>
                    </div> -->
             <!--       <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/M.Sc(DFSM).pdf"
                                   style="font-size:18px; font-weight:400">M.Sc (DFSM)</a>
                            </h6>

                        </div>
                    </div>-->
                   <!-- <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MCA.pdf"
                                   style="font-size:18px; font-weight:400">MCA</a>
                            </h6>

                        </div>
                    </div>-->
                    <!-- <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/Post%20Graduate%20Diploma%20in%20Dietetics%20&%20Public%20Nutrition.pdf"
                                   style="font-size:18px; font-weight:400">Post Graduate Diploma in Dietetics &amp;
                                    Public Nutrition</a>
                            </h6>

                        </div>
                    </div>

                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/Post%20Graduate%20Diploma%20in%20Food%20Safety%20&%20Quality%20Management.pdf"
                                   style="font-size:18px; font-weight:400">Post Graduate Diploma in Food Safety &amp;
                                    Quality Management</a>
                            </h6>
                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/Post%20Graduate%20Diploma%20in%20Hospital%20&%20Health%20Management.pdf"
                                   style="font-size:18px; font-weight:400">Post Graduate Diploma in Hospital &amp;
                                    Health Management</a>
                            </h6>
                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/Post%20Graduate%20Diploma%20in%20Intellectual%20Property%20Rights.pdf"
                                   style="font-size:18px; font-weight:400">Post Graduate Diploma in Intellectual
                                    Property Rights</a>
                            </h6>

                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/Post%20Graduate%20Diploma%20in%20Maternal%20&%20Child%20Health.pdf"
                                   style="font-size:18px; font-weight:400">Post Graduate Diploma in Maternal &amp; Child
                                    Health</a>
                            </h6>
                        </div>
                    </div>
                    <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/Post%20Graduate%20Diploma%20in%20Patent%20Practice.pdf"
                                   style="font-size:18px; font-weight:400">Post Graduate Diploma in Patent Practice</a>
                            </h6>

                        </div>
                    </div> -->

                    <!-- <div class="blog_box">
                        <div class="blog_heading">
                            <h6 class="download_text" style="background:#CCCCCC; padding:8px; margin-bottom:10px">
                                <a href="assets/documents/Syllabus/MBA-HOSPITAL%20ADMINISTRATION.pdf"
                                   style="font-size:18px; font-weight:400">MBA-HOSPITAL ADMINISTRATION</a>
                            </h6>

                        </div>
                    </div> -->
                    <br class="clear">
                </div>
            </div>
        </div>
    </div>
</div>
