<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>For Session 2021-22</h2>
        </div>
        <div class="shape9">
            <img src="assets/img/shape8.svg" alt="image" />
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-text-container">
                    <!--<h4 style="background: #29176d;color: white;padding: 21px; margin-bottom:15px">Fee Structure (Applicable from Academic Session 2022-23 onwards)</h4>-->
                    <div class="about-text-container">
                        <div
                            class="accor_heading downToggle"
                            style="overflow-x: auto"
                        >
                            <!--<table cellspacing="0" cellpadding="0" style="border: 1px solid black;">-->
                            <table class="table table-striped">
                                <colgroup>
                                    <col width="49" />
                                    <col width="54" />
                                    <col width="405" />
                                    <col width="310" />
                                </colgroup>
                                <thead style="border: 1px solid black">
                                    <tr height="20" style="background: #29176d">
                                        <td rowspan="2" height="40" width="64">
                                            <a name="RANGE!A1:E35"
                                                ><strong style="color: #ffffff"
                                                    >S. No.</strong
                                                ></a
                                            >
                                        </td>
                                        <td rowspan="2" width="77">
                                            <strong style="color: #ffffff"
                                                >LEVEL</strong
                                            >
                                        </td>
                                        <td rowspan="2" width="281">
                                            <strong style="color: #ffffff"
                                                >PROGRAMME</strong
                                            >
                                        </td>
                                        <td rowspan="2" width="284">
                                            <strong style="color: #ffffff"
                                                >STREAM</strong
                                            >
                                        </td>
                                        <td rowspan="2" width="124">
                                            <strong style="color: #ffffff"
                                                >Semester wise Fee
                                                Structure</strong
                                            >
                                        </td>
                                    </tr>
                                </thead>
                                <tbody style="border: 1px solid black">
                                    <tr height="20"></tr>
                                    <tr height="38">
                                        <td rowspan="8" height="178" width="64">
                                            1
                                        </td>
                                        <td rowspan="8" width="77">UG</td>
                                        <td rowspan="8" width="281">
                                            BACHELOR OF ARTS
                                        </td>
                                        <td width="284">
                                            GENERAL(Combination of any 3
                                            Subjects)
                                        </td>
                                        <td rowspan="8">2000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="284">
                                            1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            HINDI
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="284">
                                            2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            ENGLISH
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="284">
                                            3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            POLITICAL SCIENCE
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="284">
                                            4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            HISTORY
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="284">
                                            5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            ECONOMICS
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="284">
                                            6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            SOCIOLOGY
                                        </td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="284">
                                            7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            MATHEMATICS
                                        </td>
                                    </tr>
                                    <tr height="39">
                                        <td height="39" width="64">2</td>
                                        <td width="77">UG</td>
                                        <td width="281">
                                            BACHELOR OF LIBRARY AND INFORMATION
                                            SCIENCES
                                        </td>
                                        <td width="284">
                                            LIBRARY AND INFORMATION SCIENCES
                                        </td>
                                        <td>7000</td>
                                    </tr>
                                    <tr height="38">
                                        <td height="38" width="64">3</td>
                                        <td width="77">UG</td>
                                        <td width="281">
                                            BACHELOR OF BUSINESS ADMINISTRATION
                                        </td>
                                        <td width="284">MANAGEMENT</td>
                                        <td>6000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="64">4</td>
                                        <td width="77">UG&nbsp;</td>
                                        <td width="281">
                                            BACHELOR OF COMMERCE
                                        </td>
                                        <td width="284">MANAGEMENT</td>
                                        <td>3500</td>
                                    </tr>
                                    <tr height="38">
                                        <td height="38" width="64">5</td>
                                        <td width="77">UG&nbsp;</td>
                                        <td width="281">
                                            BACHELOR OF COMMERCE (HONORS)
                                        </td>
                                        <td width="284">MANAGEMENT</td>
                                        <td>5000</td>
                                    </tr>
                                    <tr height="39">
                                        <td height="39" width="64">6</td>
                                        <td width="77">UG</td>
                                        <td width="281">BACHELOR OF ARTS</td>
                                        <td width="284">
                                            JOURNALISM AND MASS COMMUNICATION
                                        </td>
                                        <td>6000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="64">7</td>
                                        <td width="77">PG</td>
                                        <td width="281">MASTER OF ARTS</td>
                                        <td width="284">BUDDHIST STUDIES</td>
                                        <td>6500</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="64">8</td>
                                        <td width="77">PG</td>
                                        <td width="281">MASTER OF ARTS</td>
                                        <td width="284">EDUCATION</td>
                                        <td>7200</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="64">9</td>
                                        <td width="77">PG</td>
                                        <td width="281">MASTER OF ARTS</td>
                                        <td width="284">
                                            PUBLIC ADMINISTRATION
                                        </td>
                                        <td>5000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="64">10</td>
                                        <td width="77">PG</td>
                                        <td width="281">MASTER OF ARTS</td>
                                        <td width="284">HOME SCIENCE</td>
                                        <td>5000</td>
                                    </tr>
                                    <tr height="39">
                                        <td height="39" width="64">11</td>
                                        <td width="77">PG</td>
                                        <td width="281">
                                            MASTER OF LIBRARY AND INFORMATION
                                            SCIENCES
                                        </td>
                                        <td width="284">
                                            LIBRARY AND INFORMATION SCIENCES
                                        </td>
                                        <td>7000</td>
                                    </tr>
                                    <tr height="38">
                                        <td height="20" width="64">12</td>
                                        <td width="77">PG</td>
                                        <td width="281">
                                            MASTER OF BUSINESS ADMINISTRATION
                                        </td>
                                        <td width="284">
                                            GENERAL (Combination of any 2
                                            Subjects)
                                        </td>
                                        <td>10000</td>
                                    </tr>

                                    <tr height="20">
                                        <td height="20" width="64">13</td>
                                        <td width="77">PG</td>
                                        <td width="281">MASTER OF COMMERCE</td>
                                        <td width="284">MANAGEMENT</td>
                                        <td>5000</td>
                                    </tr>
                                    <tr height="39">
                                        <td height="39" width="64">14</td>
                                        <td width="77">PG</td>
                                        <td width="281">MASTER OF ARTS</td>
                                        <td width="284">
                                            JOURNALISM AND MASS COMMUNICATION
                                        </td>
                                        <td>7000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="64">15</td>
                                        <td width="77">PG</td>
                                        <td width="281">MASTER OF ARTS</td>
                                        <td width="284">POLITICAL SCIENCES</td>
                                        <td>5000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="64">16</td>
                                        <td width="77">PG</td>
                                        <td width="281">MASTER OF ARTS</td>
                                        <td width="284">MATHEMATICS</td>
                                        <td>5000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="64">17</td>
                                        <td width="77">PG</td>
                                        <td width="281">MASTER OF ARTS</td>
                                        <td width="284">SOCIOLOGY</td>
                                        <td>5000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="64">18</td>
                                        <td width="77">PG</td>
                                        <td width="281">MASTER OF ARTS</td>
                                        <td width="284">HISTORY</td>
                                        <td>5000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="64">19</td>
                                        <td width="77">PG</td>
                                        <td width="281">MASTER OF ARTS</td>
                                        <td width="284">HINDI</td>
                                        <td>5000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="64">20</td>
                                        <td width="77">PG</td>
                                        <td width="281">MASTER OF ARTS</td>
                                        <td width="284">ECONOMICS</td>
                                        <td>5000</td>
                                    </tr>
                                    <tr height="20">
                                        <td height="20" width="64">21</td>
                                        <td width="77">PG</td>
                                        <td width="281">MASTER OF ARTS</td>
                                        <td width="284">ENGLISH</td>
                                        <td>5000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br />
                        <br />
                        <div>
                            <p>
                                <b>Other Fees: </b>
                            </p>
                            <p>
                                <strong
                                    >In addition to the course fee, the learner
                                    will be required to deposit the following
                                    fee at appropriate</strong
                                >
                            </p>
                            <br />
                            <!-- <p>One-time Registration fee: Rs. 1500 (Non refundable &amp; Non Transferable)</p> -->
                            <p></p>
                            Examination Fee : Rs. 1500 per Annum
                            <p></p>
                            <p>Reappear Paper Fee : Rs. 250 per paper</p>
                            <p>Credit Transfer Fee : Rs. 1500</p>
                            <p>Continuation Fee : Rs. 2000</p>
                            <p></p>
                            Re-Totaling Fee : Rs. 200 per paper
                            <p></p>
                            <p>Change of Address Fee : Rs. 500</p>
                            <p>Change of Specialization Fee : Rs. 500</p>
                            <p></p>
                            Certificate/ Diploma/ Provisional Degree Fee : Rs.
                            500
                            <p></p>
                            <p>Consolidated Marksheet Fee : Rs. 2000</p>
                            <p></p>
                            Duplicate Marksheet Fee : Rs. 500
                            <p></p>
                            <p>Final Degree Fee : Rs. 3000</p>
                            <p>Confidential Result Fee : Rs. 1000</p>
                            <p>Final Diploma Fee : Rs. 2000</p>
                            <p></p>
                            Document Verification Fee : Rs. 1500 (Per Document
                            for non Gov. Organizations)
                            <p></p>
                            <p>Transcript Fee : Rs. 1000 for each</p>
                            <p></p>
                            Degree Correction : Rs. 500
                            <p></p>
                            <p></p>
                            Marksheet Correction Fee : Rs. 500
                            <p></p>
                            <p></p>
                            <h3>Note : Fee refund policy as per UGC norms</h3>
                            <p><strong>No any other hidden charges</strong></p>
                        </div>
                        <div style="padding-top: 20px">
                            Student can submit his fee in cash to cash counter
                            in department or submit online<br />
                            <b
                                >For online payment, Account Details are as
                                follow :</b
                            >
                            <br /><br />
                            <table
                                style="width: 100%"
                                cellpadding="0"
                                cellspacing="0"
                                border="1"
                            >
                                <tbody>
                                    <tr>
                                        <td>
                                            <b>Account Holder Name:</b> Swami
                                            Vivekanand Subharti University
                                            Distance Education
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="height: 24px">
                                            <b>Account Number:</b> 628501043170
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>IFSC Code:</b> ICIC0004078</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <b>Branch:</b> 65 Shiv Puri Hapur
                                            Road Meerut
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <table
                                style="width: 100%"
                                cellpadding="0"
                                cellspacing="0"
                                border="1"
                            >
                                <tbody>
                                    <tr>
                                        <td>
                                            <b>Account Holder Name:</b> S
                                            .V.S.U. ( D.D.E)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="height: 24px">
                                            <b>Account Number:</b>
                                            52282191015961
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>IFSC Code:</b> PUNB0522810</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <b>Branch:</b> SUBHARTIPURAM, DELHI
                                            HARIDWAR BYE-PASS, MEERUT
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
