<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>LIST OF COURSE CO-ORDINATORS</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="container mt-3">
    <h4 style="text-align: center;">List of Course Co-ordinators</h4>

    <div class="accor_heading downToggle" style="overflow-x:auto;">
        <table class="table  table-striped" style="border: 1px solid black;">
            <thead>
            <tr style="background-color: #29166d;">
                <td>
                    <p style="color: white">
                        <strong>S.No.</strong>
                    </p>
                </td>
                <td>
                    <p style="color: white">
                        <strong>Name of Programme</strong>
                    </p>
                </td>
                <td>
                    <p style="color: white">
                        <strong>Faculty Name </strong>
                    </p>
                </td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <p>
                        1
                    </p>
                </td>
                <td>
                    <p>
                        Post Graduate Diploma in Intellectual Property Rights
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Dr. Vaibhav Goel </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        2
                    </p>
                </td>
                <td>
                    <p>
                        Post Graduate Diploma in Dietetics &amp; Public Nutrition
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Ms. Priyanka Sharma </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        3
                    </p>
                </td>
                <td>
                    <p>
                        Post Graduate Diploma in Hospital &amp; Health Management
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Dr. Smita Chandna</strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        4
                    </p>
                </td>
                <td>
                    <p>
                        Post Graduate Diploma in Maternal and Child Health
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Dr. Mukti Bhatnagar </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        5
                    </p>
                </td>
                <td>
                    <p>
                        Post Graduate Diploma in Food Safety &amp; Quality Management
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Dr. Sunayan</strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        6
                    </p>
                </td>
                <td>
                    <p>
                        Post Graduate Certificate in Patent Practice
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Dr. Manoj Tripathi </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        7
                    </p>
                </td>
                <td>
                    <p>
                        Bachelor of Arts in Journalism &amp; Mass Communications
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Mr. Rajesh Kumar </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        8
                    </p>
                </td>
                <td>
                    <p>
                        Bachelor of Arts
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Mr. Neeraj Kumar </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        9
                    </p>
                </td>
                <td>
                    <p>
                        Bachelor of Commerce
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Ms. Padma Misra</strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        10
                    </p>
                </td>
                <td>
                    <p>
                        Diploma in Business Administration
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Ms. Malika Paul</strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        11
                    </p>
                </td>
                <td>
                    <p>
                        Advance Diploma in Business Administration
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Ms. Mireet Fatima Mir </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        12
                    </p>
                </td>
                <td>
                    <p>
                        Bachelors in Business Administration
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Mr. Rahul Sharma </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        13
                    </p>
                </td>
                <td>
                    <p>
                        B. Lib.
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Dr. Javed </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        14
                    </p>
                </td>
                <td>
                    <p>
                        M. Lib.
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Dr. Sudhir Tyagi </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        15
                    </p>
                </td>
                <td>
                    <p>
                        Master of Arts in English/Hindi/Sociology/Political Science/History/Public Admn.
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Dr. Suraj Bhan </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        16
                    </p>
                </td>
                <td>
                    <p>
                        Master of Arts in Journalism &amp; Mass Communications
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Dr. Sailesh Kumar Pandey </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        17
                    </p>
                </td>
                <td>
                    <p>
                        Master’s in Commerce (M.COM.)
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Ms. Shehnila Athar </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        18
                    </p>
                </td>
                <td>
                    <p>
                        Master in Business Administration
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Dr. Prabhat Kumar </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        19
                    </p>
                </td>
                <td>
                    <p>
                        PG Diploma in Computer Applications
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Er. Amit Kumar </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        20
                    </p>
                </td>
                <td>
                    <p>
                        M.Sc. Dietetics &amp; Food Service Management
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Dr. Ankita Tyagi </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        21
                    </p>
                </td>
                <td>
                    <p>
                        Diploma in Computer Applications
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Mr. Sanjeev Panwar </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        22
                    </p>
                </td>
                <td>
                    <p>
                        Advance Diploma in Computer Applications
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Ms. Samridhi Sharma </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        23
                    </p>
                </td>
                <td>
                    <p>
                        Bachelor in Computer Applications
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Mr. Shashiraj Teotia </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        24
                    </p>
                </td>
                <td>
                    <p>
                        Bachelor of Science (PCM)
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Mr. Rishabh Raj </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        25
                    </p>
                </td>
                <td>
                    <p>
                        Bachelor of Science (ZBC)
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Mr. Aditya Kumar Sharma </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        26
                    </p>
                </td>
                <td>
                    <p>
                        M.Sc. (Computer Applications)
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Mr. Nirankar Sharma </strong>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        27
                    </p>
                </td>
                <td>
                    <p>
                        Master in Computer Applications
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Dr. Rajesh Kumar Bhagat </strong>
                    </p>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
