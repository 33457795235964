<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Master of Commerce (M.Com)</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Introduction</h3>
                        </div>
                        <p>
                            The Master of Commerce (M.Com) program is a two-year
                            program focused on business and financial
                            innovation. The focus of this curriculum is on
                            preparing students for responsible positions in the
                            business and financial sectors. M.Com degree opens
                            doors for graduates in the fields of mutual fund
                            brokerage, financial bookkeeping, budget analysis,
                            and management consulting.
                        </p>
                        <h4>Objective</h4>
                        <p>
                            The Master of Commerce degree is highly sought after
                            by students in India. Students can tailor their
                            M.Com studies to their own schedules, interests, and
                            skill sets by choosing from a variety of specialties
                            and course options. The M.Com degree is a
                            well-regarded program that helps students get ready
                            for successful professions in economics and
                            commerce.
                        </p>
                        <p>
                            The field of M.Com is quite varied, and there is a
                            sea of possibilities made possible by the rapid
                            development of new technologies. M.Com grads are in
                            high demand for professions that require significant
                            responsibility, such as financial analyst and
                            supervisor, in today's era of startups and the rise
                            of entrepreneurship.
                        </p>
                        <p>
                            M.Com graduates who are able to secure internships
                            at industry leaders like Infosys and Dell will have
                            a much more impressive portfolio of work experience
                            to highlight upon graduation. The primary function
                            of investment bankers is to help their clients
                            access the capital market to issue debt or sell
                            shares of stock in order to expand finance. Finance
                            grads can find work in a variety of fields,
                            including consulting and advisory services,
                            bookkeeping and business analysis, stock broking,
                            and more.
                        </p>
                        <p>
                            The Master of Commerce degree program is narrow in
                            scope. Candidates for the M.Com can contend with MBA
                            graduates thanks to the program's emphasis on
                            disciplines including Management Theory,
                            Institutional Management, and Governance Model.
                            Compared to a CA program, an M.Com course is
                            substantially shorter and has a similar length to an
                            MBA program.
                        </p>

                        <h5>Programme Duration</h5>
                        <p>
                            Master of Commerce is a post-graduate degree program
                            and this programs are typically two years course.
                        </p>
                        <p>
                            Minimum Duration 2 Years<br />Maximum Duration 4
                            Year
                        </p>
                        <h6>Eligibility & Fee</h6>
                        <p>
                            Online/distance M. Com eligibility criteria are too
                            very flexible. To pursue an online and distance
                            Master of Commerce in Education one has to be
                            completed graduation from any recognised university.
                            There are no entrance examinations for admission in
                            this course.
                        </p>
                        <table class="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Programme fee</th>
                                    <th>Examination fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Rs. 12000 Per Year</td>
                                    <td>Rs. 2000 Per Year</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6 style="font-weight: bold">Payment Mode</h6>
                        <P
                            >Student can submit his fee in cash to cash counter
                            in department or submit online</P
                        >
                        <p>
                            <strong
                                >For online payment, Account Details are as
                                follow :</strong
                            >
                        </p>
                        <table
                            style="width: 100%"
                            cellpadding="0"
                            cellspacing="0"
                            border="1"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Account Holder Name:</b> Swami
                                        Vivekanand Subharti University Distance
                                        Education
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height: 24px">
                                        <b>Account Number:</b> 628501043170
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>IFSC Code:</b> ICIC0006285</td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>Branch:</b> 65 Shiv Puri Hapur Road
                                        Meerut
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                        <h6>Career Prospects</h6>
                        <p>
                            After post-graduation student has various options
                            for employment, both in the government and private
                            sectors.
                        </p>
                        <h6 style="font-weight: bold">
                            There are many designations for M. Com students:
                        </h6>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Banking
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Finance
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Accountant
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Account, Auditing, Tax
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Financial Analyst
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Taxation Officer
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Finance Officer
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Financial Advisor
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Taxation Officer
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Tax Consultant
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Investment Analyst
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Insurance Agent
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Stock Broker
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Cost & Management Accountant
                            </li>
                        </ul>
                        <h5 style="font-weight: bold">
                            Best study options after M. Com in Education
                            <hr />
                        </h5>
                        <div class="container p-4" style="text-align: justify">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h6 style="font-weight: bold">
                                        Graduates who are enthusiastic about
                                        continuing their studies in the field of
                                        education and have already earned their
                                        M. Com degree can pursue-
                                    </h6>
                                    <!--<p>There are many designations for Graduates are:</p>-->
                                    <ul>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >M.Phil.
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Ph.D.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</div>
