<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Master of Arts in Public Administration</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!--<div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Introduction</h3>
                        </div>
                        <p>
                            The Master of Arts in Public Administration is a
                            two-year program where students learn about the
                            inner workings of government institutions and the
                            myriad social and political problems that plague our
                            modern world.
                        </p>
                        <p>
                            Those who get a Master of Arts in Public
                            Administration will find a wide variety of
                            employment possibilities open to them upon
                            graduation. Jobs in administration, management
                            analysis, teaching, consulting, customs inspection,
                            labor-management relations, human resources, social
                            work, etc., are always in demand.
                        </p>
                        <h4>Objective</h4>
                        <p>
                            A career in public administration may be both
                            stimulating and demanding. This is a fruitful career
                            path for morally-minded individuals. Those who are
                            serious about creating a positive impact for the
                            greater good of society can choose this course to
                            pursue their interests.
                        </p>
                        <p>
                            If you get MA in Public Administration degree, you
                            will be qualified to work for a government agency as
                            a consultant in public administration, or you will
                            be able to work your way up to the position of the
                            city manager. Those with a value-sensitive,
                            research-oriented, problem-solving mindset,
                            sensitivity to nuanced ethical issues, and a genuine
                            concern for the common good would do well in this
                            field.
                        </p>
                        <p>
                            One must have or develop abilities in communication,
                            leadership, research, and problem-solving to succeed
                            in public administration. After finishing a master's
                            program in public administration, students are
                            eligible to sit for the UGC-NET or JRF Exam. The
                            biggest opportunity in public administration is the
                            possibility of taking and passing the Indian Civil
                            Services Exam.
                        </p>
                        <h5>Programme Duration</h5>
                        <p>
                            MA in Public Administration is a post-graduate
                            degree program and this programs are typically two
                            years course.
                        </p>
                        <p>
                            Minimum Duration 2 Years<br />Maximum Duration 4
                            Year
                        </p>
                        <h6>Eligibility & Fee</h6>
                        <p>
                            Online/distance MA in Public Administration
                            eligibility criteria are too very flexible. To
                            pursue an online and distance MA in Public
                            Administration one has to be completed graduation
                            from any recognised university. There are no
                            entrance examinations for admission in this course.
                        </p>
                        <table class="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Programme fee</th>
                                    <th>Examination fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Rs. 12000 Per Year</td>
                                    <td>Rs. 2000 Per Year</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6 style="font-weight: bold">Payment Mode</h6>
                        <p>
                            Student can submit his fee in cash to cash counter
                            in department or submit online
                            <b
                                >For online payment, Account Details are as
                                follow :</b
                            >
                        </p>
                        <table
                            style="width: 100%"
                            cellpadding="0"
                            cellspacing="0"
                            border="1"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Account Holder Name:</b> Swami
                                        Vivekanand Subharti University Distance
                                        Education
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height: 24px">
                                        <b>Account Number:</b> 628501043170
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>IFSC Code:</b> ICIC0006285</td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>Branch:</b> 65 Shiv Puri Hapur Road
                                        Meerut
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h6><strong>Career Prospects</strong></h6>
                        <p>
                            After post-graduation student has various options
                            for employment, both in the government and private
                            sectors.
                        </p>
                        <p>There are many designations for Graduates are:</p>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Business Analyst
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Administrative Officer
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Consultant
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Management Analyst
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Teacher
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Customs Inspector
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Labor-management Relations Specialist
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Corporate Manage
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Personnel Management Specialist
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Corporate Management
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Economic Development
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Indian Civil Services
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Public Works
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Land Revenue Systems
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Panchayati Ra
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Client Services Specialist
                            </li>
                        </ul>
                        <h6>
                            Best study options after MA in Public Administration
                        </h6>
                        <p>
                            Graduates who are enthusiastic about continuing
                            their studies in the field of education and have
                            already earned their MA in Public Administration
                            degree can pursue-
                        </p>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >M.Phil.
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Ph.D.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</div>
