import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mba-rural-development',
  templateUrl: './mba-rural-development.component.html',
  styleUrls: ['./mba-rural-development.component.scss']
})
export class MbaRuralDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
