<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Master of Arts in Education</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!--<div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Introduction</h3>
                        </div>
                        <p>
                            The Master of Arts in Education is a two-year or
                            four-semester post-graduate course in education.
                            Students will leave with an understanding of the
                            fundamentals of education theory and a working
                            familiarity with the most up-to-date research
                            techniques in the field of education.
                        </p>
                        <h4>Objective</h4>
                        <p>
                            The primary focus of a MA Education program is on
                            preparing students for careers in education through
                            a deep immersion in pedagogy, teaching strategies,
                            and educational technology. While pursuing their
                            education, students will have the unique chance to
                            teach in a classroom setting, guided by the
                            mentorship of a professional educator. That way, by
                            the time they graduate, they'll have a firm grasp of
                            what it takes to enter the teaching profession.
                        </p>
                        <p>
                            To improve the quality of education, schools require
                            highly trained, expert teachers, and a graduate
                            degree indicates that the applicants holding this
                            degree are valued, competent educators who will have
                            a beneficial impact on youth.
                        </p>
                        <p>
                            Obtaining a MA Education degree is also a valuable
                            networking tool. When you're in graduate school,
                            you'll meet a lot of people who are already at the
                            top of their field; they'll have lots of suggestions
                            to provide and may even be able to help you get some
                            great jobs.
                        </p>
                        <p>
                            The MA Education program was created in response to
                            a growing need for qualified professionals in the
                            areas of K-12 and higher education instruction, as
                            well as educational leadership and management.
                            Graduates who are enthusiastic about continuing
                            their studies in the field of education and have
                            already earned their MA degree can pursue an M.Phil.
                            or Ph.D.
                        </p>
                        <!--     <h5>Programme Duration</h5>
                             <p>Bachelor of Arts in Journalism and Mass Communication (BA-JMC) is an undergraduate degree
                                 program and BA-JMC programs are typically three years course.</p>
                             <p>Minimum Duration 3 Years<br>Maximum Duration 6 Year</p>
                             <h6>Eligibility & Fee</h6>
                             <p>Online/distance Bachelor of Arts in Journalism and Mass Communication (BA-JMC) eligibility
                                 criteria are too very flexible. To pursue an online and distance BA-JMC one has to be 10+2
                                 pass out from any recognised university. There are no entrance examinations for admission in
                                 online and distance BA-JMC courses.</p>
                             <table class="table table-success  table-striped">
                                 <thead>
                                 <tr>
                                     <th>S.No</th>
                                     <th>Programme fee</th>
                                     <th>Examination fee</th>
                                 </tr>
                                 </thead>
                                 <tbody>
                                 <tr>
                                     <td>1.</td>
                                     <td>Rs. 12000 Per Year</td>
                                     <td>Rs. 3000 Per Year</td>
                                 </tr>
                                 </tbody>
                             </table>

                             <h6 style="font-weight: bold;">Payment Mode</h6>
                             <p>Student can submit his fee in cash to cash counter in department or submit online
                                 <b>For online payment, Account Details are as follow :</b></p>
                             <table style="width:100%;" cellpadding="0" cellspacing="0" border="1">
                                 <tbody>
                                 <tr>
                                     <td>
                                         <b>Account Holder Name:</b> Swami Vivekanand Subharti University Distance
                                         Education
                                     </td>
                                 </tr>
                                 <tr>
                                     <td style="height: 24px">
                                         <b>Account Number:</b> 628501043170
                                     </td>
                                 </tr>
                                 <tr>
                                     <td>
                                         <b>IFSC Code:</b> ICIC0006285
                                     </td>
                                 </tr>

                                 <tr>
                                     <td>
                                         <b>Branch:</b> 65 Shiv Puri Hapur Road Meerut
                                     </td>
                                 </tr>
                                 </tbody>
                             </table>
                             <br>
                             <h6><strong>Career Prospects</strong></h6>
                             <p>After college, a BA-JMC graduate student has various options for employment, both in the
                                 government and private sectors. </p>
                             <h6 style="font-weight: bold;">Government Sector-</h6>
                             <p>There are many designations for BA-JMC Graduates are:</p>
                             <ul>
                                 <li><i
                                     class='bx bx-chevron-right' style="color: #29176d;"></i>AIR - All India Radio
                                 </li>
                                 <li><i
                                     class='bx bx-chevron-right' style="color: #29176d;"></i>Gov. Printing Press
                                 </li>
                                 <li><i
                                     class='bx bx-chevron-right' style="color: #29176d;"></i>Circulation and Public Relations
                                 </li>
                                 <li><i
                                     class='bx bx-chevron-right' style="color: #29176d;"></i>Central Information Service
                                 </li>
                                 <li><i
                                     class='bx bx-chevron-right' style="color: #29176d;"></i>Legal Affairs Department
                                 </li>
                                 <li><i
                                     class='bx bx-chevron-right' style="color: #29176d;"></i>Radio Journalists
                                 </li>
                                 <li><i
                                     class='bx bx-chevron-right' style="color: #29176d;"></i>Advertising Agencies
                                 </li>
                                 <li><i
                                     class='bx bx-chevron-right' style="color: #29176d;"></i>Administrators
                                 </li>
                                 <li><i
                                     class='bx bx-chevron-right' style="color: #29176d;"></i>Public Relations Officers
                                 </li>
                             </ul>
                             <h5 style="font-weight: bold;">Private Sector-
                                 <hr>
                             </h5>
                             <div class="container p-4" style="text-align: justify;">
                                 <div class="row">
                                     <div class="col-lg-6">
                                         <h6 style="font-weight: bold;">There are many designations for BA-JMC Graduates
                                             are:</h6>
                                         &lt;!&ndash;<p>There are many designations for Graduates are:</p>&ndash;&gt;
                                         <ul>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Journalists
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Broadcast and Sound
                                                 Technicians
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Chief reporter and
                                                 special representative
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Cinema Projectionist
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Directors
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Space Selling
                                                 Executives
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Sub editor
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>TV News Reporters
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Advertising
                                                 Executives
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Content Writer cum
                                                 Editor
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Newspaper Reporters
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Television Producers
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Trainee Journalists
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Web Journalists
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Free Lance Writers
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Newspapers
                                             </li>
                                         </ul>
                                     </div>
                                     <div class="col-lg-6">
                                         <h6 style="font-weight: bold;">Best study options after BA-JMC</h6>
                                         <ul>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Arts in
                                                 Media and Communication
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Arts in
                                                 Public Relations
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Philosophy
                                                 in Journalism and Mass Communication
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Science in
                                                 Mass Communication
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Arts in
                                                 Convergent Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Arts in
                                                 Photo Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Arts in
                                                 Mass Communication and Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Arts in
                                                 Mass Communication
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Science in
                                                 Mass Communication and Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master in Mass
                                                 Communication, Advertising and Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Arts in
                                                 Hindi and Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Arts in
                                                 Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Arts in
                                                 Broadcast Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>MJMC (Journalism and
                                                 Mass Communication)
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Science in
                                                 Mass Communication, Advertising and Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master in
                                                 Development Journalism and Mass Communication
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Master of Journalism
                                                 and Mass Communication
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Doctor of Philosophy
                                                 in Journalism and Mass Communication
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Post Graduate
                                                 Diploma in Journalism and Mass Communication
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Post Graduate
                                                 Diploma in Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Post Graduate
                                                 Diploma in Defense Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Post Graduate
                                                 Diploma in Corporate Communication
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Post Graduate
                                                 Diploma in Print Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Post Graduate
                                                 Diploma in Science and Technology Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Post Graduate
                                                 Diploma in Business Journalism
                                             </li>
                                             <li><i
                                                 class='bx bx-chevron-right' style="color: #29176d;"></i>Post Graduate
                                                 Diploma in Oriya Journalism
                                             </li>
                                         </ul>
                                     </div>
                                 </div>
                             </div>-->
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</div>
