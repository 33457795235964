<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                 <li><a routerLink="/">Home</a></li>
                 <li>Courses Grid 04</li>
             </ul>-->
            <h2>DOWNLOADS</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="events-area bg-f9f9f9 pt-70" style="margin-top: -50px;">
    <div class="container">
        <div class="row align-items-center">
            <h4 class="inter-font" style="text-align: center;"><span>Downloads for Students</span></h4>
            <!-- <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>--
                    <div class="single-coaching-events-box">
                        <span class="location"> Guidelines for students to applying the application form
                        </span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>--
                        <div class="date">
                            <a href="assets/documents/Guidelines%20for%20students%20to%20applying%20the%20application%20form.pdf"
                               target="_blank">
                                <div class="div">
                                    <span>Click</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> Application Form For Provisional/Consolidated Marksheet and Final Degree
                        </span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <a href="assets/documents/Degree-diploma_Format_New.pdf"
                               target="_blank">
                                <div class="div">
                                    <span>Click</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>--
                    <div class="single-coaching-events-box">
                        <span class="location"> Application Format for obtaining the Prospectus/Admission Form
                        </span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>--
                        <div class="date">
                            <a href="assets/documents/APPLICATION%20FORMAT.pdf"
                               target="_blank">
                                <div class="div">
                                    <span>Click</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> Admission Form for 2nd & 3rd Year
                        </span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <a href="assets/documents/Admission%20form%20for%202nd%20&%203rd%20year1.pdf"
                               target="_blank">
                                <div class="div">
                                    <span>Click</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> Examination Form
                        </span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <a href="assets/documents/examination%20form_3.pdf"
                               target="_blank">
                                <div class="div">
                                    <span>Click</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> Examination Form (Credit Transfer)
                        </span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <a href="assets/documents/EXAMINATION%20FORM(Credit%20Transfer)1.pdf"
                               target="_blank">
                                <div class="div">
                                    <span>Click</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> Application Form for Migration Certificate
                        </span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <a href="assets/documents/Migration%20Form.pdf"
                               target="_blank">
                                <div class="div">
                                    <span>Click</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> Degree Performa
                        </span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <a href="assets/documents/Degree-diploma_Format_New.pdf"
                               target="_blank">
                                <div class="div">
                                    <span>Click</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <div class="single-coaching-events-box">
                        <span class="location"> CDOE (Formerly DDE) Prospectus
                        </span>
                        <!--<a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy
                            Ticket<span></span></a>-->
                        <div class="date">
                            <a href="assets/documents/DDE Prospectus.pdf"
                               target="_blank">
                                <div class="div">
                                    <span>Click</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
