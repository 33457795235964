<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Master of Arts in Buddhist Studies</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!--<div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Introduction</h3>
                        </div>
                        <p>
                            The Master of Arts in Buddhist Studies is a two-year
                            program for those interested in the study of
                            religion at the graduate level. The goal of the
                            program is to provide students with a solid
                            foundation in Buddhist studies and scholarship as
                            well as training in research, analysis, and
                            presentation techniques. Graduates will be
                            well-equipped for academic or missionary careers
                            thanks to a thorough grounding in Buddhist teachings
                            in all its facets.
                        </p>
                        <h4>Objective</h4>
                        <p>
                            The MA Buddhist Studies curriculum allows students
                            to delve deeply into the religion by learning about
                            its origins, canonical writings, and contemporary
                            practices. The curriculum is also a great stepping
                            stone to graduate school or a career in the
                            nonprofit or religious sphere.
                        </p>
                        <p>
                            Graduate students are able to hone their analytical
                            skills, put their learning to practical use, and
                            investigate underlying ethical ideas. The goals of
                            the course are to stimulate analytical thought and
                            practical knowledge.
                        </p>
                        <p>
                            Skills in historical analysis, literary analysis,
                            and ethnographic research are emphasized. Buddhist
                            principles of nonviolence and pacifism are
                            highlighted throughout the program. This degree
                            prepares its students to enter the academic world
                            and propagate Buddhist philosophy in the Western
                            world.
                        </p>
                        <p>
                            Graduates of the MA Buddhist Studies program will be
                            prepared for advanced degrees in the field,
                            including the Doctor of Philosophy. Some graduates
                            begin their careers as elementary school teachers,
                            some as high school teachers, and yet others as
                            university professors and translators. If you want
                            to advance your career in academia, earning a MA
                            Buddhist Studies degree will provide you with the
                            tools you need to succeed.
                        </p>
                        <h5>Programme Duration</h5>
                        <p>
                            Master of Arts in Buddhist Studies is an
                            undergraduate degree program and this programs is
                            typically two years course.
                        </p>
                        <p>
                            Minimum Duration 2 Years<br />Maximum Duration 4
                            Year
                        </p>
                        <h6>Eligibility & Fee</h6>
                        <p>
                            Online/distance Master of Arts in Buddhist Studies
                            eligibility criteria are too very flexible. To
                            pursue an online and distance MA Buddhist Studies
                            one has to be graduate from any recognised
                            university. There are no entrance examinations for
                            admission in this course.
                        </p>
                        <table class="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Programme fee</th>
                                    <th>Examination fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Rs. 13000 Per Year</td>
                                    <td>Rs. 2000 Per Year</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6 style="font-weight: bold">Payment Mode</h6>
                        <p>
                            Student can submit his fee in cash to cash counter
                            in department or submit online
                            <b
                                >For online payment, Account Details are as
                                follow :</b
                            >
                        </p>
                        <table
                            style="width: 100%"
                            cellpadding="0"
                            cellspacing="0"
                            border="1"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Account Holder Name:</b> Swami
                                        Vivekanand Subharti University Distance
                                        Education
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height: 24px">
                                        <b>Account Number:</b> 628501043170
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>IFSC Code:</b> ICIC0006285</td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>Branch:</b> 65 Shiv Puri Hapur Road
                                        Meerut
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h6><strong>Career Prospects</strong></h6>
                        <p>
                            After post-graduation student has various options
                            for employment, both in the government and private
                            sectors.
                        </p>
                        <p>
                            There are many designations for Master of Arts in
                            Buddhist Studies:
                        </p>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >University professor
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Researcher at the advanced level in various
                                subjects related to Buddhist studies
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Lecturer
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Theologise
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Buddhist archaeologist
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Historian
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Buddhist philosophy expert
                            </li>
                        </ul>
                        <h6>
                            Best study options after Master of Arts in Buddhist
                            Studies
                        </h6>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >M.Phil.
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Ph.D.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</div>
