<div class="events-area bg-f9f9f9 pt-70" style="margin-top: -50px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="coaching-events-content dividerHeading">
                    <!--<span class="sub-title">COACHING PROGRAM</span>-->
                    <h4
                        class="inter-font d-none d-lg-block"
                        style="text-align: center"
                    >
                        <span>Recent News</span>
                    </h4>
                    <h5 class="inter-font d-lg-none" style="text-align: center">
                        Recent News
                    </h5>
                    <div
                        class="single-coaching-events-box"
                        style="
                            height: 482px;
                            background-color: #f9be2b;
                            text-align: center;
                        "
                    >
                        <!--<span class="location"><i class='bx bx-map'></i> New York</span>-->

                        <marquee
                            width="100%"
                            behavior="scroll"
                            direction="up"
                            height="100%"
                            onMouseOver="this.stop();"
                            onMouseOut="this.start();"
                        >
                            <ol class="inner">
                                <li>
                                    <b>Important Information</b><br />
                                    This is to inform to all concerned that
                                    please send your consent letter for coming
                                    examinations November 2024, through your
                                    official email along with the prescribed
                                    format till 20.11.2024. After 20.11.2024 no
                                    application will be entertained regarding
                                    the same. Email is:
                                    examconsent2021@gmail.com
                                    <br /><br />
                                    <b>महत्वपूर्ण सूचना</b><br />
                                    सभी संबंधितों को सूचित किया जाता है कि कृपया
                                    आगामी परीक्षाओं नवंबर 2024 के लिए अपना सहमति
                                    पत्र अपने आधिकारिक ईमेल के माध्यम से
                                    निर्धारित प्रारूप के साथ 20.11.2024 तक
                                    भेजें। 20.11.2024 के बाद इस संबंध में किसी
                                    भी आवेदन पर विचार नहीं किया जाएगा।<br />
                                    <a
                                        href="assets/documents/ImportantDocuments/Nov 2024 consent letter.pdf"
                                        ><b>Consent Letter November 2024</b></a
                                    ><br />
                                    <a
                                        href="assets/documents/ImportantDocuments/Nov 2024 consent form.pdf"
                                        ><b
                                            >Consent Letter Format November
                                            2024</b
                                        ></a
                                    ><br />
                                </li>
                                <br />
                                <li>
                                    <b>Important Information</b><br />
                                    This is to inform to all concerned that, on
                                    the auspicious occasion of Diwali,
                                    Bhaiyadooj and Govardhan the Centre for
                                    Distance and Online Education (Formally
                                    known as Directorate of Distance Education)
                                    remain closed from 30.10.2024 to 04.11.2024.
                                    <br /><br />
                                    <b>महत्वपूर्ण सूचना</b><br />
                                    सभी संबंधितों को सूचित किया जाता है कि
                                    दिवाली, भैयादूज और गोवर्धन के शुभ अवसर पर
                                    दूरस्थ और ऑनलाइन शिक्षा केंद्र (औपचारिक रूप
                                    से दूरस्थ शिक्षा निदेशालय के रूप में जाना
                                    जाता है) 30.10.2024 से 04.11.2024 तक बंद
                                    रहेगा।<br />
                                </li>
                                <br />
                                <li>
                                    <b>Important Information</b><br />
                                    This is to inform to all concerned/
                                    students/ visitors that due to KANWAR YATRA
                                    University remain closed from 26th July to
                                    2nd August 2024, but the scheduled
                                    examinations will be completed on due course
                                    of date and time. <br /><br />
                                    <b>महत्वपूर्ण सूचना</b><br />
                                    सभी संबंधितों/छात्रों/आगंतुकों को सूचित किया
                                    जाता है कि कांवर यात्रा के कारण
                                    विश्वविद्यालय 26 जुलाई से 2 अगस्त 2024 तक
                                    बंद रहेगा, लेकिन निर्धारित परीक्षाएं तय समय
                                    और तारीख पर पूरी की जाएंगी।<br />
                                </li>
                                <br />
                                <li>
                                    <b>Important Information</b><br />
                                    It is to inform to all the concerned that
                                    the last date of filling the examination
                                    form for July 2024 Semester mode
                                    Examinations are as follows:-<br />
                                    1. Up to 29.06.2024 without any late fee.<br />

                                    2. From 01.07.2024 to 3.07.2024, with Rs.
                                    1000/. late fee<br />

                                    3. From 04.07.2024 to 05.07.2024, with
                                    Rs.5000/. late fee.<br />
                                    <b>महत्वपूर्ण सूचना</b><br />
                                    सभी संबंधितों को सूचित किया जाता है कि जुलाई
                                    2024 सेमेस्टर मोड परीक्षाओं के लिए परीक्षा
                                    फॉर्म भरने की अंतिम तिथि इस प्रकार है:-<br />
                                    1. 29.06.2024 बिना किसी विलंब शुल्क के।<br />

                                    2. 01.07.2024 से 3.07.2024 तक रु. 1000/
                                    विलंब शुल्क ।<br />

                                    3. 04.07.2024 से 05.07.2024 तक, रु. 5000/
                                    विलंब शुल्क के साथ।<br />
                                </li>
                                <br />
                                <li>
                                    <b>Important</b><br />
                                    This is to inform all concerned / students/
                                    visitors that Centre for Distance and Online
                                    Education (Formerly Directorate of Distance
                                    Education) of Swami Vivekanand Subharti
                                    University will remain closed on Monday,
                                    June 17, 2024, in observance of Eid
                                    al-Adha.<br /><br />
                                    <b>महत्वपूर्ण</b><br />
                                    सभी संबंधित/छात्रों/आगंतुकों को सूचित किया
                                    जाता है कि ईद उल-अज़हा के उपलक्ष्य में
                                    स्वामी विवेकानन्द सुभारती विश्वविद्यालय का
                                    दूरस्थ शिक्षा निदेशालय सोमवार, 17 जून 2024
                                    को बंद रहेगा।<br />
                                </li>
                                <br />
                                <li>
                                    <b>Important</b><br />
                                    This is to inform to all concerned that
                                    please send your consent letter for coming
                                    semester examinations July 2024, through
                                    your official email along with the
                                    prescribed format till 25.06.2024. After
                                    25.06.2024 no application will be
                                    entertained regarding the same. Email is:
                                    examconsent2021@gmail.com.<br /><br />
                                    <b>महत्वपूर्ण</b><br />
                                    सभी संबंधितों को सूचित किया जाता है कि कृपया
                                    आगामी सेमेस्टर परीक्षाओं जुलाई 2024 के लिए
                                    अपना सहमति पत्र अपने आधिकारिक ईमेल के माध्यम
                                    से निर्धारित प्रारूप के साथ 25.06.2024 तक
                                    भेजें। 25.06.2024 के बाद इस संबंध में किसी
                                    भी आवेदन पर विचार नहीं किया जाएगा।<br /><br />

                                    <a
                                        href="assets/documents/ImportantDocuments/July 2024 Consent Letter.pdf"
                                        ><b>Consent Letter July 2024</b></a
                                    ><br />
                                    <a
                                        href="assets/documents/ImportantDocuments/July 2024 Consent Form.pdf"
                                        ><b
                                            >Consent Letter Format July 2024</b
                                        ></a
                                    ><br />
                                </li>
                                <br />
                                <li>
                                    <b>Office Order</b><br />
                                    This is to inform you that Thursday, 23rd
                                    May 2024, will be observed as a holiday on
                                    account of Buddha Purnima. On this
                                    auspicious day, we commemorate the birth,
                                    enlightenment of Lord Buddha.
                                    <br />
                                    All offices and departments will remain
                                    closed for the day i.e., 23.05.2024. Regular
                                    work will resume on Friday, 24th May 2024.
                                    <br />
                                    We wish everyone a peaceful and reflective
                                    Buddha Purnima.
                                </li>
                                <li>
                                    <b>कार्यालय आदेश</b> <br />
                                    आपको सूचित किया जाता है कि गुरुवार, 23 मई
                                    2024 को बुद्ध पूर्णिमा के अवसर पर अवकाश
                                    रहेगा। इस शुभ दिन पर, हम भगवान बुद्ध के
                                    जन्म, ज्ञान प्राप्ति का स्मरण करते हैं। सभी
                                    कार्यालय और विभाग आज यानी 23.05.2024 को बंद
                                    रहेंगे। शुक्रवार, 24 मई 2024 को नियमित काम
                                    फिर से शुरू होगा। हम सभी को शांतिपूर्ण और
                                    चिंतनशील बुद्ध पूर्णिमा की शुभकामनाएं देते
                                    हैं।
                                    <hr />
                                </li>

                                <li>
                                    Important<br />
                                    This is to inform to all concerned that
                                    please send your consent letter for coming
                                    Annual Mode examinations May 2024, through
                                    your official email along with the
                                    prescribed format till 20.05.2024. After
                                    20.05.2024 no application will be
                                    entertained regarding the same. Email is:
                                    examconsent2021@gmail.com<br /><br />
                                    महत्वपूर्ण<br />
                                    सभी संबंधितों को सूचित किया जाता है कि कृपया
                                    आगामी वार्षिक परीक्षा मई 2024 के लिए अपना
                                    सहमति पत्र अपने आधिकारिक ईमेल के माध्यम से
                                    निर्धारित प्रारूप के साथ 20.05.2024 तक
                                    भेजें। 20.05.2024 के बाद इस संबंध में किसी
                                    भी आवेदन पर विचार नहीं किया जाएगा।<br />
                                    <a
                                        href="assets/documents/ImportantDocuments/ConcentLetter.jpg"
                                        >Consent Letter</a
                                    ><br />
                                    <a
                                        href="assets/documents/ImportantDocuments/ConcentLetterFormat.jpg"
                                        >Consant Letter Format</a
                                    >
                                </li>
                                <li>
                                    <a href="#"
                                        >We are pleased to inform to all
                                        concerned that in favor of our PLANET
                                        EARTH University decided to make TUESDAY
                                        & WEDNESDAY as a VEHICLE FREE/ POLUTION
                                        FEE Day. All vehicle except electrical
                                        one, are prohibited in University CAMPUS
                                        on TUESDAY & WDNESDAY, every week. This
                                        is approved by competent authority.</a
                                    >
                                    <hr />
                                </li>

                                <li>
                                    <a href="#">
                                        महत्वपूर्ण सूचना
                                        <br />
                                        Make the online payment only on the
                                        account numbers given below. <br />
                                        ऑनलाइन भुगतान केवल नीचे दिए गए अकाउंट
                                        नंबर पर ही करें।<br /><br />
                                        <span>(i)</span>
                                        <table style="border: 2px solid">
                                            <tr style="border: 2px solid">
                                                <td
                                                    style="
                                                        border: 2px solid;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    Account Holder Name:
                                                </td>
                                                <td
                                                    style="
                                                        border: 2px solid;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    Swami Vivekanand Subharti
                                                    University Distance
                                                    Education
                                                </td>
                                            </tr>
                                            <tr style="border: 2px solid">
                                                <td
                                                    style="
                                                        border: 2px solid;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    Account Number:
                                                </td>
                                                <td
                                                    style="
                                                        border: 2px solid;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    628501043170
                                                </td>
                                            </tr>
                                            <tr style="border: 2px solid">
                                                <td
                                                    style="
                                                        border: 2px solid;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    IFSC Code:
                                                </td>
                                                <td
                                                    style="
                                                        border: 2px solid;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    ICIC0004078
                                                </td>
                                            </tr>
                                            <tr style="border: 2px solid">
                                                <td
                                                    style="
                                                        border: 2px solid;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    Branch:
                                                </td>
                                                <td
                                                    style="
                                                        border: 2px solid;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    65 Shiv Puri Hapur Road
                                                    Meerut
                                                </td>
                                            </tr>
                                        </table>
                                        <br />
                                        <span>(ii)</span>
                                        <table style="border: 2px solid">
                                            <tr style="border: 2px solid">
                                                <td
                                                    style="
                                                        border: 2px solid;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    Account Holder Name:
                                                </td>
                                                <td
                                                    style="
                                                        border: 2px solid;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    SWAMI VIVEKANAND SUBHARTI
                                                    UNIVERSITY
                                                </td>
                                            </tr>
                                            <tr style="border: 2px solid">
                                                <td
                                                    style="
                                                        border: 2px solid;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    Account Number:
                                                </td>
                                                <td
                                                    style="
                                                        border: 2px solid;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    407801000455
                                                </td>
                                            </tr>
                                        </table>
                                        <br />
                                        Don't give cash to anyone. <br />
                                        किसी भी व्यक्ति को नकद पैसे न दें।
                                    </a>
                                    <hr />
                                </li>

                                <li>
                                    <a href="#"
                                        >All students and visitors are hereby
                                        informed that Mr. Rajat Sarkar has
                                        already left the Distance Education
                                        department from Swami Vivekanand
                                        Subharti University, Meerut and it is
                                        requested to you all that please do not
                                        transfer or give any money to him or in
                                        his bank account. If anyone will do this
                                        then University will not be responsible
                                        for any monetary loss, the sender is own
                                        responsible for their act.</a
                                    >
                                    <hr />
                                </li>

                                <li>
                                    <a
                                        href="assets/documents/ImportantDocuments/black-list-details.pdf"
                                        >Black Listed Institutes
                                    </a>
                                    <hr />
                                </li>

                                <li>
                                    <a
                                        href="dde_files/Time Table Update.pdf"
                                        target="_blank"
                                        >Time Table CALENDER BATCH-2021</a
                                    >
                                    <hr />
                                </li>
                                <li>
                                    <a
                                        href="images/Fraud-Institute-11-8-21.xlsx"
                                        target="_blank"
                                        >Fraud Institute List</a
                                    >
                                    <hr />
                                </li>

                                <li>
                                    <a
                                        href="https://www.subhartidde.com/no-study-center/index.php"
                                        >No Study Center</a
                                    >

                                    <hr />
                                </li>
                                <li>
                                    <a
                                        href="documents/PROSPECTS_FAKE.pdf"
                                        target="_blank"
                                        >It has come to the notice of the Centre
                                        for Distance and Online Education
                                        (Formerly Directorate of Distance
                                        Education), SVSU, that a FAKE PROSPECTUS
                                        is circulating on social media/WhatsApp.
                                        group and other digital media platform.
                                        This PROSPECTUS has not been issued by
                                        Directorate of Distance Education, SVSU,
                                        Meerut. <br />
                                        Candidate and Students are advised to be
                                        more cautious about such FAKE
                                        PROSPECTUS. The genuine prospectus is
                                        only available at our official website
                                        www.subhartidde.com and
                                        www.subhartidde.co.in, or from the
                                        CAMPUS.<br />
                                        Kindly be cautious while taking
                                        admission and transferring money in name
                                        of admission to the University.
                                        <br />
                                        <span style="color: red"
                                            >click here to see FAKE
                                            PROSPECTUS</span
                                        >
                                    </a>
                                    <hr />
                                </li>

                                <li>
                                    <a
                                        href="documents/Notification.jpg"
                                        target="_blank"
                                    >
                                        3rd February 2021 <br />
                                        Public Notice.
                                    </a>
                                    <hr />
                                </li>

                                <!-- <li><a href="documents/NewFeeStr_2020_21.pdf" target="_blank">
                                    14 October 2020 <br>
                                    New fee Structure for Academic session 2020-21 and Calendar 2021.
                                </a>
                                    <hr>
                                </li> -->
                                <li>
                                    <a
                                        href="documents/1471046_Public-Notice-DEB.pdf"
                                        style="width: 320px"
                                        target="_top"
                                    >
                                        Continuation of recognition for a period
                                        of one year for Academic session 2020-21
                                        and Calendar 2021.
                                    </a>
                                    <hr />
                                </li>
                            </ol>
                        </marquee>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="coaching-events-image">
                    <img
                        src="assets/img/business-coach/business-coach9.jpg"
                        alt="image"
                    />
                </div>
            </div>
        </div>
    </div>
    <!--<div class="c-shape5"><img src="assets/img/business-coach/c-shape5.png" alt="image"></div>-->
</div>
