<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>MA in Journalism and Mass Communication</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!--<div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Introduction</h3>
                        </div>
                        <p>
                            The curriculum of an MA in Journalism and Mass
                            Communication provides students with the tools they
                            need to analyze and evaluate the many forms of
                            media. This is a two-year graduate program. Students
                            in this course will learn how to effectively
                            disseminate information through many forms of mass
                            media, such as print publications, audio and visual
                            presentations, and the internet.
                        </p>
                        <h4>Objective</h4>
                        <p>
                            MA Journalism & Mass Communication is a study of
                            different forms of communication by which
                            individuals can deliver information to a vast
                            portion of the population through mass media.
                            Participants in this course should have a natural
                            curiosity for learning, the self-discipline to do
                            their own research, the capacity to distill complex
                            ideas into simple language, and the fluency to
                            articulate those ideas both in oral and written
                            form. Reporting, writing, editing, photography, and
                            other related skills are covered and taught
                            throughout the course.
                        </p>
                        <p>
                            Students can choose from a wide range of degrees in
                            the field of journalism, including those focused on
                            sports, science, the environment, fashion, film,
                            advertising, corporate communications, and more.
                            Graduates who are successful in their career
                            searches may get employment in fields including
                            marketing, cinematography, broadcasting, press
                            information, radio, television networks, websites,
                            etc.
                        </p>
                        <p>
                            Students must have a Bachelor's degree with a
                            minimum of 50% from an accredited university in any
                            field to be considered for admission to the program.
                            In certain institutions, students are admitted based
                            on their performance in a combination of an entrance
                            test, a Group Discussion, and an interview.
                        </p>
                        <h5>Programme Duration</h5>
                        <p>
                            MA Journalism & Mass Communication is a
                            post-graduate degree program and this programs are
                            typically two years course.
                        </p>
                        <p>
                            Minimum Duration 2 Years<br />Maximum Duration 4
                            Year
                        </p>
                        <h6>Eligibility & Fee</h6>
                        <p>
                            Online/distance MA Journalism & Mass Communication
                            eligibility criteria are too very flexible. To
                            pursue an online and distance MA Journalism & Mass
                            Communication one has to be completed graduation
                            from any recognised university. There are no
                            entrance examinations for admission in this course.
                        </p>
                        <table class="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Programme fee</th>
                                    <th>Examination fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Rs. 14000 Per Year</td>
                                    <td>Rs. 2000 Per Year</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6 style="font-weight: bold">Payment Mode</h6>
                        <p>
                            Student can submit his fee in cash to cash counter
                            in department or submit online
                            <b
                                >For online payment, Account Details are as
                                follow :</b
                            >
                        </p>
                        <table
                            style="width: 100%"
                            cellpadding="0"
                            cellspacing="0"
                            border="1"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Account Holder Name:</b> Swami
                                        Vivekanand Subharti University Distance
                                        Education
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height: 24px">
                                        <b>Account Number:</b> 628501043170
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>IFSC Code:</b> ICIC0006285</td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>Branch:</b> 65 Shiv Puri Hapur Road
                                        Meerut
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h6><strong>Career Prospects</strong></h6>
                        <p>
                            After post-graduation student has various options
                            for employment, both in the government and private
                            sectors.
                        </p>
                        <p>
                            There are many designations for MA Journalism & Mass
                            Communication Graduates:
                        </p>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Television
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Journalist
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Digital Marketing Expert
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Radio jockey (RJ)
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Social Media Executive
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Content Creator/ Developer
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Public Relations Professional
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Publicity/Advertising Industry.
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Event Manager
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Broadcasting and Production
                            </li>
                        </ul>
                        <h6>
                            Top Companies that hire Journalism & Mass
                            Communication Graduates
                        </h6>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Hindustan Times Group of Publication
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >NDTV Network
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Balaji Telefilms
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Zee TV Network
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >DDB Mudra Group
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Times of India Group
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Reliance Entertainment
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Viacom
                            </li>
                        </ul>
                        <p>
                            <strong>Best study options after </strong> MA
                            Journalism & Mass Communication
                        </p>
                        <p>
                            Graduates who are enthusiastic about continuing
                            their studies in the field of education and have
                            already earned their MA Journalism & Mass
                            Communication degree can pursue-
                        </p>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >M.Phil.
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Ph.D.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</div>
