import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detailed-syllabus',
  templateUrl: './detailed-syllabus.component.html',
  styleUrls: ['./detailed-syllabus.component.scss']
})
export class DetailedSyllabusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
