<div class="about-area-three ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <!--<span class="sub-title">Distance Learning</span>-->
                    <h2>Centre for Distance and Online Education (Formerly Directorate of Distance Education)</h2>
                    <p>In exercise of the powers vested under section 7 (b) of the Act, Swami Vivekanand Subharti
                        University has established the Centre for Distance and Online Education (Formerly Directorate of Distance Education) to help those who want to
                        pursue higher studies, but are unable to do so through regular mode of education due to job
                        constraints, family commitments or remoteness of location.he Distance Education Council of
                        Government of India has accorded recognition to the University for offering programmes through
                        distance education mode also vide letter no. DEC/Recog/2009/3174 dated 09.09.2009, on the
                        recommendation of the Joint Committee of the University Grants Commission,</p>
                    <!--<a routerLink="/courses-4" class="default-btn"><i class="flaticon-user"></i>READ MORE<span></span></a>-->
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-img">
                    <div class="image">
                        <img src="assets/img/about-img7.png" alt="image">
                        <img src="assets/img/about-img8.png" alt="image">
                    </div>
                    <div class="shape17"><img src="assets/img/shape16.png" alt="image"></div>
                    <div class="shape18"><img src="assets/img/shape17.png" alt="image"></div>
                    <div class="shape19"><img src="assets/img/shape18.png" alt="image"></div>
                    <div class="shape20"><img src="assets/img/shape19.png" alt="image"></div>
                    <div class="shape21"><img src="assets/img/shape20.png" alt="image"></div>
                    <div class="shape22"><img src="assets/img/shape21.png" alt="image"></div>
                    <div class="shape23"><img src="assets/img/shape22.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
</div>
