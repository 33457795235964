<div class="experience-area ptb-100 extra-padding">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="experience-content">
                    <span class="sub-title">Join our World Famous Yoga Teacher Training Course... Online!</span>
                    <h2 class="playfair-display-font">200hr, 300hr & 500hr Certified Online Yoga courses</h2>
                    <p>subharti training programs can bring you a super exciting experience of learning through online! You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. Our flexible learning initiatives will help you to learn better and quicker than the traditional ways of learning skills.</p>
                    <ul class="features-list">
                        <li><i class="bx bx-check"></i> Receive a Yoga Alliance Certificate</li>
                        <li><i class="bx bx-check"></i> 100hrs of Live Streaming Zoom Classes</li>
                        <li><i class="bx bx-check"></i> Watch Video Modules in Your Free Time</li>
                        <li><i class="bx bx-check"></i> Flex Schedule with Extended Time from Yoga Alliance</li>
                        <li><i class="bx bx-check"></i> Practice Traditional Hatha and vinyasa Yoga</li>
                        <li><i class="bx bx-check"></i> Bonus Aerial & Acro Yoga Workshops</li>
                    </ul>
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Get Started Now<span></span></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="experience-image">
                    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                        <a
                            data-lg-size="1280-720"
                            data-src="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                            data-sub-html="<p>Watch Video</p>"
                            class="d-block"
                        >
                            <img src="assets/img/experience-img1.jpg" alt="image">
                            <div class="video-btn">
                                <i class="flaticon-play"></i>
                            </div>
                        </a>
                    </lightgallery>
                    <span class="title"><span>15</span> Years of Experience</span>
                </div>
            </div>
        </div>
    </div>
</div>
