<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2 style="color: white;">Detail Syllabus</h2>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-text-container">
                    <h4 style="background: #29176d;color: white;padding: 21px; margin-bottom:15px">Detail Syllabus</h4>
                    <div class="rightcategory_box">
                        <!--  <div class="blog_box">
                              <div class="blog_heading">
                                  <h6 class="download_text">
                                      ADBA
                                  </h6>
                                  <h2 style="text-align: center">
                                      <a target=_blank href="assets/img/pdf/ADBA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                              </div>
                          </div>-->
                        <!--   <div class="blog_box">
                               <div class="blog_heading">
                                   <h6 class="download_text">
                                       ADCA
                                   </h6>
                                   <h2 style="text-align: center">
                                       <a target=_blank href="assets/img/pdf/ADCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                               </div>
                           </div>-->
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    B.Com
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/B.COM.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    B.Lib
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/B.lib.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <!--<div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BSc (PCM)
                                </h6>
                                <h2 style="text-align: center">
                                    <a target=_blank href="assets/img/pdf/B.SC(PCM).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                      <!--  <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BSc (ZBC)
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/BSc(ZBC).pdf">&lt;&lt;Download&gt;&gt;</a>
                                </h2>
                            </div>
                        </div>-->
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BA
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/BA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BBA
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/BBA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <!-- <div class="blog_box">
                             <div class="blog_heading">
                                 <h6 class="download_text">
                                     BBA-HM
                                 </h6>
                                 <h2 style="text-align: center">
                                     <a target=_blank href="assets/img/pdf/BBA-HM.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                             </div>
                         </div>-->
                        <!--  <div class="blog_box">
                              <div class="blog_heading">
                                  <h6 class="download_text">
                                      BCA
                                  </h6>
                                  <h2 style="text-align: center">
                                      <a target=_blank href="assets/img/pdf/BCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                              </div>
                          </div>-->
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    BA (JMC)
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/BJMC.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <!--   <div class="blog_box">
                               <div class="blog_heading">
                                   <h6 class="download_text">
                                       DBA
                                   </h6>
                                   <h2 style="text-align: center">
                                       <a target=_blank href="assets/img/pdf/DBA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                               </div>
                           </div>-->
                        <!--      <div class="blog_box">
                                  <div class="blog_heading">
                                      <h6 class="download_text">
                                          DCA
                                      </h6>
                                      <h2 style="text-align: center">
                                          <a target=_blank href="assets/img/pdf/DCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                                  </div>
                              </div>-->
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (English)
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank"
                                       href="assets/img/pdf/MA(English).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (Hindi)
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/MA(Hindi).pdf">&lt;&lt;Download&gt;&gt;</a>
                                </h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (History)
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank"
                                       href="assets/img/pdf/MA(History).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (Pol. Science)
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/MA(PoliticalScience).pdf">&lt;&lt;Download&gt;&gt;</a>
                                </h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (Public Admin)
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/MA(PublicAdministration).pdf">&lt;&lt;Download&gt;&gt;</a>
                                </h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (Sociology)
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank"
                                       href="assets/img/pdf/MA(Sociology).pdf">&lt;&lt;Download&gt;&gt;</a>
                                </h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MA (JMC)
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/MA(JMC).pdf">&lt;&lt;Download&gt;&gt;</a>
                                </h2>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    M. Lib
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/M.lib.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                        <!--   <div class="blog_box">
                               <div class="blog_heading">
                                   <h6 class="download_text">
                                       MSc (CA)
                                   </h6>
                                   <h2 style="text-align: center">
                                       <a target=_blank href="assets/img/pdf/MSc(CA).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                               </div>
                           </div>-->
                        <!-- <div class="blog_box">
                             <div class="blog_heading">
                                 <h6 class="download_text">
                                     MSc (DFSM)
                                 </h6>
                                 <h2 style="text-align: center">
                                     <a target=_blank href="assets/img/pdf/M.Sc(DFSM).pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                             </div>
                         </div>-->
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MBA
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/MBA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>
                      <!--  <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MCA
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/MCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>
                        </div>-->
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6 class="download_text">
                                    MCom
                                </h6>
                                <h2 style="text-align: center">
                                    <a target="_blank" href="assets/img/pdf/MCom.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                            </div>

                        </div>
                        <!--        <div class="blog_box">
                                    <div class="blog_heading">
                                        <h6 class="download_text">
                                            PGDCA
                                        </h6>
                                        <h2 style="text-align: center">
                                            <a target=_blank href="assets/img/pdf/PGDCA.pdf">&lt;&lt;Download&gt;&gt;</a></h2>
                                    </div>
                                </div>-->
                        <!-- <div class="blog_box">
                             <div class="blog_heading">
                                 <h6 class="download_text">
                                     PGDFSQM
                                 </h6>
                                 <h2 style="text-align: center">
                                     <a target="_blank" href="assets/img/pdf/PGDFSQM.pdf">&lt;&lt;Download&gt;&gt;</a>
                                 </h2>
                             </div>
                         </div>-->
                        <!--     <div class="blog_box">
                                 <div class="blog_heading">
                                     <h6 class="download_text">
                                         PGDHHM
                                     </h6>
                                     <h2 style="text-align: center">
                                         <a target="_blank" href="assets/img/pdf/PGDHHM.pdf">&lt;&lt;Download&gt;&gt;</a>
                                     </h2>
                                 </div>
                             </div>-->
                        <!--   <div class="blog_box">
                               <div class="blog_heading">
                                   <h6 class="download_text">
                                       PGDIPR
                                   </h6>
                                   <h2 style="text-align: center">
                                       <a target="_blank" href="assets/img/pdf/PGDIPR.pdf">&lt;&lt;Download&gt;&gt;</a>
                                   </h2>
                               </div>
                           </div>-->
                        <!--      <div class="blog_box">
                                  <div class="blog_heading">
                                      <h6 class="download_text">
                                          PGDPPN
                                      </h6>
                                      <h2 style="text-align: center">
                                          <a target="_blank" href="assets/img/pdf/PGDPPN.pdf">&lt;&lt;Download&gt;&gt;</a>
                                      </h2>
                                  </div>
                              </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
