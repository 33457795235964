<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                 <li><a routerLink="/">Home</a></li>
                 <li>Courses Grid 04</li>
             </ul>-->
            <h2>Latest News</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-area pt-100 pb-70">
    <div class="container">
        <!--    <div class="subharti-grid-sorting row align-items-center">
                <div class="col-lg-6 col-md-6 result-count">
                    <p>We found <span class="count">12</span> courses available for you</p>
                </div>
                <div class="col-lg-6 col-md-6 ordering">
                    <div class="select-box">
                        <label>Sort By:</label>
                        <select>
                            <option>Default</option>
                            <option>Popularity</option>
                            <option>Latest</option>
                            <option>Price: low to high</option>
                            <option>Price: high to low</option>
                        </select>
                    </div>
                </div>
            </div>-->
        <h3>Latest News</h3>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="assets/img/courses/img12.jpg" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">Agile Crash Course: Agile Project Management</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="assets/img/courses/img13.jpg" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">Vue JS 2 - The Complete Guide (incl. Vue Router &
                            Vuex)</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="assets/img/courses/img14.jpg" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">The Python Bible™ | Everything You Need to Program in
                            Python</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="assets/img/courses/img15.jpg" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">Mathematical Foundation For Machine Learning and AI</a>
                        </h3>

                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="assets/img/courses/img16.jpg" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">The Ultimate Drawing Course - Beginner to Advanced</a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="assets/img/courses/img17.jpg" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">PyTorch: Deep Learning and Artificial Intelligence</a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="assets/img/courses/img1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">Azure Machine Learning Certification- AZ AI100</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="assets/img/courses/img2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">Statistics for Data Science and Business Analysis</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="assets/img/courses/img3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">Data Science & Deep Learning for Business™ 20 Case
                            Studies</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="assets/img/courses/img4.jpg" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">Introduction to Machine Learning for Data Science</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="assets/img/courses/img5.jpg" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">Microsoft Excel - Excel from Beginner to Advanced</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="assets/img/courses/img6.jpg" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">Python Django Web Development: To-Do App</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
