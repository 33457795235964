import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { from } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TitleService {
    constructor(public http: HttpClient) {}

    getCourseTitleJson() {
        return this.http.get<any>('../../assets/courses.json');
    }
    getAboutTitleJson() {
        return this.http.get<any>('../../assets/about.json');
    }
    getExaminationTitleJson() {
        return this.http.get<any>('../../assets/examination.json');
    }
    getStudentSupportTitleJson() {
        return this.http.get<any>('../../assets/student-support.json');
    }
    getAcademicTitleJson() {
        return this.http.get<any>('../../assets/academic.json');
    }
}
