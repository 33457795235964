import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-msc-mathematics',
  templateUrl: './msc-mathematics.component.html',
  styleUrls: ['./msc-mathematics.component.scss']
})
export class MscMathematicsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
