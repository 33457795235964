import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bfa',
  templateUrl: './bfa.component.html',
  styleUrls: ['./bfa.component.scss']
})
export class BfaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
