<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>WORLD OF SUBHARTI</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="products-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="products-details-desc">
                    <h3>Urban Medical Establishments</h3>
                    <p>Lokpriya Hospital, Meerut</p>
                    <h4>Rural Medical Establishments</h4>
                    <p>Veer Savarkar Subharti Hospital, Lohia Meerut</p>
                    <p>Khudiram Bose I, Subharti Hospital, Lohia, Meerut</p>
                    <p>Chandra Shekher Azad, Subharati Hospital Kailawan, Bulandshahr</p>
                    <p>Maj. Abdul Hameed, Subharati Hospital, Khajoori, Meerut</p>
                    <h5>Other Establishments</h5>
                    <p>Subharti Kalyan Ashram (For the aged)</p>
                    <p>Subharti Institute of Computer Technology (Col. Gurbox Singh Dhillon)</p>
                    <p>Subharti Library</p>
                    <p>Subharti Bulletin</p>
                    <h6>Social Responsibilities of Subharti</h6>
                    <p>Subharti Sewa Sanstha– runs one Intermediate School and 3 junior High Schools in rural areas.</p>
                    <p>Subharti Trust - encourages bright students of poor/economic/social back grounds in the way of
                        Scholarships and other facilities.</p>
                    <p>Ishawar Chandra Vidyasagar Shramik Kalyan Kendra- a free school for basic education for labourers
                        and their children.</p>
                </div>
            </div>
        </div>
    </div>
</div>
