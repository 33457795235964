import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-is-subharti-good',
  templateUrl: './is-subharti-good.component.html',
  styleUrls: ['./is-subharti-good.component.scss']
})
export class IsSubhartiGoodComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
