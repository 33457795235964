import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';

@Injectable()
export class HttpsGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree {
        if (location.protocol === 'http:') {
            // Redirect to the same URL but with HTTPS protocol
            const secureUrl = location.href.replace('http:', 'https:');
            return this.router.parseUrl(secureUrl);
        }

        return true;
    }
}
