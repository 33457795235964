import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assignment-fm3rd23',
  templateUrl: './assignment-fm3rd23.component.html',
  styleUrls: ['./assignment-fm3rd23.component.scss']
})
export class AssignmentFm3rd23Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
