import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assignments-a-s1st23',
  templateUrl: './assignments-a-s1st23.component.html',
  styleUrls: ['./assignments-a-s1st23.component.scss']
})
export class AssignmentsAS1st23Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
