<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Admission Details</h2>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-text-container">
                    <h4 style="background: #29176d;color: white;padding: 21px; margin-bottom:15px">Students
                        Enrolled/Awarded</h4>
                    <div align="center" class="rightcategory_box">
                        <div><strong>Batch 2020-21</strong><br>
                            <br>
                            <div class="accor_heading downToggle" style="overflow-x:auto;">
                                <table cellspacing="0" cellpadding="0" class="table  table-striped"
                                       style="border: 1px solid black;">
                                    <thead>
                                    <tr>
                                        <td></td>
                                        <td><strong>UG</strong></td>
                                        <td><strong>PG</strong></td>
                                        <td><strong>Diploma</strong></td>
                                        <td><strong>PG Diploma</strong></td>
                                        <td><strong>Certificate</strong></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td><strong>Enrolled</strong></td>
                                        <td>2831</td>
                                        <td>657</td>
                                        <td>185</td>
                                        <td>15</td>
                                        <td>7</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Awarded</strong></td>
                                        <td>5213</td>
                                        <td>2504</td>
                                        <td>28</td>
                                        <td>96</td>
                                        <td>3</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <br>
                            <br>
                            <strong>Batch 2019-20</strong><br>
                            <br>
                            <div class="accor_heading downToggle" style="overflow-x:auto;">
                                <table cellspacing="0" cellpadding="0" class="table  table-striped"
                                       style="border: 1px solid black;">
                                    <thead>
                                    <tr>
                                        <td></td>
                                        <td><strong>UG</strong></td>
                                        <td><strong>PG</strong></td>
                                        <td><strong>Diploma</strong></td>
                                        <td><strong>PG Diploma</strong></td>
                                        <td><strong>Certificate</strong></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td><strong>Enrolled</strong></td>
                                        <td>2821</td>
                                        <td>852</td>
                                        <td>14</td>
                                        <td>117</td>
                                        <td>4</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Awarded</strong></td>
                                        <td>3546</td>
                                        <td>2304</td>
                                        <td>21</td>
                                        <td>96</td>
                                        <td>2</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <br>
                            <br>
                            <strong>Batch 2018-19</strong><br>
                            <br>
                            <div class="accor_heading downToggle" style="overflow-x:auto;">
                                <table cellspacing="0" cellpadding="0" class="table  table-striped"
                                       style="border: 1px solid black;">
                                    <thead>
                                    <tr>
                                        <td></td>
                                        <td><strong>UG</strong></td>
                                        <td><strong>PG</strong></td>
                                        <td><strong>Diploma</strong></td>
                                        <td><strong>PG Diploma</strong></td>
                                        <td><strong>Certificate</strong></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td><strong>Enrolled</strong></td>
                                        <td>695</td>
                                        <td>284</td>
                                        <td>132</td>
                                        <td>984</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Awarded</strong></td>
                                        <td>2587</td>
                                        <td>117</td>
                                        <td>122</td>
                                        <td>957</td>
                                        <td>1</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
