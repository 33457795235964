<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">About</a></li>
                <li>It’s Time To Think Differently About Homeschooling</li>
            </ul>-->
            <h2>Bachelor of Commerce (B.Com)</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="">
                            <h3>Introduction</h3>
                        </div>
                        <p>
                            Bachelor of Commerce (B.Com) programs typically last
                            three years and cover topics like business
                            administration, accounting, economics, auditing, and
                            taxation. The Bachelor of Commerce is intended to
                            assist students to develop the knowledge and
                            abilities necessary to launch successful careers in
                            the financial services industries such as banking,
                            accountancy, and insurance.
                        </p>
                        <h4>Objective</h4>
                        <p>
                            Students who are committed to working in business
                            management or retail and who want to advance their
                            careers might consider earning a Bachelor of
                            Commerce degree. It is appropriate for M.Com
                            candidates because it teaches the fundamentals of
                            the M.Com syllabus. The demand for applicants with a
                            Bachelor of Commerce degree has grown steadily over
                            the years in both the public sector and the private
                            sector of the employment market.
                        </p>
                        <p>
                            The subjects included in a Bachelor of Commerce
                            degree program are in high demand by financial
                            institutions, banks, and businesses of all kinds.
                            Students who graduate with a Bachelor of Commerce
                            degree have a better chance of finding satisfactory
                            employment. You should consider getting a B.Com for
                            the reasons listed below:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b>Earning potential -</b>This course has
                                    excellent potential for financial gain.
                                    B.Com graduates have many lucrative
                                    employment options in the business and
                                    financial sectors.
                                </p>
                                <p>
                                    <b>Research and Development -</b>The field
                                    of research and development in accountancy
                                    and business is one of the most common
                                    careers for graduates with a Bachelor of
                                    Commerce degree. The course is designed to
                                    give students a solid grounding in
                                    innovation and research methods that will
                                    assist them to advance in their chosen
                                    fields.
                                </p>
                                <p>
                                    <b
                                        >Multiple-purpose Employment
                                        Opportunities -</b
                                    >Aspirants can find a variety of
                                    professional opportunities, from advanced
                                    degrees to high-paying positions, with its
                                    assistance. Companies often look for
                                    candidates with a Bachelor of Commerce
                                    degree because of the breadth of knowledge
                                    they get in areas like accounting, finance,
                                    and business.
                                </p>
                            </li>
                        </ul>
                        <p>
                            As a B.Com degree can encompass a wide variety of
                            specializations, its holders tend to be highly
                            marketable to prospective employers.
                        </p>

                        <h5>Programme Duration</h5>
                        <p>
                            Bachelor of Commerce (B. Com) is an undergraduate
                            degree program and B. Com programs are typically
                            three years course.
                        </p>
                        <p>
                            Minimum Duration 3 Years<br />Maximum Duration 6
                            Year
                        </p>
                        <h6>Eligibility & Fee</h6>
                        <p>
                            Online/distance Bachelor of Commerce (B. Com)
                            eligibility criteria are too very flexible. To
                            pursue an online and distance B. Com one has to be
                            10+2 pass out with Commerce from any recognised
                            university. There are no entrance examinations for
                            admission in online and distance B. Com courses.
                        </p>
                        <table class="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Programme fee</th>
                                    <th>Examination fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Rs. 8000 Per Year</td>
                                    <td>Rs. 2000 Per Year</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6 style="font-weight: bold">Payment Mode</h6>
                        <table
                            style="width: 100%"
                            cellpadding="0"
                            cellspacing="0"
                            border="1"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Account Holder Name:</b> Swami
                                        Vivekanand Subharti University Distance
                                        Education
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height: 24px">
                                        <b>Account Number:</b> 628501043170
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>IFSC Code:</b> ICIC0006285</td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>Branch:</b> 65 Shiv Puri Hapur Road
                                        Meerut
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h6><strong>Career Prospects</strong></h6>
                        <p>
                            After college, a B. Com graduate student has various
                            options for employment, both in the government and
                            private sectors.
                        </p>
                        <h6 style="font-weight: bold">Government Sector-</h6>
                        <ul>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Banking
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Accounting
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Taxation
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Wealth Management
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Finance
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Insurance
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Commerce
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Education
                            </li>
                            <li>
                                <i
                                    class="bx bx-chevron-right"
                                    style="color: #29176d"
                                ></i
                                >Government Service
                            </li>
                        </ul>
                        <h5 style="font-weight: bold">
                            Private Sector-
                            <hr />
                        </h5>
                        <div class="container p-4" style="text-align: justify">
                            <div class="row">
                                <div class="col-lg-6">
                                    <h6 style="font-weight: bold">
                                        There are many designations for<br />
                                        B. Com Graduates are:
                                    </h6>
                                    <!--<p>There are many designations for Graduates are:</p>-->
                                    <ul>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Charted Accountant (CA)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Company Secretary (CS)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Charted Financial Analyst (CFA)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Financial Risk Management (FRM)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Certified management Accountant
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <h6 style="font-weight: bold">
                                        Best study options after B. Com
                                    </h6>
                                    <ul>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Master of Business Administration
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Chartered Accountancy (CA)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Master of Commerce (M. Com)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Chartered Financial Analyst (CFA)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Business Accounting and Taxation
                                            (BAT)
                                        </li>
                                        <li>
                                            <i
                                                class="bx bx-chevron-right"
                                                style="color: #29176d"
                                            ></i
                                            >Certified Management Accountant
                                            (CMA)
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</div>
