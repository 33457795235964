import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assignment-fm4th23',
  templateUrl: './assignment-fm4th23.component.html',
  styleUrls: ['./assignment-fm4th23.component.scss']
})
export class AssignmentFm4th23Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
