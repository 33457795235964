<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>COURSES</h2>
        </div>
        <div class="shape9">
            <img src="assets/img/shape8.svg" alt="image" />
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>
<div class="container" style="margin-top: 25px">
    <div class="row align-items-center">
        <div class="col-md-8">
            <div class="accor_heading downToggle" style="overflow-x: auto">
                <table
                    class="table table-striped"
                    style="border: 1px solid black"
                >
                    <tr style="background-color: #29166d; color: white">
                        <th>S. No.</th>
                        <th>LEVEL</th>
                        <th>PROGRAMME</th>
                        <th>STREAM</th>
                    </tr>
                    <tr style="">
                        <td>1</td>
                        <td>UG</td>
                        <td>BACHELOR OF ARTS</td>
                        <td>
                            <h6>GENERAL(Combination of any 3 Subjects)</h6>
                            <ol>
                                <li>HINDI</li>
                                <li>English</li>
                                <li>POLITICAL SCIENCE</li>
                                <li>ECONOMICS</li>
                                <li>SOCIOLOGY</li>
                                <li>MATHEMATICS</li>
                            </ol>
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>UG</td>
                        <td>BACHELOR OF LIBRARY AND INFORMATION SCIENCES</td>
                        <td>LIBRARY AND INFORMATION SCIENCES</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>UG</td>
                        <td>BACHELOR OF BUSINESS ADMINISTRATION</td>
                        <td>MANAGEMENT</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>UG</td>
                        <td>BACHELOR OF COMMERCE</td>
                        <td>MANAGEMENT</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>UG</td>
                        <td>BACHELOR OF COMMERCE (HONORS)</td>
                        <td>MANAGEMENT</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>UG</td>
                        <td>BACHELOR OF ARTS</td>
                        <td>JOURNALISM AND MASS COMMUNICATION</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>BUDDHIST STUDIES</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>EDUCATION</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>PUBLIC ADMINISTRATION</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>HOME SCIENCE</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>PG</td>
                        <td>MASTER OF LIBRARY AND INFORMATION SCIENCES</td>
                        <td>LIBRARY AND INFORMATION SCIENCES</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>PG</td>
                        <td>MASTER OF BUSINESS ADMINISTRATION</td>
                        <td>GENERAL (Combination of any 2 Subjects)</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>PG</td>
                        <td>MASTER OF COMMERCE</td>
                        <td>MANAGEMENT</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>JOURNALISM AND MASS COMMUNICATION</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>POLITICAL SCIENCES</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>MATHEMATICS</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>SOCIOLOGY</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>HISTORY</td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>HINDI</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>ECONOMICS</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>PG</td>
                        <td>MASTER OF ARTS</td>
                        <td>ENGLISH</td>
                    </tr>
                </table>
                <p>
                    * Fashiod Design will be applicable in the combination with
                    one of the specialization from HR, Marketing and Finance
                </p>
                <br />
                <p>
                    * Minimum Age of a learner for eligibility for a perticular
                    course of progrmme should be 17 Years in the month of
                    December for respective Academic Session.
                </p>
                <p>
                    * Maximum duration refers to the Maximum time allowed for
                    the completion of that course. If the student fails to
                    complete it within
                </p>
                <p>this period full fees will have to be paid again.</p>
            </div>
        </div>
        <div
            class="col-lg-4 col-md-4 col-sm-12 d-none d-lg-block"
            style="margin-top: -1100px"
        >
            <div class="sidebar-widget">
                <div class="single-sidebar-widget">
                    <!--  <div class="tution-wrapper">
                          <div class="tution-fee">
                              <h1>Quick Links</h1>
                          </div>
                          <ul class="sub-menu">
                              &lt;!&ndash;<li><a routerLink="/all-courses"><span>All Courses</span></a></li>&ndash;&gt;
                              <li><a routerLink="/diploma"><span>Diploma Courses</span></a></li>
                              <li><a routerLink="/advanced-diploma"><span>Advanced Diploma</span></a></li>
                              <li><a routerLink="/degree-courses"><span>Degree Courses</span></a></li>
                              <li><a routerLink="/pg-courses"><span>PG Courses</span></a></li>
                              <li><a routerLink="/pg-diploma-courses"><span>PG Diploma Courses</span></a></li>
                              <li><a routerLink="/new-courses"><span>New Courses</span></a></li>
                          </ul>
                      </div>-->
                    <!-- <div class="widget widget_categories">
                        <h3 class="widget-title">Quick Links</h3>
                        <ul>
                            <!-- <li><a routerLink="/diploma"> <span style="color: black;"
                                                                 class="post-count">Diploma Courses</span></a></li>
                             <li><a routerLink="/advanced-diploma"> <span style="color: black;" class="post-count">Advanced Diploma</span></a>
                             </li>--
                            <li>
                                <a routerLink="/degree-courses">
                                    <span
                                        style="color: black"
                                        class="post-count"
                                        >Degree Courses</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a routerLink="/pg-courses">
                                    <span
                                        style="color: black"
                                        class="post-count"
                                        >PG Courses</span
                                    ></a
                                >
                            </li>
                            <!--<li><a routerLink="/pg-diploma-courses"> <span style="color: black;" class="post-count">PG Diploma Courses</span></a>
                            </li>-->
                    <!-- <li><a routerLink="/new-courses"> <span style="color: black;"
                                                                    class="post-count">New Courses</span></a></li> -->
                    <!-- <li><a routerLink="/programme/bachelor-of-arts"> <span style="color: black;"
                                                                    class="post-count">Bachelor Of Arts</span></a></li>
                            <li><a routerLink="/programme/bachelor-of-library-and-information-sciences"> <span style="color: black;"
                                                                    class="post-count">Bachelor Of Library And Information Sciences</span></a></li>
                            <li><a routerLink="/new-courses"> <span style="color: black;"
                                                                    class="post-count">Bachelor Of Business Administration</span></a></li>
                            <li><a routerLink="/programme/bachelor-of-commerce"> <span style="color: black;"
                                                                    class="post-count">Bachelor Of Commerce</span></a></li>
                            <li><a routerLink="/new-courses"> <span style="color: black;"
                                                                    class="post-count">Master Of Arts</span></a></li>
                            <li><a routerLink="/new-courses"> <span style="color: black;"
                                                                    class="post-count">Master Of Library And Information Sciences</span></a></li>
                            <li><a routerLink="/new-courses"> <span style="color: black;"
                                                                    class="post-count">Master Of Business Administration</span></a></li>
                            <li><a routerLink="/new-courses"> <span style="color: black;"
                                                                    class="post-count">Master Of Commece</span></a></li>--
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 d-lg-none">
            <div class="sidebar-widget">
                <div class="single-sidebar-widget">
                    <!-- <div class="tution-wrapper">
                         <div class="tution-fee">
                             <h1>Quick Links</h1>
                         </div>
                         <ul class="sub-menu">
                             &lt;!&ndash;<li><a routerLink="/all-courses"><span>All Courses</span></a></li>&ndash;&gt;
                             <li><a routerLink="/diploma"><span>Diploma Courses</span></a></li>
                             <li><a routerLink="/advanced-diploma"><span>Advanced Diploma</span></a></li>
                             <li><a routerLink="/degree-courses"><span>Degree Courses</span></a></li>
                             <li><a routerLink="/pg-courses"><span>PG Courses</span></a></li>
                             <li><a routerLink="/pg-diploma-courses"><span>PG Diploma Courses</span></a></li>
                             <li><a routerLink="/new-courses"><span>New Courses</span></a></li>
                         </ul>
                     </div>-->
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Quick Links</h3>
                        <ul>
                            <!-- <li><a routerLink="/diploma"> <span style="color: black;"
                                                                 class="post-count">Diploma Courses</span></a></li>
                             <li><a routerLink="/advanced-diploma"> <span style="color: black;" class="post-count">Advanced Diploma</span></a>
                             </li>-->
                            <li>
                                <a routerLink="/degree-courses">
                                    <span
                                        style="color: black"
                                        class="post-count"
                                        >Degree Courses</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a routerLink="/pg-courses">
                                    <span
                                        style="color: black"
                                        class="post-count"
                                        >PG Courses</span
                                    ></a
                                >
                            </li>
                            <!-- <li><a routerLink="/pg-diploma-courses"> <span style="color: black;" class="post-count">PG Diploma Courses</span></a>
                             </li>-->
                            <li>
                                <a routerLink="/new-courses">
                                    <span
                                        style="color: black"
                                        class="post-count"
                                        >New Courses</span
                                    ></a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
