import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resution-for-dde',
  templateUrl: './resution-for-dde.component.html',
  styleUrls: ['./resution-for-dde.component.scss']
})
export class ResutionForDdeComponent implements OnInit {
    pdfFilePath = "";
  constructor() { }

  ngOnInit(): void {
  }

}
